import { request } from "graphql-request";
import { graphql } from "gql/gql";

import { BACKEND_DOMAIN } from "utils/constants";
import {
    buildRequestHeaders,
} from "utils/helpers";

const queryFn = (
    accessToken: string, 
    data: {
        reportFiltersetUid: string | null;
    }
) =>
    request({
        url: `${BACKEND_DOMAIN}/graphql`,
        document: query,
        variables: {
            reportFiltersetUid: data.reportFiltersetUid,
        },  
        requestHeaders: buildRequestHeaders(accessToken),
    }).then(
        (data) =>
            // eslint-disable-next-line
            (data.prefilteredReports?.prefilteredBaselineReport?.annualCarbonEmissionsNaturalGasChart.map(
                (item) => {
                    const endUses: { [endUseKey: string]: number } = {};
                    const year = item?.year || 0;
                    item?.endUseCarbonEmissions.forEach((use) => {
                        const endUseKey = use?.endUse;
                        const carbonEmissions = use?.carbonEmissions || 0;
                        if (endUseKey) endUses[endUseKey] = carbonEmissions;
                    });
                    return {
                        year,
                        ...endUses,
                    };
                }
            ) as AnnualCarbonEmissionsNaturalGasChartData) || null
    );

const query = graphql(`
    query queryBaselineAnnualCarbonEmissionsNaturalGasChart(
        $reportFiltersetUid: Uuid) {
        prefilteredReports(reportFiltersetUid: $reportFiltersetUid) {
            prefilteredBaselineReport {
                annualCarbonEmissionsNaturalGasChart {
                    year
                    endUseCarbonEmissions {
                        endUse
                        carbonEmissions
                    }
                }
            }
        }
    }
`);

export default queryFn;
