import { atom, useRecoilState } from "recoil";

interface CustomPlanSidebarState {
    selectedRows: CustomPlanDataSource[];
}

const customPlanSidebarStateAtom = atom<CustomPlanSidebarState>({
    key: "customPlanSidebarState",
    default: {
        selectedRows: [],
    }
});

const useCustomPlanSidebarState = () => {
    const [state, setState] = useRecoilState(customPlanSidebarStateAtom);

    const resetSelections = () => {
        setState(prev => ({
            ...prev,
            selectedRows: []
        }));
    };
    
    return {
        state,
        setState,
        resetSelections
    };
};

export default useCustomPlanSidebarState;