/* eslint-disable max-len */
import { request } from "graphql-request";
import { graphql } from "gql/gql";

import { BACKEND_DOMAIN } from "utils/constants";
import {
    buildRequestHeaders,
} from "utils/helpers";

const queryFn = (accessToken: string, buildingModelRunUid: string) =>
    request({
        url: `${BACKEND_DOMAIN}/graphql`,
        document: query,
        variables: { buildingModelRunUid },
        requestHeaders: buildRequestHeaders(accessToken),
    }).then((data) => {
        if (data.carbonReductionPlanReports.error)
            throw new Error(data.carbonReductionPlanReports.error);
        else
            return data.carbonReductionPlanReports?.annualCarbonEmissionsElectricityChart.map(
                (item) => {
                    const endUses: { [endUseKey: string]: number } = {};
                    const year = item?.year || 0;
                    item?.endUseCarbonEmissions.forEach((use) => {
                        const endUseKey = use?.endUse;
                        const carbonEmissions = use?.carbonEmissions || 0;
                        if (endUseKey) endUses[endUseKey] = carbonEmissions;
                    });
                    return {
                        year,
                        ...endUses,
                    };
                }
            ) as AnnualCarbonEmissionsElectricityChartData;
    });

const query = graphql(`
    query buildingRecommendationsAnnualCarbonEmissionsElectricityChart(
        $buildingModelRunUid: Uuid!
    ) {
        carbonReductionPlanReports(buildingModelRunUid: $buildingModelRunUid) {
            error
            annualCarbonEmissionsElectricityChart {
                year
                endUseCarbonEmissions {
                    endUse
                    carbonEmissions
                }
            }
        }
    }
`);

export default queryFn;
