import { BuildingDatum } from "./EnergyAndEmissionIntensityAggregator";

export function refineBuildingData(buildings: Building[]) {
    return buildings.flatMap((building: Building): BuildingDatum | [] => {
        if (
            !building.grossFloorArea ||
            !building.reportedCrp ||
            !building.reportedCrp.firstYearAnnualCarbonEmissions ||
            !building.reportedCrp?.firstYearEnergyConsumptionTotal
        )
            return [];

        const { grossFloorArea } = building;
        const {
            firstYearAnnualCarbonEmissions,
            firstYearEnergyConsumptionTotal,
        } = building.reportedCrp;

        return {
            buildingName: building.buildingName,
            streetAddress: building.streetAddress,
            city: building.city,
            stateProvince: building.stateProvince,
            buildingArchetype: building.buildingArchetype,
            propertyName: building.propertyName,
            fundName: building.fundName,
            grossFloorArea,
            firstYearAnnualCarbonEmissions,
            firstYearEnergyConsumptionTotal,
        };
    });
}
