import { useMutation } from "react-query";
import { buildRequestHeaders, showMutationAlert } from "utils/helpers";
import { BACKEND_DOMAIN } from "utils/constants";
import useAlert from "hooks/useAlert";
import { useAccessToken } from "hooks/useAccessToken";

export const chatbotQueryKey = ["chatbot"];

const usePortfolioChatbotMutation = () => {
    const alert = useAlert();
    const accessToken = useAccessToken();

    const URL = `${BACKEND_DOMAIN}/portfolio-chatbot`;

    return useMutation({
        mutationFn: async ({
            message,
            holdPeriod,
        }: {
            message: string;
            holdPeriod: number;
        }) => {
            const response = await fetch(URL, {
                method: "POST",
                headers: buildRequestHeaders(accessToken),
                body: JSON.stringify({ message, holdPeriod }),
            });

            if (!response.ok) {
                throw new Error(`Error ${response.status}`);
            }

            return response.json();
        },
        onError: (error) => {
            showMutationAlert(error, alert);
        },
    });
};

export default usePortfolioChatbotMutation;
