import { request } from "graphql-request";
import { graphql } from "gql/gql";

import { BACKEND_DOMAIN } from "utils/constants";
import {
    buildRequestHeaders,
} from "utils/helpers";

const queryFn = (
    accessToken: string, 
    data: {
        reportFiltersetUid: string | null;
    }
) =>
    request({
        url: `${BACKEND_DOMAIN}/graphql`,
        document: query,
        variables: {
            reportFiltersetUid: data.reportFiltersetUid,
        },  
        requestHeaders: buildRequestHeaders(accessToken),
    }).then((data) => {
        if (data.prefilteredReports?.prefilteredBaselineReport?.annualEnergyConsumptionCard.error)
            throw new Error(
                data.prefilteredReports?.prefilteredBaselineReport.annualEnergyConsumptionCard.error
            );
        return (
            data.prefilteredReports?.prefilteredBaselineReport?.annualEnergyConsumptionCard.data ||
            null
        );
    });

const query = graphql(`
    query annualEnergyConsumptionCard($reportFiltersetUid: Uuid) {
        prefilteredReports(reportFiltersetUid: $reportFiltersetUid) {
            prefilteredBaselineReport {
                annualEnergyConsumptionCard {
                    error
                    data {
                        totalEnergyConsumption
                        totalEnergyConsumptionElectricity
                        totalEnergyConsumptionNaturalGas
                        year
                    }
                }
            }
        }
    }
`);

export default queryFn;
