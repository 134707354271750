import { atom, useRecoilState } from "recoil";

interface FiltersetState {
    filterset: string | null;
}

const filtersetStateAtom = atom<FiltersetState>({
    key: "filtersetState",
    default: {
        filterset: null
    }
});

const useFiltersetState = () => {
    const [state, setState] = useRecoilState(filtersetStateAtom);

    return {
        state,
        setState,
    };
};

export default useFiltersetState;