import { BuildingModelQuery } from "gql/graphql";

export const removeMeasureInputUid = (measures: CarbonReductionMeasureInput[]) =>
    measures.map(
        (measure): CarbonReductionMeasureInput => ({
            year: measure.year,
            crmId: measure.crmId,
            measureCost: measure.measureCost,
            measureSize: measure.measureSize,
        })
    );

export const measuresToMeasureInput = (measures: CarbonReductionMeasure[]) =>
    measures
        .filter(
            (measure): measure is NonNullable<typeof measure> =>
                measure !== undefined &&
                measure.carbonReductionMeasureId !== "no-action"
        )
        .map(
            (measure): CarbonReductionMeasureInput => ({
                measureUid: measure.carbonReductionMeasureUid,
                year: measure.yearApplied,
                crmId: measure.carbonReductionMeasureId,
                measureCost:
                    measure.userProvidedCost && measure.measureCost
                        ? measure.measureCost
                        : undefined,
                measureSize: 
                    measure.userProvidedSize && measure.measureSize 
                        ? measure.measureSize 
                        : undefined,
            })
        );

export const addProjectToMeasures = (
    measures: CarbonReductionMeasureInput[],
    newProject: NewProject
) => [
    ...measures,
    {
        year: newProject?.measureYear,
        crmId: newProject?.selectedProject,
    },
];

export const removeProjectsFromMeasures = (
    measures: CarbonReductionMeasureInput[],
    measureUids: string[]
) =>
    measures.filter(
        (measure) => !measureUids.includes(measure.measureUid || "")
    );

export const editProjectInMeasures = (
    measures: CarbonReductionMeasureInput[],
    selectedMeasureUid: string,
    measureYear: number,
    totalMeasureCost?: number | null,
    measureSize?: number | null
): CarbonReductionMeasureInput[] =>
    measures.map((measure) =>
        measure.measureUid === selectedMeasureUid
            ? {
                  ...measure,
                  ...{ year: measureYear, measureCost: totalMeasureCost, measureSize },
              }
            : measure
    );

export const getSelectedMeasuresUids = (selectedRows: CustomPlanDataSource[]) =>
    selectedRows.map((selectedRow) => selectedRow.carbonReductionMeasureUid);

export const getSelectedMeasureUid = (selectedRows: CustomPlanDataSource[]) => {
    const uuids = getSelectedMeasuresUids(selectedRows);
    if (uuids.length !== 1) {
        console.error("More than one row selected.");
    }
    return uuids[0];
};

export const getSelectedPlan = (
    buildingModel: BuildingModelQuery,
    carbonReductionPlanUid: string
) => {
    const targetPlanIndex =
        buildingModel?.buildingModel.carbonReductionPlans.findIndex(
            (plan) => plan?.id === carbonReductionPlanUid
        );

    if (targetPlanIndex === undefined || targetPlanIndex === -1) {
        console.error("No matching carbon reduction plan found");
        return null;
    }

    return buildingModel?.buildingModel.carbonReductionPlans[targetPlanIndex];
};

export const currentYear = new Date().getFullYear();