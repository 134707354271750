/* eslint-disable no-restricted-syntax */
import mapboxgl, {
    LngLatLike,
    Marker
} from "mapbox-gl";
import { useEffect } from "react";
import { createRoot } from "react-dom/client";
import { NavigateFunction } from "react-router-dom";
import MapPopup from "./MapPopUp";

export const useMapLayers = (
    buildingModels: BarChartAndMapData[],
    map: React.MutableRefObject<mapboxgl.Map | null>,
    mapContainer: React.MutableRefObject<HTMLDivElement | null>,
    setMapHasLoaded: (loaded: boolean) => void,
    minEmissionIntensity: number,
    maxEmissionIntensity: number,
    navigate: NavigateFunction
) => {
    useEffect(() => {
        if (!mapContainer.current) return;

        // eslint-disable-next-line no-param-reassign
        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: "mapbox://styles/mapbox/light-v11",
            center: [-99, 47],
            zoom: 4,
            projection: {
                name: "mercator",
            },
            minZoom: 2,
            boxZoom: false,
        });

        const bounds = createBoundingBox(buildingModels);
        if (bounds !== null) {
            map.current.fitBounds(bounds);
        }

        map.current.on("load", () => {
            setMapHasLoaded(true);
            const buildingFeatures = createGeoJsonData(buildingModels);

            if (!map.current?.getSource("buildings"))
                map.current!.addSource("buildings", {
                    type: "geojson",
                    data: buildingFeatures,
                    cluster: true,
                    clusterMaxZoom: 14,
                    clusterRadius: 50,
                    clusterProperties: {
                        clusterTotal: ["+", ["get", "colorValue"]],
                    },
                });

            buildingFeatures.features.forEach((feature) => {
                const coords = feature.geometry.coordinates as LngLatLike;
                const building = feature.properties
                    ?.building as BarChartAndMapData;

                const popupNode = document.createElement("div");
                popupNode.className = "popup-node";
                createRoot(popupNode).render(
                    <MapPopup buildingModel={building} navigate={navigate} />
                );
                const marker = new Marker(popupNode).setLngLat(coords);
                marker.addTo(map.current!);
            });

            if (!map.current!.getLayer("cluster-circle"))
                map.current!.addLayer({
                    id: "cluster-circle",
                    type: "circle",
                    source: "buildings",
                    filter: ["has", "point_count"],
                    paint: {
                        "circle-color": [
                            "interpolate",
                            ["linear"],
                            [
                                "/",
                                ["number", ["get", "clusterTotal"]],
                                ["number", ["get", "point_count"]],
                            ],
                            minEmissionIntensity,
                            "#00BC98",
                            (minEmissionIntensity + maxEmissionIntensity) / 2,
                            "#F7931E",
                            maxEmissionIntensity,
                            "#CC303C",
                        ],
                        "circle-radius": ["number", 14],
                        "circle-opacity": 1,
                        "circle-stroke-width": 0,
                    },
                });

            if (!map.current!.getLayer("cluster-number"))
                map.current!.addLayer({
                    id: "cluster-number",
                    type: "symbol",
                    source: "buildings",
                    filter: ["has", "point_count"],
                    layout: {
                        "text-field": ["get", "point_count"],
                        "text-font": [
                            "DIN Offc Pro Medium",
                            "Arial Unicode MS Bold",
                        ],
                        "text-size": 16,
                        "text-allow-overlap": true,
                    },
                    paint: {
                        "text-color": "#FFF",
                    },
                });

            if (!map.current!.getLayer("building-points"))
                map.current!.addLayer({
                    id: "building-points",
                    type: "circle",
                    source: "buildings",
                    filter: ["!", ["has", "point_count"]],
                    paint: {
                        "circle-color": [
                            "interpolate",
                            ["linear"],
                            ["get", "colorValue"],
                            minEmissionIntensity,
                            "#00BC98",
                            (minEmissionIntensity + maxEmissionIntensity) / 2,
                            "#F7931E",
                            maxEmissionIntensity,
                            "#CC303C",
                        ],
                        "circle-radius": 10,
                        "circle-opacity": 1,
                        "circle-stroke-width": 0,
                    },
                });
        });
        setMapHasLoaded(true);
    }, [buildingModels]);
};

const createBoundingBox = (buildings: BarChartAndMapData[]) => {
    if (buildings.length === 0) return null;

    let north = buildings[0].latitude;
    let south = buildings[0].latitude;
    let west = buildings[0].longitude;
    let east = buildings[0].longitude;

    for (let i = 1; i < buildings.length; i++) {
        north = Math.max(north, buildings[i].latitude);
        south = Math.min(south, buildings[i].latitude);
        west = Math.min(west, buildings[i].longitude);
        east = Math.max(east, buildings[i].longitude);
    }

    return new mapboxgl.LngLatBounds([west, south, east, north]);
};

export const createGeoJsonData = (
    buildings: BarChartAndMapData[]
): GeoJSON.FeatureCollection<any> => ({
    type: "FeatureCollection",
    features: buildings
        // filter out buildings that haven't been modelled
        .filter(
            (building) =>
                building.annualCarbonEmissionIntensityCurrent !== null &&
                building.annualCarbonEmissionIntensityCurrent !== undefined
        )
        .map((building) => ({
            type: "Feature",
            geometry: {
                type: "Point",
                coordinates: [building.longitude, building.latitude],
            },
            properties: {
                building,
                colorValue: building.annualCarbonEmissionIntensityCurrent,
            },
        })),
});
