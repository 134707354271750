import { AIGenerate } from "components/atoms/Icon";
import { useState } from "react";
import styles from "./Chatbot.module.scss";
import DialogSidebar from "./DialogBox/DialogSidebar";

interface ChatbotProps {
  title: string
  page: PageWithChatbot
}

function Chatbot({ title, page }: ChatbotProps) {
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => {
    setIsOpen(false);
  };

  const clickHandler = () => {
    setIsOpen(!isOpen);
  }

  return (
    <>
        {isOpen &&
            <DialogSidebar
                handleClose={handleClose}
                sidebarOpen={isOpen}
                title={title}
                page={page}
            />
        }

        <button
            onClick={clickHandler}
            onKeyDown={clickHandler}
            className={styles.container}
            type="button"
            style={{
                width: '50px',
                height: '50px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: 0,
                position: 'fixed',
                bottom: '30px',
                left: '40px',
                zIndex: 10
            }}
            >
            <div style={{
                width: '24px',
                height: '24px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <AIGenerate />
            </div>
        </button>
    </>
  );
}

export default Chatbot;