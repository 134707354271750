import { ScatterPlotDatum } from "components/molecules/Charts/ScatterPlot";
import { buildingTypeOptions, ScatterPlotAssetGroup, ScatterPlotProjectGroup } from "utils/constants";

export interface BuildingDatum {
    buildingName: string;
    streetAddress: string;
    city: string;
    stateProvince: string;
    buildingArchetype: string;
    propertyName: string;
    fundName: string;
    netPresentValueTotal: number;
    grossFloorArea: number;
    annualMeanCarbonEmissionSavings: number;
    firstYearAnnualCarbonEmissions: number;
    totalCost: number;
    simplePayback: number;
    numberOfMeasures: number;
}

type BuildingAggregator = (buildingData: BuildingDatum[]) => ScatterPlotDatum[];

function buildingAggregator(buildingData: BuildingDatum[]): ScatterPlotDatum[] {
    return buildingData.map((d) => (
        formatData({
            simplePaybackAverage: d.simplePayback,
            grossFloorArea: d.grossFloorArea,
            annualMeanCarbonEmissionSavings: d.annualMeanCarbonEmissionSavings,
            firstYearAnnualCarbonEmissions: d.firstYearAnnualCarbonEmissions,
            totalCost: d.totalCost,
            title: d.buildingName,
            subtitle: `${d.streetAddress}, ${d.city}, ${d.stateProvince}`,
        })
    ));
}

function buildingArchetypeAggregator(buildingData: BuildingDatum[]): ScatterPlotDatum[] {
    const groupedData = Object.groupBy(buildingData, ({ buildingArchetype }) => {
        const humanReadable = (
            buildingTypeOptions.find(({ key }) => key === buildingArchetype)?.label ?? "undefined"
        );
        return humanReadable;
    });
    const scatterPlotData = aggregateGroupedData(groupedData);
    return scatterPlotData;
}

function regionAggregator(buildingData: BuildingDatum[]): ScatterPlotDatum[] {
    const groupedData = Object.groupBy(buildingData, ({ stateProvince }) => stateProvince);
    const scatterPlotData = aggregateGroupedData(groupedData);
    return scatterPlotData;
}

function propertyAggregator(buildingData: BuildingDatum[]): ScatterPlotDatum[] {
    const groupedData = Object.groupBy(buildingData, ({ propertyName }) => propertyName ?? "no property");
    const scatterPlotData = aggregateGroupedData(groupedData);
    return scatterPlotData;
}

function fundAggregator(buildingData: BuildingDatum[]): ScatterPlotDatum[] {
    const groupedData = Object.groupBy(buildingData, ({ fundName }) => fundName ?? "no fund");
    const scatterPlotData = aggregateGroupedData(groupedData);
    return scatterPlotData;
}

export function getBuildingAggregator(
    scatterPlotAssetGroup: ScatterPlotAssetGroup
): BuildingAggregator {
    const groupToAggregator = {
        "building": buildingAggregator,
        "property": propertyAggregator,
        "fund": fundAggregator,
        "region": regionAggregator,
        "buildingArchetype": buildingArchetypeAggregator,
    };
    return groupToAggregator[scatterPlotAssetGroup];
}

export interface ProjectDatum {
    projectName: string;
    measureCategory: string;
    netPresentValueTotal: number;
    grossFloorArea: number;
    annualMeanCarbonEmissionSavings: number;
    firstYearAnnualCarbonEmissions: number;
    totalCost: number;
    simplePayback: number;
    numberOfMeasures: number;
}

type ProjectAggregator = (projectData: ProjectDatum[]) => ScatterPlotDatum[];

function projectAggregator(projectData: ProjectDatum[]): ScatterPlotDatum[] {
    return projectData.map((d) => (
        formatData({
            simplePaybackAverage: d.simplePayback,
            grossFloorArea: d.grossFloorArea,
            annualMeanCarbonEmissionSavings: d.annualMeanCarbonEmissionSavings,
            firstYearAnnualCarbonEmissions: d.firstYearAnnualCarbonEmissions,
            totalCost: d.totalCost,
            title: d.projectName,
            subtitle: d.measureCategory,
        })
    ));
}

function measureAggregator(projectData: ProjectDatum[]): ScatterPlotDatum[] {
    const groupedData = Object.groupBy(projectData, ({ projectName }) => projectName);
    const scatterPlotData = aggregateGroupedData(groupedData, "measureCategory");
    return scatterPlotData;
}

function measureCategoryAggregator(projectData: ProjectDatum[]): ScatterPlotDatum[] {
    const groupedData = Object.groupBy(projectData, ({ measureCategory }) => measureCategory);
    const scatterPlotData = aggregateGroupedData(groupedData);
    return scatterPlotData;
}

function aggregateGroupedData(
    groupedData: Partial<Record<string, BuildingDatum[] | ProjectDatum[]>>,
    subtitleKey?: string,
): ScatterPlotDatum[] {
    return Object.entries(groupedData).flatMap(([groupName, groupData]) => {
        if (groupData === undefined || groupData.length === 0) return [];

        // Uncomment to debug
        // console.log("groupData", groupData);

        const sumOfSimplePaybackOfAllProjectsInGroup = (
            groupData.reduce((acc, groupDatum) => 
                acc + groupDatum.simplePayback * groupDatum.numberOfMeasures, 0)
        ); 
        const totalNumberOfProjects = groupData.reduce((acc, groupDatum) => 
            acc + groupDatum.numberOfMeasures, 0);
        const simplePaybackAverage = sumOfSimplePaybackOfAllProjectsInGroup / totalNumberOfProjects;

        const grossFloorArea = (
            groupData.reduce((acc, groupDatum) => acc + groupDatum.grossFloorArea, 0)
        );
        const annualMeanCarbonEmissionSavings = (
            groupData.reduce(
                (acc, groupDatum) => acc + groupDatum.annualMeanCarbonEmissionSavings,
                0
            )
        );
        const firstYearAnnualCarbonEmissions = (
            groupData.reduce(
                (acc, groupDatum) => acc + groupDatum.firstYearAnnualCarbonEmissions,
                0
            )
        );
        const totalCost = (
            groupData.reduce((acc, groupDatum) => acc + groupDatum.totalCost, 0)
        );

        return formatData({
            simplePaybackAverage,
            grossFloorArea,
            annualMeanCarbonEmissionSavings,
            firstYearAnnualCarbonEmissions,
            totalCost,
            title: groupName,
            subtitle: groupData[0][subtitleKey as keyof (BuildingDatum | ProjectDatum)]
                || undefined,
        })
    });
}

interface UnformattedDatum {
    simplePaybackAverage: number;
    grossFloorArea: number;
    annualMeanCarbonEmissionSavings: number;
    firstYearAnnualCarbonEmissions: number;
    totalCost: number;
    title: string;
    subtitle?: string | number;
}

function formatData(data: UnformattedDatum): ScatterPlotDatum {
    const emissionsReductionPotentialPercentage = (
        data.annualMeanCarbonEmissionSavings / data.firstYearAnnualCarbonEmissions * 100
    );

    const formattedPayback = data.simplePaybackAverage <= 0 || data.simplePaybackAverage > 50 
    ? 51 
    : data.simplePaybackAverage;
    return {
        x: Math.ceil(formattedPayback),
        y: emissionsReductionPotentialPercentage,
        z: data.totalCost,
        tooltip: {
            title: data.title,
            subtitle: data?.subtitle,
            tooltipData: [
                {
                    formatKey: "totalMeasureCost",
                    value: data.totalCost,
                },
                {
                    formatKey: "simplePaybackAverage",
                    value: formattedPayback,
                },
                {
                    formatKey: "carbonEmissionReductionPotential",
                    value: emissionsReductionPotentialPercentage / 100,
                },
            ]
        }
    }
}

export function getProjectAggregator(
    scatterPlotProjectGroup: ScatterPlotProjectGroup
): ProjectAggregator {
    const groupToAggregator = {
        "project": projectAggregator,
        "measure": measureAggregator,
        "measureCategory": measureCategoryAggregator,
    };
    return groupToAggregator[scatterPlotProjectGroup];
}
