import { BuildingDatum, ProjectDatum } from "./DecarbonizationEfficiencyAggregator";

export function refineBuildingData(buildings: Building[], projects: CRM[]) {
    const crmsByUid = Object.fromEntries(
        projects.map((project) => [project.carbonReductionMeasureUid, project])
    )

    return buildings.flatMap((building: Building): BuildingDatum | [] => {
        if (
            !building.grossFloorArea ||
            !building.reportedCrp ||
            !building.reportedCrp.firstYearAnnualCarbonEmissions
        ) return [];

        const { grossFloorArea } = building;
        const {
            firstYearAnnualCarbonEmissions,
            carbonReductionMeasureUids
        } = building.reportedCrp;
        const carbonReductionMeasures = carbonReductionMeasureUids.map(
            (uid) => crmsByUid[uid]
        ).filter((crm) => crm);

            const netPresentValueTotal = carbonReductionMeasures.reduce(
                (sumNpv: number, crm: any) => sumNpv + crm.netPresentValueTotal,
                0
            );

            const annualMeanCarbonEmissionSavings = carbonReductionMeasures.reduce(
                (sumSavings: number, crm: any) => sumSavings + crm.annualMeanCarbonEmissionSavings,
                0
            );

            const totalCost = carbonReductionMeasures.reduce(
                (sumCost: number, crm: any) => sumCost + crm.measureCost,
                0
            );

            const simplePayback = carbonReductionMeasures.reduce(
                (sumPayback: number, crm: any) => sumPayback + crm.simplePaybackYearsTotal,
                0
            )/ carbonReductionMeasures.length;

            return {
                buildingName: building.buildingName,
                streetAddress: building.streetAddress,
                city: building.city,
                stateProvince: building.stateProvince,
                buildingArchetype: building.buildingArchetype,
                propertyName: building.propertyName,
                fundName: building.fundName,
                netPresentValueTotal,
                grossFloorArea,
                annualMeanCarbonEmissionSavings,
                firstYearAnnualCarbonEmissions,
                totalCost,
                simplePayback,
                numberOfMeasures: carbonReductionMeasures.length
            }
        });
}

export function refineProjectData(buildings: Building[], projects: CRM[]) {
    const buildingsByUid = Object.fromEntries(
         buildings.map((building) => [building.buildingModelUid, building])
    )

    return projects.flatMap((project: CRM): ProjectDatum | [] => {
        if (!project) return [];

        const building = buildingsByUid[project.buildingModelUid];
        const { grossFloorArea } = building;
        const firstYearAnnualCarbonEmissions = (
            building.reportedCrp?.firstYearAnnualCarbonEmissions || 0
        );
        const {
            netPresentValueTotal,
            annualMeanCarbonEmissionSavings,
            measureCost,
        } = project;

        return {
            projectName: project.carbonReductionMeasureType,
            measureCategory: project.carbonReductionMeasureCategory,
            netPresentValueTotal,
            grossFloorArea,
            annualMeanCarbonEmissionSavings,
            firstYearAnnualCarbonEmissions,
            totalCost: measureCost,
            simplePayback: project.simplePaybackYearsTotal,
            numberOfMeasures: 1
        }
    });
}