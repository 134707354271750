import { Header, Paragraph } from "components/atoms/Typography";
import "./ChartTooltip.css";

export interface ChartTooltipProps {
    title: string | number;
    subtitle?: string | number;
    rows: TooltipRow[];
}

export type TooltipRow = {
    unit: string;
    value: string | number;
    label: string;
    color?: string;
};

function ChartTooltip({ title, subtitle, rows }: ChartTooltipProps) {
    return (
        <div className="chart-tooltip">
            <Header>{title}</Header>
            {subtitle && <Paragraph>{subtitle}</Paragraph>}
            {rows.map(({ label, color, unit, value }) => (
                <div className="chart-tooltip__row" key={label}>
                    {color && (
                        <div
                            className="chart-tooltip__row__color-key"
                            style={{
                                backgroundColor: color,
                            }}
                        />
                    )}
                    <Paragraph style={{ color: "var(--audette-gray-500" }}>
                        {label}:
                    </Paragraph>
                    <Paragraph>
                        {unit === "$" ? `${unit}${value}` : `${value} ${unit}`}
                    </Paragraph>
                </div>
            ))}
        </div>
    );
}

export default ChartTooltip;
