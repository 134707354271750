// hooks
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import useBuildingLoadingState from "recoilStore/useBuildingLoadingState";

// components
import { useBuildingModelHeaderQuery } from "queries/useBuildingModelHeaderQuery";
import { usePermissions } from "hooks/usePermissions";
import { ErrorPage } from "pages/ErrorState";
import { useBuildingModel } from "hooks/Building/useBuildingModel";
import LoadingSpinner from "components/atoms/LoadingSpinner/LoadingSpinner";
import CreateCustomPlanModal from "components/organisms/Modals/CreateCustomPlanModal/createCustomPlanModal";
import Chatbot from "components/organisms/Chatbot/Chatbot";
import { UserPermissions, Tab } from "utils/enums";

import BuildingPageBaselineReport from "./BuildingPageBaselineReport";
import DecarbonizationReport from "./DecarbonizationReport";
import ProjectsReport from "./ProjectReport/ProjectsReport";

// css
import "./BuildingPage.scss";
import BuildingPageHeader from "./BuildingPageHeader/BuildingPageHeader";
import CustomPlan from "./CustomPlan/CustomPlan";
import EmptyBuildingReport from "./EmptyBuildingReport";
import ViewTabs from "./ViewTabs/ViewsTabs";
import { useCustomPlanState } from "./BuildingPage.hooks";
import BuildingPageSidebars from "./BuildingPageSidebars/BuildingPageSidebars";
import RecommendationsReport from "./RecommendationsReport";

function BuildingPage() {
    // Hooks
    const { buildingModelUid, buildingModelRunUid, tabName } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();

    // State Variables
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [isCustomPlanOpen, setIsCustomPlanOpen] = useState(false);
    const [
        currentReportedBuildingModelRunUid,
        setCurrentReportedBuildingModelRunUid,
    ] = useState("undefined");
    const [isCustomPlanSidebarOpen, setIsCustomPlanSidebarOpen] =
        useState(false);
    const [currentCustomPlanDetails, setCurrentCustomPlanDetails] = useState<{
        id: string;
        plan: any;
        name: string;
    } | null>(null);

    const currentActiveTab = tabName || "baseline";
    const setCurrentActiveTab = (tabName: string) => {
        navigate(
            `/building/${buildingModelUid}/${tabName}/${buildingModelRunUid}`
        );
    };
    const setBuildingModelRunUid = (buildingModelRunUid: string) => {
        navigate(
            `/building/${buildingModelUid}/${currentActiveTab}/${buildingModelRunUid}`
        );
    };

    // Query Params
    const carbonReductionMeasureType = searchParams.get(
        "carbonReductionMeasureType"
    );
    const carbonReductionMeasureCategory = searchParams.get(
        "carbonReductionMeasureCategory"
    );

    if (!buildingModelUid)
        throw new Error("BuildingModelUid param needs to be defined"); // TODO: create a custom hook for this

    const { data: buildingModel } = useBuildingModel(buildingModelUid);

    const { hasPermission, isFetching } = usePermissions();
    const { loadingState } = useBuildingLoadingState(buildingModelUid);

    const handleOnViewProfileClick = () => setIsSidebarOpen((show) => !show);
    const handleOnClose = () => setIsSidebarOpen(false);
    const handleCreateCustomPlan = () => {
        setModalIsOpen(true);
    };
    if (!buildingModelUid)
        throw new Error("BuildingModelUid param needs to be defined");

    const {
        isLoading: headerIsLoading,
        error: headerError,
        data: headerData,
    } = useBuildingModelHeaderQuery(buildingModelUid);

    const buildingIsProcessingOrError = useMemo(
        () =>
            headerData?.dataCoverage.modellingStatus === "processing" ||
            headerData?.dataCoverage.modellingStatus === "error" ||
            !headerData,
        [headerData]
    );

    const customPlans = buildingModel?.buildingModel.carbonReductionPlans || [];
    const grossFloorArea = buildingModel?.buildingModel.grossFloorArea || 0;
    const carbonReductionPlanUid =
        buildingModel?.buildingModel.reportedCrp?.carbonReductionPlanUid || "";

    useCustomPlanState({
        customPlans,
        searchParams,
        setCurrentActiveTab,
        setSearchParams,
        setCurrentCustomPlanDetails,
        setIsCustomPlanOpen,
    });

    useEffect(() => {
        if (currentCustomPlanDetails && buildingModel) {
            const updatedPlan = buildingModel.buildingModel.carbonReductionPlans
                .find(plan => plan && plan.id === currentCustomPlanDetails.id);
            if (updatedPlan) {
                setCurrentCustomPlanDetails({
                    id: updatedPlan.id,
                    plan: updatedPlan.plan,
                    name: updatedPlan.name
                });
            }
        }
    }, [buildingModel, currentCustomPlanDetails?.id]);
    
    useEffect(() => {
        if (currentActiveTab === undefined) {
            setCurrentActiveTab(Tab.Baseline);
        }
    }, [currentActiveTab]);

    useEffect(() => {
        if (buildingModelRunUid === "undefined") {
            setBuildingModelRunUid(
                buildingModel?.buildingModel.reportedCrp?.buildingModelRunUid ||
                    "undefined"
            );
        }
    }, [currentActiveTab, buildingModelRunUid]);

    // [DP 2025-03-16] Effect updates the url when a new recommended plan is generated.
    useEffect(() => {
        if (
            currentReportedBuildingModelRunUid !==
            buildingModel?.buildingModel.reportedCrp?.buildingModelRunUid
        ) {
            const currentModelRunUid =
                buildingModel?.buildingModel.reportedCrp?.buildingModelRunUid ||
                "undefined";
            setCurrentReportedBuildingModelRunUid(currentModelRunUid);
            setBuildingModelRunUid(currentModelRunUid);
        }
    }, [buildingModel]);

    if (headerError) return <ErrorPage />;

    // To do When we remove the Permission Check for the clients to see the custom plans, remove that Loading Spinner
    if (isFetching) return <LoadingSpinner />;
    return (
        <div className="building-page-with-sidebar">
            <div className="building-page">
                <BuildingPageHeader
                    onClickViewProfile={handleOnViewProfileClick}
                    onClickCreateCustomPlan={handleCreateCustomPlan}
                    hasPermission={hasPermission}
                    setIsCustomPlanSidebarOpen={setIsCustomPlanSidebarOpen}
                    isCustomPlanOpen={isCustomPlanOpen}
                />
                {buildingIsProcessingOrError ? (
                    <EmptyBuildingReport
                        isLoading={
                            headerIsLoading ||
                            loadingState.buildingReportsLoading
                        }
                    />
                ) : (
                    <>
                        <ViewTabs
                            customPlans={customPlans}
                            currentActiveTab={currentActiveTab}
                            setCurrentActiveTab={setCurrentActiveTab}
                            setCurrentCustomPlanDetails={
                                setCurrentCustomPlanDetails
                            }
                            setIsCustomPlanOpen={setIsCustomPlanOpen}
                            // setSearchParams={setSearchParams}
                        />

                        {currentActiveTab === Tab.Baseline && (
                            <div className="report">
                                <BuildingPageBaselineReport />
                            </div>
                        )}

                        {currentActiveTab === Tab.Decarbonization && (
                            <div className="report">
                                <DecarbonizationReport />
                            </div>
                        )}

                        {currentActiveTab === Tab.Recommendations && (
                            <div className="report">
                                <RecommendationsReport />
                            </div>
                        )}

                        {currentActiveTab === Tab.Projects && (
                            <ProjectsReport
                                carbonReductionMeasureTypeUrl={
                                    carbonReductionMeasureType
                                }
                                carbonReductionMeasureCategoryUrl={
                                    carbonReductionMeasureCategory
                                }
                            />
                        )}

                        {isCustomPlanOpen && currentCustomPlanDetails && (
                            <CustomPlan
                                currentCustomPlan={
                                    currentCustomPlanDetails.plan
                                }
                                grossFloorArea={grossFloorArea}
                                currentCarbonReductionPlanUid={
                                    currentCustomPlanDetails?.id || ""
                                }
                            />
                        )}

                        <CreateCustomPlanModal
                            modalIsOpen={modalIsOpen}
                            onCancel={() => setModalIsOpen(false)}
                            carbonReductionPlanUid={carbonReductionPlanUid}
                            setModalIsOpen={setModalIsOpen}
                            buildingModelUid={buildingModelUid}
                        />
                    </>
                )}
            </div>

            <BuildingPageSidebars
                isSidebarOpen={isSidebarOpen}
                handleOnClose={handleOnClose}
                isCustomPlanSidebarOpen={isCustomPlanSidebarOpen}
                currentCustomPlanDetails={currentCustomPlanDetails}
                setIsCustomPlanSidebarOpen={setIsCustomPlanSidebarOpen}
            />

            {hasPermission(UserPermissions.UseInternalTools) && (
                <Chatbot title="Building Chat" page="building" />
            )}
        </div>
    );
}

export default BuildingPage;
