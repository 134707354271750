export interface Message {
    text: string;
    type: 'send' | 'receive';
}


export const buildingChatbotWelcomeMessage: Message = {
    text: `Hi! I am Audette, your assistant for this building's details and path to decarbonization. How may I assist you today?

Try asking about:
  - This building's properties and features
  - Carbon footprint and energy cost reduction strategies
  - Cost-effective decarbonization measures
  - Financial risks of not upgrading
`,
    type: "receive",
}

export const portfolioChatbotWelcomeMessage: Message = {
    text: `Hi! I am Audette. I am here to provide insights and address your inquiries regarding:

- The financial risks associated with not decarbonizing your investment portfolio.
- Opportunities available for decarbonization.
- The impact of implementing mitigation measures.

My current analytical horizon is set to a 10-year period, assuming a 10-year holding period for the assets within your portfolio. Click on the Cog Wheel icon (in the text input area) to change the holding period.

Feel free to ask any questions about your portfolio.
`,
    type: "receive",
}
