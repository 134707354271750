/* eslint-disable max-len */
import { graphql } from "gql/gql";
import { request } from "graphql-request";

import { BACKEND_DOMAIN } from "utils/constants";
import {
    buildRequestHeaders
} from "utils/helpers";

const queryFn = (
    accessToken: string,
    data: {
        reportFiltersetUid: string | null;
    }
) =>
    request({
        url: `${BACKEND_DOMAIN}/graphql`,
        document: query,
        variables: {
            reportFiltersetUid: data.reportFiltersetUid,
        },        
        requestHeaders: buildRequestHeaders(accessToken),
    }).then(
        (data) =>
            (data.prefilteredReports?.prefilteredRecommendationsReport
                .averageMarginalAbatementCostByMeasureCategoryChart as AverageMarginalAbatementCostByMeasureCategoryChartData) ||
            null
    );

const query = graphql(`
    query averageMarginalAbatementCostByMeasureCategoryChart(
        $reportFiltersetUid: Uuid) {
        prefilteredReports(reportFiltersetUid: $reportFiltersetUid) {
            prefilteredRecommendationsReport {
                averageMarginalAbatementCostByMeasureCategoryChart {
                    carbonReductionMeasureCategory
                    averageMarginalAbatementCost
                    totalLifetimeCarbonEmissionSavings
                }
            }
        }
    }
`);

export default queryFn;
