import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

// hooks
import LoadingSpinner from "components/atoms/LoadingSpinner/LoadingSpinner";
import Chatbot from "components/organisms/Chatbot/Chatbot";
import { usePermissions } from "hooks/usePermissions";
import { useFunds } from "queries/Funds/useFunds";
import { useProperties } from "queries/useProperties";
import useBuildingsPageState from "recoilStore/useBuildingsPageState";
import { UserPermissions } from "utils/enums";

// components
import TableLoading from "components/molecules/TableLoading";
import { ErrorPage } from "pages/ErrorState";
import Buildings from "./Buildings";
import DataCoverageHeader from "./DataCoverageHeader";
import Funds from "./Funds";
import { useBuildingsData } from "./hooks";
import Properties from "./Properties";

import PageHeader from "./HeaderComponents/PageHeader";
import "./PortfolioPage.scss";
import TabType from "./PortfolioPage.types";
import ViewHeader from "./ViewHeader";


function PortfolioPage() {
    const { pageState, setPageState } = useBuildingsPageState();
    const { hasPermission, isFetching } = usePermissions();

    const [searchParams, setSearchParams] = useSearchParams();
    const selectedTabFromUrl = (searchParams.get("tab") as TabType) || "buildings";

    const isValidTabType = (tab: string): tab is TabType => ["buildings", "properties", "funds"].includes(tab);

    const defaultTab: TabType = isValidTabType(selectedTabFromUrl) ? selectedTabFromUrl : "buildings";
    const [selectedTab, setSelectedTab] = useState<TabType>(defaultTab);


    useEffect( () => {
        setSearchParams({ tab: selectedTab });
    }, [selectedTab, selectedTabFromUrl])  

    const tabClickHandler = (tabName: TabType) => {
        setSearchParams({ tab: tabName });
        setSelectedTab(tabName)
    }
    const { isLoading, numberOfBuildings, error, ...rest } = useBuildingsData();

    const [selectedProperties, setSelectedProperties] = useState<Property[]>(
        []
    );

    const {
        isLoading: propertiesLoading,
        data: propertiesData,
        error: propertiesError,
    } = useProperties();

    const {
        isLoading: fundsLoading,
        data: fundsData,
        error: fundsError,
    } = useFunds();

    if (isLoading || propertiesLoading || fundsLoading) return <TableLoading />;
    if (error || propertiesError || fundsError) return <ErrorPage />;

    const handleViewChange = (view: PortfolioPageView) => {
        setPageState((prev) => {
            if (selectedTab === "buildings")
                return { ...prev, buildingsView: view };
            return { ...prev, propertiesView: view };
        });
    };

    if (isFetching) return <LoadingSpinner />;
    
    return (
        <div className="portfolio-page">
            <PageHeader />
            {selectedTab === "buildings" && (
                <DataCoverageHeader dataCoverages={rest.dataCoverages} />
            )}
            <ViewHeader
                numberOfBuildings={numberOfBuildings}
                numberOfProperties={propertiesData?.length}
                numberOfFunds={fundsData?.length}
                handleViewChange={handleViewChange}
                currentView={
                    selectedTab === "buildings"
                        ? pageState.buildingsView
                        : pageState.propertiesView
                }
                selectedTab={selectedTab}
                tabClickHandler={tabClickHandler}
                buildingName={rest.buildingName || undefined}
                numberOfSelectedBuildings={rest.selectedBuildingRows.length}
                selectedBuildingRows={rest.selectedBuildingRows}
                setSelectedBuildingRows={rest.setSelectedBuildingRows}
                selectedProperties={selectedProperties}
                setSelectedProperties={setSelectedProperties}
                selectedTags={rest.selectedTags}
                selectedTagsAreMixed={rest.tagsAreMixed}
            />
            {selectedTab === "buildings" && (
                <Buildings
                    buildingsData={{
                        isLoading,
                        numberOfBuildings,
                        error,
                        ...rest,
                    }}
                    view={pageState.buildingsView}
                />
            )}
            {selectedTab === "properties" && propertiesData && (
                <Properties
                    properties={propertiesData}
                    pageState={pageState.propertiesView}
                    selectedProperties={selectedProperties}
                    setSelectedProperties={setSelectedProperties}
                />
            )}
            {selectedTab === "funds" && fundsData && (
                <Funds funds={fundsData} />
            )}

        {hasPermission(UserPermissions.UseInternalTools) && 
            <Chatbot 
                title="Portfolio Chat"
                page="portfolio"
            />
        }

        </div>
    );
}



export default PortfolioPage;
