/* eslint-disable max-len */
import { request } from "graphql-request";
import { graphql } from "gql/gql";

import { BACKEND_DOMAIN } from "utils/constants";
import {
    buildRequestHeaders,
    buildBuildingModelRequestVariables,
} from "utils/helpers";

const queryFn = (accessToken: string, buildingModelRunUid: string) =>
    request({
        url: `${BACKEND_DOMAIN}/graphql`,
        document: query,
        variables: {buildingModelRunUid},
        requestHeaders: buildRequestHeaders(accessToken),
    }).then((data) => {
        if (data.carbonReductionPlanReports.error)
            throw new Error(data.carbonReductionPlanReports.error);
        else
            return data.carbonReductionPlanReports
                ?.annualEmissionSavingsByMeasureChart as AnnualEmissionSavingsByMeasureChartData;
    });

const query = graphql(`
    query buildingModelRecommendationsAnnualEmissionSavingsByMeasureChart(
        $buildingModelRunUid: Uuid!
    ) {
        carbonReductionPlanReports(buildingModelRunUid: $buildingModelRunUid) {
            error
            annualEmissionSavingsByMeasureChart {
                carbonEmissionSavingsFromGrid
                carbonReductionMeasureCategories {
                    carbonReductionMeasureCategory
                    totalCarbonEmissionSavings
                }
                remainingCarbonEmissions
                year
            }
        }
    }
`);

export default queryFn;
