import { useEffect, useMemo } from "react";

import { Table } from "components/molecules/Table";
import { getDisplayInfo } from "utils/formatting";

import "./MeasuresProjectsReport.scss";
import { ComponentErrorBoundary } from "components/molecules/ErrorStates";
import errorFallbackUI from "components/molecules/ErrorFallbackUI.tsx/ErrorFallbackUI";
import { useNavigate } from "react-router-dom";
import posthog from "posthog-js";
import useFiltersetState from "recoilStore/Filters/useFilterset";
import useQueryProjects from "ApiLayer/Project/queryProjects";
import useQueryBuildings from "ApiLayer/Building/queryBuildings";
import { falseProjects } from "utils/constants";
import { formatProjectsData } from "utils/helpers";

interface MeasuresProjectsReportProps {
    setExportData: (data: any) => void;
}

function MeasuresProjectsReport({
    setExportData,
}: MeasuresProjectsReportProps) {
    // Hooks
    const { state: filtersetState } = useFiltersetState();
    const filtersetId = filtersetState.filterset;
    const { data: buildingsResponse } = useQueryBuildings(filtersetId);
    const { data: projectsResponse } = useQueryProjects(filtersetId);
    const navigate = useNavigate();

    const formattedData = formatProjectsData(buildingsResponse, projectsResponse);

    const excludedKeys = ["buildingArchetype", "stateProvince", "tags"];
    const tableColumns = useMemo(() => {
        if (!formattedData || formattedData.length === 0) return null;
        const formattedDataKeys = Object.keys(formattedData[0])
            .filter(key => !excludedKeys.includes(key));

        return formattedDataKeys.map((key) => {
            const typedKey = key as keyof MeasuresProjectsReportTableRow;
            if (typeof formattedData[0][typedKey] === "string") {
                const { formatFunction, humanReadable, tooltip } =
                    getDisplayInfo(key);

                return {
                    render: formatFunction,
                    key,
                    title: humanReadable,
                    tooltip,
                };
            }

            const columnValues = formattedData.map(
                (row) => row[typedKey]
            ) as number[];

            const { formatFunction, humanReadable, unit, tooltip } =
                getDisplayInfo(key, columnValues);

            return {
                render: formatFunction,
                key,
                title: `${humanReadable} ${unit && `(${unit})`}`,
                tooltip,
            };
        });
    }, [formattedData]);

    const dataSource = useMemo(() => {
        if (!formattedData) return null;

        const filteredData = formattedData.filter(
            (row) => !falseProjects.includes(row.carbonReductionMeasureType)
        );

        return filteredData.map((row, index) => ({
            ...row,
            key: `${row.carbonReductionMeasureType
                    ? row.carbonReductionMeasureType
                    : "defaultKey"
                }-${index}`,
        }));
    }, [formattedData]);

    const exportData = dataSource?.map(row => ({
        ...row,
        tags: Array.isArray(row.tags) ? row.tags.join(', ') : ''
    }));

    useEffect(() => {
        // Using preventive state update to avoid a rerender that bugs pagination
        if (exportData) {
            setExportData((prev: any) => {
                if (JSON.stringify(prev) !== JSON.stringify(exportData)) {
                    return exportData;
                }
                return prev;
            });
        }

    }, [exportData]);

    if (!dataSource || !tableColumns) return null;

    const handleProjectRowClick = (row: MeasuresProjectsReportTableRow) => {
        const {
            buildingModelUid: uid,
            carbonReductionMeasureType,
            carbonReductionMeasureCategory,
        } = row;

        posthog.capture("projectsRowClick", { carbonReductionMeasureType });

        if (uid)
            navigate(
                `/building/${uid}?tab=projects&carbonReductionMeasureType=${carbonReductionMeasureType}&carbonReductionMeasureCategory=${carbonReductionMeasureCategory}`
            );
    };

    return (
        <div className="measures-projects-report">
            <ComponentErrorBoundary
                fallback={errorFallbackUI("tableError")}
                originComponent="MeasuresProjectsReport"
            >
                <Table
                    dataSource={dataSource}
                    columns={tableColumns}
                    className="projects-report-table"
                    pagination={true}
                    onRowClick={(row) => handleProjectRowClick(row)}
                />
            </ComponentErrorBoundary>
        </div>
    );
}

export default MeasuresProjectsReport;
