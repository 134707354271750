import { Delete } from "components/atoms/Icon";
import { Paragraph } from "components/atoms/Typography";
import styles from "./HeaderSharedStyles.module.scss";

interface DeleteCustomPlanButtonProps {
    setDeleteCustomPlanModalIsOpen: (isOpen: boolean) => void;
}

function DeleteCustomPlanButton({
    setDeleteCustomPlanModalIsOpen
}: DeleteCustomPlanButtonProps) {

    const onClickDelete = () => {
        setDeleteCustomPlanModalIsOpen(true);
    };

    return (
        <Paragraph style={{ color: "var(--audette-content-negative)" }}>
            <button
                className={styles.deleteCustomPlanButton}
                type="button"
                onClick={onClickDelete}
            >
                <Delete />
                Delete Custom Plan
            </button>
        </Paragraph>
    );
}

export default DeleteCustomPlanButton;
