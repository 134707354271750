import DOMPurify from "dompurify";
import Sidebar from "components/molecules/Sidebar";
import { useEffect, useRef, useState } from "react";
import useBuildingChatbotMutation from "ApiLayer/Chatbot/queryBuildingChatbot";
import usePortfolioChatbotMutation from "ApiLayer/Chatbot/queryPortfolioChatbot";
import { useBuildingModelUid } from "hooks/useBuildingModelUid";
import { CogWheelIcon, UploadIcon } from "components/atoms/Icon";
import { scrollToBottom } from "hooks/UI/useScrollToBottom";
import HoldingPediodModal from "components/organisms/Modals/holdingPeriodModal/holdingPeriodModal";
import {
    buildingChatbotWelcomeMessage,
    Message,
    portfolioChatbotWelcomeMessage,
} from "./DialogSidebar.constants";
import styles from "./DialogSidebar.module.scss";

interface DialogSidebarProps {
    handleClose: () => void;
    sidebarOpen: boolean;
    title: string;
    page: PageWithChatbot;
}

function DialogSidebar({
    handleClose,
    sidebarOpen,
    title,
    page,
}: DialogSidebarProps) {
    // Hooks
    const buildingModelUid = page === "building" ? useBuildingModelUid() : null;
    const { mutate: sendMessageBuilding, isLoading: isLoadingBuilding } =
        useBuildingChatbotMutation();
    const { mutate: sendMessagePortfolio, isLoading: isLoadingPortfolio } =
        usePortfolioChatbotMutation();

    // Variables
    const isLoading = isLoadingBuilding || isLoadingPortfolio;

    const chatbotWelcomeMessages = {
        building: buildingChatbotWelcomeMessage,
        portfolio: portfolioChatbotWelcomeMessage,
    };

    const chatbotWelcomeMessage = chatbotWelcomeMessages[page];

    // State
    const [messages, setMessages] = useState<Message[]>([
        chatbotWelcomeMessage,
    ]);
    const [currentMessage, setCurrentMessage] = useState("");
    const [isExpanded, setIsExpanded] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [holdingPeriod, setHoldingPeriod] = useState(10);

    // Refs
    const textareaRef = useRef<HTMLTextAreaElement | null>(null);
    const messagesEndRef = useRef<null | HTMLDivElement>(null);

    // Effects
    useEffect(() => {
        if (textareaRef.current) {
            // Reset height to default when message is cleared
            textareaRef.current.style.height = currentMessage ? "auto" : "40px";
            if (currentMessage) {
                textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
            }
        }
    }, [currentMessage]);

    useEffect(() => {
        scrollToBottom(messagesEndRef);
    }, [messages]);

    const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setCurrentMessage(e.target.value);

        resizeTextArea();
    };

    // Functions
    const handleKeyPress = (e: React.KeyboardEvent) => {
        if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault();
            handleSendMessage();
        }
    };

    const resizeTextArea = () => {
        if (textareaRef.current) {
            textareaRef.current.style.height = "auto";
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        }
    };

    const handleSendMessage = async () => {
        if (!currentMessage.trim()) return;

        setMessages((prev) => [
            ...prev,
            { text: currentMessage, type: "send" },
        ]);

        if (page === "building" && buildingModelUid) {
            sendMessageBuilding(
                { message: currentMessage, buildingModelUid },
                {
                    onSuccess: (response) => {
                        setMessages((prev) => [
                            ...prev,
                            { text: response.response, type: "receive" },
                        ]);
                    },
                    onError: (response: any) => {
                        setMessages((prev) => [
                            ...prev,
                            { text: response.message || 'An error occurred. Read the message at the top of the screen for more information', type: "receive" },
                        ]);
                    }
                }
            );
        } else if (page === "portfolio") {
            sendMessagePortfolio(
                { message: currentMessage, holdPeriod: holdingPeriod },
                {
                    onSuccess: (response) => {
                        setMessages((prev) => [
                            ...prev,
                            { text: response.response, type: "receive" },
                        ]);
                    },
                    onError: (response: any) => {
                        setMessages((prev) => [
                            ...prev,
                            { text: response.message || 'An error occurred. Read the message at the top of the screen for more information', type: "receive" },
                        ]);
                    }
                }
            );
        } else {
            throw new Error(`Invalid page type: ${page}`);
        }

        setCurrentMessage("");
    };

    return (
        <>
            <Sidebar
                onCancel={handleClose}
                sidebarOpen={sidebarOpen}
                title={title}
                isExpanded={isExpanded}
                canBeExpanded={true}
                setIsExpanded={setIsExpanded}
            >
                <div className={styles.container}>
                    {messages.map((message) => (
                        // !!!!! THIS IS TEMPORARY AND WRONG - USE UID FROM API OR LIBRARY TO GENERATE ID FOR USER  !!!!!
                        <p key={message.text} className={styles[message.type]}>
                            {DOMPurify.sanitize(message.text)}
                        </p>
                    ))}
                    {isLoading && (
                        <p className={`${styles.receive} loading`}>...</p>
                    )}
                    <div ref={messagesEndRef} />
                </div>

                <div className={styles.chatTextContainer}>
                    <textarea
                        ref={textareaRef}
                        value={currentMessage}
                        className={styles.chatTextInput}
                        placeholder={
                            isLoading
                                ? "Thinking - wait a moment..."
                                : "Ask AI anything..."
                        }
                        maxLength={1000}
                        onChange={handleInputChange}
                        // onInput={autoResize}
                        onKeyDown={handleKeyPress}
                        // onSubmit={handleSendMessage}
                        disabled={isLoading}
                    />
                    <button
                        onClick={handleSendMessage}
                        disabled={!currentMessage.trim()}
                        // disabled={isLoading || !currentMessage.trim()}
                        type="button"
                        style={{
                            marginLeft: "auto",
                            opacity:
                                currentMessage.trim().length === 0 ? 0.2 : 1,
                            transition: "opacity 0.2s ease-in-out",
                        }}
                    >
                        <UploadIcon />
                    </button>
                    { page === "portfolio" && (
                        <button
                            onClick={() => setModalIsOpen(true)}
                            type="button"
                            className={styles.settingsButton}
                        >
                            <CogWheelIcon  color="var(--audette-gray-400)"/>
                        </button>
                    )}
                </div>
            </Sidebar>

            {page === "portfolio" && (
                <HoldingPediodModal
                    modalIsOpen={modalIsOpen}
                    onCancel={() => setModalIsOpen(false)}
                    setModalIsOpen={setModalIsOpen}
                    setHoldingPeriod={setHoldingPeriod}
                    holdingPeriod={holdingPeriod}
                />
            )}
        </>
    );
}

export default DialogSidebar;
