import { useMutation, useQueryClient } from "react-query";
import { useAccessToken } from "hooks/useAccessToken";
import useAlert from "hooks/useAlert";
import { showMutationAlert } from "utils/helpers";
import mutationFn from "../ApiLayer/Tags/createBuildingModelTags";

export const useCreateBuildingModelTags = (buildingModelUids: string[]) => {
    const accessToken = useAccessToken();
    const queryClient = useQueryClient();
    const alert = useAlert();

    return useMutation({
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ["buildingModelsTable"],
            });

            buildingModelUids.forEach((uid) => {
                queryClient.invalidateQueries({
                    queryKey: ["buildingModelHeader", uid],
                });
            });
        },
        mutationFn: async (
            data: {
                buildingModelUid: string;
                tags: string[];
            }[]
        ) => mutationFn(accessToken, data),
        onError: (errors) => {
            showMutationAlert(errors, alert);
        }
    });
};

