import { useState } from "react";

// components
import ChartContainer from "components/molecules/ChartContainer";
import { ChartErrorState } from "components/molecules/ErrorStates";
import AnnualEmissionSavingsByMeasureTable from "components/organisms/Charts/Recommendations/AnnualEmissionSavingsByMeasureTable";

// query hooks
import { useCumulativeEmissionSavingsCard } from "queries/Reports/Recommendations/useCumulativeEmissionSavingsCard";
import { useAnnualEmissionSavingsByMeasureTable } from "queries/Reports/Recommendations/useAnnualEmissionSavingsByMeasureTable";
import { useAnnualEmissionSavingsByMeasureChart } from "queries/Reports/Recommendations/useAnnualEmissionSavingsByMeasureChart";
import AnnualEmissionSavingsByMeasureChart from "components/organisms/Charts/Recommendations/AnnualEmissionSavingsByMeasureChart";

// styling
import CumulativeEmissionSavingsCard from "components/organisms/Charts/Recommendations/CumulativeEmissionSavingsCard";
import { ChartNoDataState } from "components/molecules/NoDataState";


function AnnualEmissionSavingsByMeasures() {
    const [showChart, setShowChart] = useState(true);
    const {
        isLoading: chartIsLoading,
        error: chartError,
        data: chartData,
    } = useAnnualEmissionSavingsByMeasureChart();
    const {
        isLoading: tableIsLoading,
        error: tableError,
        data: tableData,
    } = useAnnualEmissionSavingsByMeasureTable();

    if (chartError || tableError) return <ChartErrorState />;
    if (chartData === null || tableData === null) return <ChartNoDataState />;

    return (
        <ChartContainer
            title="Recommended decarbonization measure types"
            loading={chartIsLoading || tableIsLoading}
            onClickTable={() => setShowChart((show) => !show)}
        >
            {showChart ? (
                <>
                    <AnnualEmissionSavingsByMeasureChart
                        query={useAnnualEmissionSavingsByMeasureChart}
                    />
                    <CumulativeEmissionSavingsCard
                        query={useCumulativeEmissionSavingsCard}
                    />
                </>
            ) : (
                <AnnualEmissionSavingsByMeasureTable
                    query={useAnnualEmissionSavingsByMeasureTable}
                />
            )}
        </ChartContainer>
    );
}

export default AnnualEmissionSavingsByMeasures;