import { useState, useRef, useEffect, ReactNode } from "react";
import { useClickOutside } from "hooks/useClickOutside";
import classNames from "classnames";
import { Paragraph, Header } from "../Typography";
import { DownCarrot } from "../Icon";

import "./index.scss";

export interface Item {
    id: any;
    displayValue: string;
    element?: ReactNode;
    itemId?: string;
}
interface DropdownProps {
    items: Item[];
    onSelectItem: (item: Item) => void;
    defaultItem?: Item;
    placeholder?: string;
    classname?: string;
    style?: React.CSSProperties;
    selectId?: string;
    selectorDataTestId?: string;
    hideTitle?: boolean;
    humanReadable?: string;
    required?: boolean;
}

function CarrotIcon({ open }: { open: boolean }) {
    return (
        <div className={`dropdown-carrot-icon ${open && "open"}`}>
            <DownCarrot />
        </div>
    );
}

function Dropdown({
    items,
    defaultItem,
    onSelectItem,
    placeholder,
    classname,
    style,
    selectId,
    selectorDataTestId,
    hideTitle = true,
    humanReadable = "",
    required,
}: DropdownProps) {
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const ref = useRef(null);
    useClickOutside(ref, () => setMenuIsOpen(false));

    const { initialDisplayValue, setInitialDisplayValue } =
        useInitialDisplayValue(defaultItem, placeholder);

    return (
        <div className="dropdown-container">
            {!hideTitle && (
                <Header size="small">
                    {humanReadable}
                    {required && (
                        <span
                            style={{ color: "var(--audette-content-negative)" }}
                        >
                            *
                        </span>
                    )}
                </Header>
            )}
            <div
                className={classNames(
                    "single-select-dropdown",
                    classname && classname
                )}
                ref={ref}
                style={style}
            >
                <button
                    type="button"
                    id={selectId}
                    className="selector"
                    onClick={() => {
                        setMenuIsOpen((open) => !open);
                    }}
                    data-testid={selectorDataTestId}
                >
                    {typeof initialDisplayValue === "string" ? (
                        <>
                            <Paragraph size="small">
                                {" "}
                                {initialDisplayValue}{" "}
                            </Paragraph>
                            <CarrotIcon open={menuIsOpen} />{" "}
                        </>
                    ) : (
                        <>
                            {initialDisplayValue}
                            <CarrotIcon open={menuIsOpen} />{" "}
                        </>
                    )}
                </button>
                {menuIsOpen && (
                    <div className="menu">
                        {items.map((item) => (
                            <Paragraph size="small" key={item.id}>
                                <button
                                    type="button"
                                    id={`dropdown-item-${item.id}`}
                                    className="menu-item"
                                    onClick={() => {
                                        setInitialDisplayValue(
                                            item.element || item.displayValue
                                        );
                                        onSelectItem(item);
                                        setMenuIsOpen(false);
                                    }}
                                >
                                    {item.displayValue}{" "}
                                </button>
                            </Paragraph>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
}

const useInitialDisplayValue = (defaultItem?: Item, placeholder?: string) => {
    const defaultItemDisplayValue =
        defaultItem?.element ||
        defaultItem?.displayValue ||
        placeholder ||
        "Select an option";

    const [initialDisplayValue, setInitialDisplayValue] = useState(
        defaultItemDisplayValue
    );

    useEffect(() => {
        setInitialDisplayValue(defaultItemDisplayValue);
    }, [defaultItemDisplayValue]);

    return { initialDisplayValue, setInitialDisplayValue };
};

export default Dropdown;


