import { request } from "graphql-request";
import { graphql } from "gql/gql";

import { BACKEND_DOMAIN } from "utils/constants";
import {
    buildRequestHeaders,
} from "utils/helpers";

const queryFnEnergyConsumptionCard = (
    accessToken: string, 
    data: {
        reportFiltersetUid: string | null;
    }
) =>
    request({
        url: `${BACKEND_DOMAIN}/graphql`,
        document: query,
        variables: {
            reportFiltersetUid: data.reportFiltersetUid,
        },
        requestHeaders: buildRequestHeaders(accessToken),
    }).then((data) => {
        if (
            data.prefilteredReports?.prefilteredBaselineReport.currentAnnualEnergyConsumptionCard
                .error
        )
            throw new Error(
                // eslint-disable-next-line max-len
                data.prefilteredReports?.prefilteredBaselineReport.currentAnnualEnergyConsumptionCard.error
            );
        return (
            data.prefilteredReports?.prefilteredBaselineReport.currentAnnualEnergyConsumptionCard ||
            null
        );
    });

const query = graphql(`
    query currentAnnualEnergyConsumptionCard($reportFiltersetUid: Uuid) {
        prefilteredReports(reportFiltersetUid: $reportFiltersetUid) {
            prefilteredBaselineReport {
                currentAnnualEnergyConsumptionCard {
                    error
                    averageEnergyIntensity
                    totalEnergyConsumption
                }
            }
        }
    }
`);

export default queryFnEnergyConsumptionCard;
