import { useMutation, useQueryClient } from "react-query";
import { BACKEND_DOMAIN } from "utils/constants";
import { buildRequestHeaders, showMutationAlert } from "utils/helpers";
import { request } from "graphql-request";
import { useAccessToken } from "hooks/useAccessToken";
import { graphql } from "gql/gql";
import useAlert from "hooks/useAlert";
import { buildingModelQueryKey } from "hooks/Building/useBuildingModel";

interface MutationFnVariables {
    buildingProperties: BuildingProperties;
    buildingUid?: string | null;
    buildingFootprint?: any;
}

export const useUpdateBuildingModelMutation = (
    buildingModelUid: string,
    onSuccess: () => void,
    onError: () => void
) => {
    const accessToken = useAccessToken();
    const alert = useAlert();
    const queryClient = useQueryClient();

    return useMutation({
        onSuccess: () => {
            onSuccess();
            queryClient.invalidateQueries(buildingModelQueryKey);
            alert.success("Construction data updated.");
        },
        onError: (errors) => {
            onError();
            showMutationAlert(errors, alert);
        },
        mutationFn: async ({
            buildingProperties,
            buildingUid,
            buildingFootprint,
        }: MutationFnVariables) =>
            mutationFn(
                accessToken,
                buildingModelUid,
                buildingProperties,
                buildingUid,
                buildingFootprint
            ),
    });
};

const mutationFn = (
    accessToken: string,
    buildingModelUid: string,
    buildingProperties: BuildingProperties,
    buildingUid?: string | null,
    buildingFootprint?: any
) =>
    request({
        url: `${BACKEND_DOMAIN}/graphql`,
        document: mutation,
        variables: {
            buildingModelUid,
            buildingProperties: {
                ...buildingProperties,
                buildingUid,
                buildingFootprint,
            },
        },
        requestHeaders: buildRequestHeaders(accessToken),
    }).then((data) => {
        if (data.updateBuildingModel.error)
            throw new Error(data.updateBuildingModel.error);
        return data.updateBuildingModel;
    });

const mutation = graphql(`
    mutation updateBuildingModel(
        $buildingModelUid: Uuid!
        $buildingProperties: BuildingProperties!
    ) {
        updateBuildingModel(
            buildingModelUid: $buildingModelUid
            buildingProperties: $buildingProperties
        ) {
            error
            buildingModelRunUid
            buildingModelUid
        }
    }
`);
