import {
    createContext,
    useContext,
    useMemo,
    useState,
    ReactNode,
} from "react";

interface TooltipDataContextValue {
    buckets: ChartBucket[];
    setBuckets: (buckets: ChartBucket[]) => void;
}

const TooltipDataContext = createContext<TooltipDataContextValue | null>(null);

export function TooltipDataContextProvider({
    children,
}: {
    children: ReactNode;
}) {
    const [buckets, setBuckets] = useState<ChartBucket[]>([]);
    const dataContexValue = useMemo(() => ({ buckets, setBuckets }), [buckets]);

    return (
        <TooltipDataContext.Provider value={dataContexValue}>
            {children}
        </TooltipDataContext.Provider>
    );
}

export const useTooltipDataContext = () => {
    const context = useContext(TooltipDataContext);
    if (context === null)
        throw new Error(
            "TooltipDataContext must be inside a TooltipDataContextProvider"
        );
    return context;
};

export interface ChartBucket {
    average: null | number;
    min: null | number;
    max: null | number;
    buildings: BuildingDatum[];
}

export interface BuildingDatum {
    label: string;
    address?: string;
    value: number;
    key: string;
    selectedKey: keyof BarChartAndMapData;
}
