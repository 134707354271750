import posthog from "posthog-js";
import { useEffect, useMemo, useState } from "react";

// components
import LoadingSpinner from "components/atoms/LoadingSpinner/LoadingSpinner";
import Filter from "components/molecules/Filter/Filter";
import FilterContainer from "components/molecules/FilterContainer/FilterContainer";
import Sidebar from "components/molecules/Sidebar";

// hooks
import useAlert from "hooks/useAlert";
import { useDebounce } from "hooks/useDebounce";
import { useCreateReportFilterset } from "mutations/Measures/useCreateReportFiltersetMutation";
import useFilters, { emptyFilters } from "recoilStore/useFilters";

import {
    buildingsFilterKeyToInfoMap,
    reportProjectsFilterKeyToInfoMap,
} from "./FilterSidebar.constants";

// helpers

import {
    buildIsFieldActiveMap,
    cloneFilters,
    getFilterNameFromKey,
    removeFilter,
} from "./FilterSidebar.helpers";

// styling
import FiltersSidebarFooter from "./Filter/FiltersSidebarFooter";
import "./index.scss";

interface FiltersSidebarProps {
    closeSidebar: () => void;
    sidebarOpen: boolean;
    page: Page;
    currentActiveTab?: ReportTab;
}

function FiltersSidebar({
    page,
    closeSidebar,
    sidebarOpen,
    currentActiveTab,
}: FiltersSidebarProps) {
    // Hook
    const { setFilters, filters } = useFilters(page);
    const [localFilters, setLocalFilters] = useState(cloneFilters(filters));
    const [isFieldActiveMap, setIsFieldActiveMap] = useState(
        buildIsFieldActiveMap(filters)
    );
    const {
        mutate: mutateCreateReportFilterset,
        isLoading: isCreateFiltersetLoading
    } = useCreateReportFilterset();
    const alert = useAlert();


    const debouncedSetLocalFilters = useDebounce(setLocalFilters, 300);

    const numberOfLocalFilters = useMemo(() => {
        const {
            numericFilters,
            stringFilters,
            dateFilters,
            dataCoverageFilter,
        } = localFilters;
        return (
            numericFilters.length +
            stringFilters.length +
            dateFilters.length +
            dataCoverageFilter.length
        );
    }, [localFilters]);

    useEffect(() => {
        setLocalFilters(cloneFilters(filters));
        setIsFieldActiveMap(buildIsFieldActiveMap(filters));
    }, [filters]);

    const handleApplyFilters = async () => {
        await Promise.resolve(setFilters(localFilters));
        const posthogSimplifiedFilters = {
            ...localFilters,
            numericFilters: localFilters.numericFilters.map(
                ({ field }) => field
            ),
            stringFilters: localFilters.stringFilters.map(({ field }) => field),
        };

        posthog.capture("Filters applied", posthogSimplifiedFilters);


        if (page === "reports" ) {
            mutateCreateReportFilterset(undefined, {
                // Got to specify undefined to avoid TS error
                onSuccess: () => {
                    alert.success("report filterset created");
                    closeSidebar()
                },
            });        
        }

        setIsFieldActiveMap(buildIsFieldActiveMap(localFilters));
    };

    const handleClearAll = () => {
        setLocalFilters(emptyFilters);
        posthog.capture("Filters cleared");
        setIsFieldActiveMap(buildIsFieldActiveMap(emptyFilters));
    };

    const handleCancel = () => {
        posthog.capture("Filters closed");
        setLocalFilters({ ...filters });
        closeSidebar();
        setIsFieldActiveMap(buildIsFieldActiveMap(filters));
    };

    const handleClearFilter = (filterField: string) =>
        setLocalFilters((prev) => removeFilter(prev, filterField));

    const handleSelectFilter = (checked: boolean, key: string) => {
        setIsFieldActiveMap((prev) => {
            const copy = new Map(prev);
            copy.set(key, checked);
            return copy;
        });
    };

    const filtersMap = useMemo(() => {
        if (page === "measures") return buildingsFilterKeyToInfoMap;
        if (page === "reports" && currentActiveTab === "projects") return reportProjectsFilterKeyToInfoMap;
        return buildingsFilterKeyToInfoMap;
    }, [currentActiveTab]);

    if (isCreateFiltersetLoading) return (
        <Sidebar
            onCancel={handleCancel}
            sidebarOpen={sidebarOpen}
            title={`Filters ${
                numberOfLocalFilters > 0 ? `(${numberOfLocalFilters})` : ""
            }`}
        >
            <LoadingSpinner />
        </Sidebar>
    )

    return (
        <Sidebar
            onCancel={handleCancel}
            sidebarOpen={sidebarOpen}
            title={`Filters ${
                numberOfLocalFilters > 0 ? `(${numberOfLocalFilters})` : ""
            }`}
        >
            <div className="filters-sidebar__body">
                {Object.keys(filtersMap).map((key) => {
                    const { type, field } = filtersMap[key];
                    if (
                        key === "createdAt" &&
                        (page === "reports" || page === "measures")
                    )
                        return null;
                    return (
                        <FilterContainer
                            name={getFilterNameFromKey(key)}
                            checked={!!isFieldActiveMap.get(field)}
                            setChecked={(checked) =>
                                handleSelectFilter(checked, field)
                            }
                            key={field}
                            handleUncheck={() => handleClearFilter(field)}
                        >
                            <Filter
                                filters={localFilters}
                                setFilters={debouncedSetLocalFilters}
                                filterKey={key}
                                filterField={field}
                                type={type}
                            />
                        </FilterContainer>
                    );
                })}
            </div>
            <FiltersSidebarFooter
                onClickApply={handleApplyFilters}
                onClickCancel={handleCancel}
                onClickClearAll={handleClearAll}
                page={page}
                localFilters={localFilters}
                isLoading={isCreateFiltersetLoading}

            />
        </Sidebar>
    );
}

export default FiltersSidebar;
