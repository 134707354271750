import { useMutation, useQueryClient } from "react-query";
import { useAccessToken } from "hooks/useAccessToken";
import useAlert from "hooks/useAlert";
import { showMutationAlert } from "utils/helpers";
import mutationFn from "../ApiLayer/updateBuildingName";

export const useUpdateBuildingName = (buildingModelUid: string) => {
    const accessToken = useAccessToken();
    const queryClient = useQueryClient();
    const alert = useAlert();

    return useMutation({
        onSuccess: (buildingName) => {
            queryClient.setQueryData(
                ["buildingModel", buildingModelUid],
                (prev: any) => {
                    const returnObj = prev
                        ? {
                              ...prev,
                              buildingName,
                          }
                        : prev;
                    return returnObj;
                }
            );
            const cachedTableData = queryClient.getQueryData([
                "buildingModelsTable",
            ]);
            if (cachedTableData)
                queryClient.setQueryData(
                    ["buildingModelsTable"],
                    (prev: BuildingModelsTableDatum[] | undefined) => {
                        const index = prev!.findIndex(
                            (datum) =>
                                datum.buildingModelUid === buildingModelUid
                        );
                        // eslint-disable-next-line no-param-reassign
                        prev![index] = { ...prev![index], buildingName };
                        return prev!;
                    }
                );

            const cachedHeaderData = queryClient.getQueryData([
                "buildingModelHeader",
                buildingModelUid,
            ]);
            if (cachedHeaderData)
                queryClient.setQueryData(
                    ["buildingModelHeader", buildingModelUid],
                    (prev: BuildingModelHeader | undefined) => {
                        // eslint-disable-next-line no-param-reassign
                        prev!.buildingName = buildingName;
                        return prev!;
                    }
                );
        },
        mutationFn: async (buildingName: string) =>
            mutationFn(accessToken, buildingModelUid, buildingName),
        onError: (errors) => {
            showMutationAlert(errors, alert);
        }
    });
};
