import {
    useEffect,
    useMemo,
    useState
} from "react";

// helpers
import { getDisplayInfo, semanticTermToDisplayInfo } from "utils/formatting";

// css
import "../ByBuildingBarChart.css";
import { useTooltipDataContext } from "./hooks/useTooltipDataContext";

export const DROPDOWN_ITEMS_KEYS: string[] = [
    "annualCarbonEmissionsCurrent",
    "annualCarbonEmissionIntensityCurrent",
    "annualEnergyConsumptionCurrent",
    "annualEnergyUseIntensityCurrent",
];
export const NUMBER_OF_BUCKETS = 60;

export const useDisplayInfo = (
    selectedItemId: string,
    data: { label: number; value: number }[]
) =>
    useMemo(
        () =>
            getDisplayInfo(
                selectedItemId,
                data.map(({ value }) => value)
            ),
        [data, selectedItemId]
    );

export const useDropdownItems = () => {
    const items = DROPDOWN_ITEMS_KEYS.map((key) => ({
        id: key,
        displayValue: semanticTermToDisplayInfo[key].humanReadable,
    }));

    const onSelectItem = (id: string) => {
        setSelectedItemId(id as keyof BuildingBarChartAndMap);
    };

    const [selectedItemId, setSelectedItemId] = useState(
        DROPDOWN_ITEMS_KEYS[0]
    );

    return { selectedItemId, setSelectedItemId, items, onSelectItem };
};

export const useData = (
    buildingModels: BarChartAndMapData[] | undefined,
    selectedItemId: string
) => {
    const data = useMemo(() => {
        if (!buildingModels) return [];
        return DROPDOWN_ITEMS_KEYS.map((key) => {
            const { buckets, data } = buildData(
                buildingModels,
                key as keyof BarChartAndMapData
            );
            return { key, data, buckets };
        });
    }, [buildingModels]);

    const { setBuckets } = useTooltipDataContext();
    useEffect(() => {
        const buckets =
            data.find(({ key }) => key === selectedItemId)?.buckets || [];
        setBuckets(buckets);
    }, [selectedItemId, data]);

    return useMemo(
        () => data.find(({ key }) => key === selectedItemId)?.data || [],
        [selectedItemId, data]
    );
};

const buildData = (
    buildingModels: BarChartAndMapData[],
    selectedKey: keyof BarChartAndMapData
) => {
    if (buildingModels.length === 0) return { buckets: [], data: [] };

    const buckets = buildBucketsFromBuildingModels(buildingModels, selectedKey);
    const data = buckets.map(({ average }, index) => ({
        label: index,
        value: average,
    }));

    return { buckets, data };
};

const buildBucketsFromBuildingModels = (
    buildingModels: BarChartAndMapData[],
    selectedKey: keyof BarChartAndMapData
) => {
    const sortedBuildingData = buildingModels
        .map((model) => ({
            label: model.buildingModelUid!,
            address: model.label as string,
            value: model[selectedKey as keyof BarChartAndMapData] as number,
            selectedKey,
            key: model.buildingModelUid || model.label,
        }))
        .sort((a, b) => a.value - b.value);

    const bucketCount = Math.ceil(
        sortedBuildingData.length / NUMBER_OF_BUCKETS
    );

    const buckets: ChartBucket[] = new Array(NUMBER_OF_BUCKETS)
        .fill(null)
        .map(() => ({
            average: null,
            min: null,
            max: null,
            buildings: [],
        }));

    let bucketIndex = 0;
    sortedBuildingData.forEach((building) => {
        const currentEntries = buckets[bucketIndex].buildings.length;
        if (
            currentEntries >= bucketCount &&
            bucketIndex !== NUMBER_OF_BUCKETS - 1
        )
            bucketIndex += 1;
        buckets[bucketIndex].buildings.push(building);
    });

    return buckets
        .filter(({ buildings }: ChartBucket) => buildings.length !== 0)
        .map((bucket) => {
            const numberOfBuildings = bucket.buildings.length;
            const sum = bucket.buildings.reduce(
                (prev, curr) => prev + curr.value,
                0
            );
            const average = sum / numberOfBuildings;
            const min = bucket.buildings[0].value;
            const max = bucket.buildings[numberOfBuildings - 1].value;
            return {
                ...bucket,
                min,
                max,
                average,
            };
        });
};

export interface BuildingDatum {
    label: string;
    address?: string;
    value: number;
    key: string;
    selectedKey: keyof BarChartAndMapData;
}

export interface ChartBucket {
    average: null | number;
    min: null | number;
    max: null | number;
    buildings: BuildingDatum[];
}