import { request } from "graphql-request";
import { graphql } from "gql/gql";

import { BACKEND_DOMAIN } from "utils/constants";
import {
    buildRequestHeaders,
} from "utils/helpers";

const queryFnBuildingBarChart = (
    accessToken: string,
    data: {
        reportFiltersetUid: string | null;
    }
) =>
    request({
        url: `${BACKEND_DOMAIN}/graphql`,
        document: query,
        variables: {
            reportFiltersetUid: data.reportFiltersetUid,
        },
        requestHeaders: buildRequestHeaders(accessToken),
    }).then(
        (data) =>
            data.prefilteredReports?.prefilteredBaselineReport
                ?.buildingBarChartAndMapByProperty as BuildingBarChartAndMapByProperty[]
    );

const query = graphql(`
    query buildingBarChartAndMapByProperty($reportFiltersetUid: Uuid) {
        prefilteredReports(reportFiltersetUid: $reportFiltersetUid) {
            prefilteredBaselineReport {
                buildingBarChartAndMapByProperty {
                    averageAnnualCarbonEmissionIntensityCurrent
                    averageAnnualEnergyUseIntensityCurrent
                    latitude
                    longitude
                    propertyName
                    totalAnnualCarbonEmissionsCurrent
                    totalAnnualEnergyConsumptionCurrent
                }
            }
        }
    }
`);

export default queryFnBuildingBarChart;