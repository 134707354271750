import { useEffect, useState } from "react";

// components
import ChartContainer from "components/molecules/ChartContainer";
import { Header } from "components/atoms/Typography";
import { ChartErrorState } from "components/molecules/ErrorStates";

// charts
import AnnualEmissionSavingsByMeasureChart from "components/organisms/Charts/Recommendations/AnnualEmissionSavingsByMeasureChart";
import AnnualCarbonEmissionsChart from "components/organisms/Charts/Recommendations/AnnualCarbonEmissionsChart";
import AnnualCarbonEmissionsNaturalGasChart from "components/organisms/Charts/Recommendations/AnnualCarbonEmissionsNaturalGasChart";
import AnnualCarbonEmissionsElectricityChart from "components/organisms/Charts/Recommendations/AnnualCarbonEmissionsElectricityChart";
import AverageMarginalAbatementCostByMeasureCategoryChart from "components/organisms/Charts/AverageMarginalAbatementCostByMeasureCategoryChart";
import CumulativeInvestmentByDecarbonizationChart from "components/organisms/Charts/Recommendations/CumulativeInvestmentByDecarbonizationChart";
import AnnualCashFlowChart from "components/organisms/Charts/Recommendations/AnnualCashFlowChart";
import AnnualEmissionSavingsByMeasureTable, {
    MeasureDrillDown,
} from "components/organisms/Charts/Recommendations/AnnualEmissionSavingsByMeasureTable";
import CumulativeNetPresentValueCard from "components/organisms/Charts/Recommendations/CumulativeNetPresentValueCard";
import CumulativeOperatingCostCard from "components/organisms/Charts/Recommendations/CumulativeOperatingCostCard";
import CumulativeEmissionSavingsCard from "components/organisms/Charts/Recommendations/CumulativeEmissionSavingsCard";
import FutureProjectionCards from "components/organisms/Charts/Recommendations/FutureProjectionCards";

// hooks
import { useAnnualEmissionSavingsByMeasureTable } from "queries/BuildingReport/Recommendations/useAnnualEmissionSavingsByMeasureTable";
import { useAnnualEmissionSavingsByMeasureChart } from "queries/BuildingReport/Recommendations/useAnnualEmissionSavingsByMeasureChart";
import { useCumulativeEmissionSavingsCard } from "queries/BuildingReport/Recommendations/useCumulativeEmissionSavingsCard";
import { useAnnualCashFlowChart } from "queries/BuildingReport/Recommendations/useAnnualCashFlowChart";
import { useCumulativeOperatingCostCard } from "queries/BuildingReport/Recommendations/useCumulativeOperatingCostCard";
import { useCumulativeNetPresentValueCard } from "queries/BuildingReport/Recommendations/useCumulativeNetPresentValueCard";
import { useCumulativeCarbonEmissionsCard } from "queries/BuildingReport/Recommendations/useCumulativeCarbonEmissionsCard";
import { useCumulativeEnergyConsumptionCard } from "queries/BuildingReport/Recommendations/useCumulativeEnergyConsumptionCard";
import { useAnnualCarbonEmissionsCard } from "queries/BuildingReport/Recommendations/useAnnualCarbonEmissionsCard";
import { useAnnualEnergyConsumptionCard } from "queries/BuildingReport/Recommendations/useAnnualEnergyConsumptionCard";
import { useAnnualCarbonEmissionsChart } from "queries/BuildingReport/Recommendations/useAnnualCarbonEmissionsChart";
import { useAnnualCarbonEmissionsNaturalGasChart } from "queries/BuildingReport/Recommendations/useAnnualCarbonEmissionsNaturalGasChart";
import { useAnnualCarbonEmissionsElectricityChart } from "queries/BuildingReport/Recommendations/useAnnualCarbonEmissionsElectricityChart";
import { useCumulativeInvestmentByDecarbonizationChart } from "queries/BuildingReport/Recommendations/useCumulativeInvestmentByDecarbonizationChart";
import { useAverageMarginalAbatementCostByMeasureCategoryChart } from "queries/BuildingReport/Recommendations/useAverageMarginalAbatementCostByMeasureCategoryChart";

// constants
import { CURRENT_YEAR } from "utils/constants";

// styling
import "./DecarbonizationReport.css";
import { useNavigate, useParams } from "react-router-dom";
import { useBuildingModel } from "hooks/Building/useBuildingModel";

function RecommendationsReport() {
    const navigate = useNavigate();
    const { buildingModelUid, tabName } = useParams();
    const { data: buildingModel } = useBuildingModel(buildingModelUid || "undefined");
    useEffect(() => {
        navigate(
            `/building/${buildingModelUid}/${tabName}/${buildingModel?.buildingModel.reportedCrp?.buildingModelRunUid}`
        );
    }, [buildingModel]);

    return (
        <div className="decarbonization-building-report">
            <div className="recommendations-report--title">
                <Header>Reach your goal</Header>
                <Header
                    size="x-small"
                    style={{ color: "var(--audette-gray-500)" }}
                >
                    {CURRENT_YEAR} - 2050
                </Header>
            </div>
            <AnnualEmissionSavingsByMeasures />
            <div className="recommendations-report--title">
                <Header>Financial impact</Header>
                <Header
                    size="x-small"
                    style={{ color: "var(--audette-gray-500)" }}
                >
                    {CURRENT_YEAR} - 2050
                </Header>
            </div>
            <div className="cards-and-chart-grid">
                <div className="cards">
                    <CumulativeNetPresentValueCard
                        query={useCumulativeNetPresentValueCard}
                    />
                    <CumulativeOperatingCostCard
                        query={useCumulativeOperatingCostCard}
                    />
                </div>
                <AnnualCashFlowChart query={useAnnualCashFlowChart} />
            </div>
            <div className="side-by-side-charts-grid">
                <AverageMarginalAbatementCostByMeasureCategoryChart
                    query={
                        useAverageMarginalAbatementCostByMeasureCategoryChart
                    }
                />
                <CumulativeInvestmentByDecarbonizationChart
                    query={useCumulativeInvestmentByDecarbonizationChart}
                />
            </div>
            <Header>Future projection</Header>
            <div className="cards-and-chart-grid">
                <FutureProjectionCards
                    cumulativeCarbonEmissionsCardQuery={
                        useCumulativeCarbonEmissionsCard
                    }
                    cumulativeEnergyConsumptionCardQuery={
                        useCumulativeEnergyConsumptionCard
                    }
                    annualCarbonEmissionsCardQuery={
                        useAnnualCarbonEmissionsCard
                    }
                    annualEnergyConsumptionCardQuery={
                        useAnnualEnergyConsumptionCard
                    }
                />
                <AnnualCarbonEmissionsChart
                    query={useAnnualCarbonEmissionsChart}
                />
            </div>
            <div className="side-by-side-charts-grid">
                <AnnualCarbonEmissionsNaturalGasChart
                    query={useAnnualCarbonEmissionsNaturalGasChart}
                />
                <AnnualCarbonEmissionsElectricityChart
                    query={useAnnualCarbonEmissionsElectricityChart}
                />
            </div>
        </div>
    );
}

function AnnualEmissionSavingsByMeasures() {
    const [showChart, setShowChart] = useState(true);
    const { isLoading: chartIsLoading, error: chartError } =
        useAnnualEmissionSavingsByMeasureChart();
    const { isLoading: tableIsLoading, error: tableError } =
        useAnnualEmissionSavingsByMeasureTable();

    if (chartError || tableError) return <ChartErrorState />;

    return (
        <ChartContainer
            title="Recommended decarbonization measure types"
            loading={chartIsLoading || tableIsLoading}
            onClickTable={() => setShowChart((show) => !show)}
        >
            {showChart ? (
                <>
                    <AnnualEmissionSavingsByMeasureChart
                        query={useAnnualEmissionSavingsByMeasureChart}
                    />
                    <CumulativeEmissionSavingsCard
                        query={useCumulativeEmissionSavingsCard}
                    />
                </>
            ) : (
                <AnnualEmissionSavingsByMeasureTable
                    query={useAnnualEmissionSavingsByMeasureTable}
                    drillDown={MeasureDrillDown.Type}
                />
            )}
        </ChartContainer>
    );
}

export default RecommendationsReport;
