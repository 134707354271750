// components
import { Header } from "components/atoms/Typography";
import CumulativeOperatingCostCard from "components/organisms/Charts/Recommendations/CumulativeOperatingCostCard";
import AnnualCashFlowChart from "components/organisms/Charts/Recommendations/AnnualCashFlowChart";
import AverageMarginalAbatementCostByMeasureCategoryChart from "components/organisms/Charts/AverageMarginalAbatementCostByMeasureCategoryChart";
import CumulativeInvestmentByDecarbonizationChart from "components/organisms/Charts/Recommendations/CumulativeInvestmentByDecarbonizationChart";
import FutureProjectionCards from "components/organisms/Charts/Recommendations/FutureProjectionCards";
import AnnualCarbonEmissionsChart from "components/organisms/Charts/Recommendations/AnnualCarbonEmissionsChart";
import AnnualCarbonEmissionsNaturalGasChart from "components/organisms/Charts/Recommendations/AnnualCarbonEmissionsNaturalGasChart";
import AnnualCarbonEmissionsElectricityChart from "components/organisms/Charts/Recommendations/AnnualCarbonEmissionsElectricityChart";
import DecarbonizationEfficiencyChart from "components/organisms/Charts/Recommendations/DecarbonizationEfficiencyChart";

// query hooks
import { useAnnualCashFlowChart } from "queries/Reports/Recommendations/useAnnualCashFlowChart";
import { useCumulativeOperatingCostCard } from "queries/Reports/Recommendations/useCumulativeOperatingCostCard";
import { useAnnualCarbonEmissionsChart } from "queries/Reports/Recommendations/useAnnualCarbonEmissionsChart";
import { useAnnualCarbonEmissionsElectricityChart } from "queries/Reports/Recommendations/useAnnualCarbonEmissionsElectricityChart";
import { useAnnualCarbonEmissionsNaturalGasChart } from "queries/Reports/Recommendations/useAnnualCarbonEmissionsNaturalGasChart";
import { useAnnualCarbonEmissionsCard } from "queries/Reports/Recommendations/useAnnualCarbonEmissionsCard";
import { useAnnualEnergyConsumptionCard } from "queries/Reports/Recommendations/useAnnualEnergyConsumptionCard";
import { useCumulativeInvestmentByDecarbonizationChart } from "queries/Reports/Recommendations/useCumulativeInvestmentByDecarbonizationChart";
import { useAverageMarginalAbatementCostByMeasureCategoryChart } from "queries/Reports/Recommendations/useAverageMarginalAbatementCostByMeasureCategoryChart";
import { useCumulativeNetPresentValueCard } from "queries/Reports/Recommendations/useCumulativeNetPresentValueCard";
import CumulativeNetPresentValueCard from "components/organisms/Charts/Recommendations/CumulativeNetPresentValueCard";
import AnnualEmissionSavingsByMeasures from "components/organisms/Charts/Recommendations/AnnualEmissionSavingsByMeasures";

// styling
import "./DecarbonizationReport.css";
import { useCumulativeCarbonEmissionsCard } from "queries/Reports/Recommendations/useCumulativeCarbonEmissionsCard";
import { useCumulativeEnergyConsumptionCard } from "queries/Reports/Recommendations/useCumulativeEnergyConsumptionCard";
import { CURRENT_YEAR } from "utils/constants";
import FiltersSidebar from "components/organisms/FiltersSidebar";
import { useState } from "react";

function DecarbonizationReport() {
    const [filterSidebarIsOpen, setFilterSidebarIsOpen] = useState(false);

    return (
        <div className="recommendations-report">
            <FiltersSidebar
                closeSidebar={() => setFilterSidebarIsOpen(false)}
                sidebarOpen={filterSidebarIsOpen}
                page="reports"
                currentActiveTab="decarbonization"
            />

            <Header>Reach your goals</Header>
            <AnnualEmissionSavingsByMeasures />
            <DecarbonizationEfficiencyChart />
            <Header>Financial impact ({CURRENT_YEAR} - 2050)</Header>
            <div className="cards-and-chart-grid">
                <div className="cards">
                    <CumulativeNetPresentValueCard
                        query={useCumulativeNetPresentValueCard}
                    />
                    <CumulativeOperatingCostCard
                        query={useCumulativeOperatingCostCard}
                    />
                </div>
                <AnnualCashFlowChart query={useAnnualCashFlowChart} />
            </div>
            <div className="side-by-side-charts-grid">
                <AverageMarginalAbatementCostByMeasureCategoryChart
                    query={
                        useAverageMarginalAbatementCostByMeasureCategoryChart
                    }
                />
                <CumulativeInvestmentByDecarbonizationChart
                    query={useCumulativeInvestmentByDecarbonizationChart}
                />
            </div>
            <Header>Future projection</Header>
            <div className="cards-and-chart-grid">
                <FutureProjectionCards
                    cumulativeCarbonEmissionsCardQuery={
                        useCumulativeCarbonEmissionsCard
                    }
                    cumulativeEnergyConsumptionCardQuery={
                        useCumulativeEnergyConsumptionCard
                    }
                    annualCarbonEmissionsCardQuery={
                        useAnnualCarbonEmissionsCard
                    }
                    annualEnergyConsumptionCardQuery={
                        useAnnualEnergyConsumptionCard
                    }
                />
                <AnnualCarbonEmissionsChart
                    query={useAnnualCarbonEmissionsChart}
                />
            </div>
            <div className="side-by-side-charts-grid">
                <AnnualCarbonEmissionsNaturalGasChart
                    query={useAnnualCarbonEmissionsNaturalGasChart}
                />
                <AnnualCarbonEmissionsElectricityChart
                    query={useAnnualCarbonEmissionsElectricityChart}
                />
            </div>
            {/* <AnnualEndUse2050Chart query={useReportAnnualEndUse2050Chart} /> */}
        </div>
    );
}

export default DecarbonizationReport;
