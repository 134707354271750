// store
import useReportsGroupByState from "recoilStore/useReportsGroupByState";

// components
import ChartContainer from "components/molecules/ChartContainer";
import { BarChart } from "components/molecules/Charts";
import { ChartErrorState } from "components/molecules/ErrorStates";

// css
import { ChartNoDataState } from "components/molecules/NoDataState";
import useBuildingBarChartAndMapByGroup from "../hooks/useBuildingBarChartAndMapByGroup";
import BarLayer from "./BarLayer";
import "../ByBuildingBarChart.css";
import { useData, useDisplayInfo, useDropdownItems } from "./ByBuildingBarChart.helpers";
import { TooltipDataContextProvider } from "./hooks/useTooltipDataContext";

function ByBuildingBarChartContextWrapper() {
    return (
        <TooltipDataContextProvider>
            <ByBuildingBarChart />
        </TooltipDataContextProvider>
    );
}

function ByBuildingBarChart() {
    const { items, onSelectItem, selectedItemId } = useDropdownItems();

    const { state: groupByMetric } = useReportsGroupByState();

    const {
        data: buildingModels,
        isLoading,
        error,
    } = useBuildingBarChartAndMapByGroup(groupByMetric);

    const data = useData(buildingModels, selectedItemId);
    const { unit, formatFunction } = useDisplayInfo(selectedItemId, data);

    if (error) return <ChartErrorState />;
    if (buildingModels?.length === 0) return <ChartNoDataState />;

    return (
        <ChartContainer
            items={items}
            onSelectItem={onSelectItem}
            loading={isLoading}
        >
            <BarChart
                data={data}
                xAxisInfo={{ hideLabels: true }}
                yAxisInfo={{
                    label: unit,
                    formatFunction: (v: string | number) =>
                        formatFunction(v, false),
                }}
                chartHeight={440}
                customLayers={[BarLayer]}
                margin={{
                    bottom: 6,
                    left: 75,
                    top: 10,
                    right: 50,
                }}
            />
        </ChartContainer>
    );
}

export default ByBuildingBarChartContextWrapper;
