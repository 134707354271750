import CsvDownloadButton from "react-json-to-csv";
import posthog from "posthog-js";
import { ExportIcon } from "../Icon";
import { Paragraph } from "../Typography";

interface MeasuresExportButtonProps {
    data: any[];
    currentActiveTab: "projects" | "measures";
}

function MeasuresExportButton({ data, currentActiveTab }: MeasuresExportButtonProps) {
    const posthogTracking = () => {
        posthog.capture('export_projects_table');
    }
    
    return (
        <div 
            onClick={posthogTracking}
            role="button"
            tabIndex={0}
            onKeyDown={(e) => { if (e.key === 'Enter') posthogTracking(); }}
        >
            <CsvDownloadButton
                data={data}
                filename={`${currentActiveTab}-data.csv`}
                delimiter=","
            >
                <div className="measures-page-flex-container">
                    <ExportIcon />
                    <Paragraph>Export</Paragraph>
                </div>
            </CsvDownloadButton>
        </div>
    );
}

export default MeasuresExportButton;