import { useQuery } from "react-query";
import { buildRequestHeaders, showMutationAlert } from "utils/helpers";
import { BACKEND_DOMAIN, QUERY_STALE_TIME } from "utils/constants";
import useAlert from "hooks/useAlert";
import { useAccessToken } from "hooks/useAccessToken";

export const buildingsQueryKey = ["buildings"];

const useQueryBuildings = (filteredId: string | null) => {
    const alert = useAlert();
    const accessToken = useAccessToken();
    const URL = `${BACKEND_DOMAIN}/buildings?filterset_uid=${filteredId}`

    return useQuery<BuildingsResponse>({
        queryKey: [...buildingsQueryKey, filteredId],   
        queryFn: async () => {
            const response = await fetch(URL, {
                method: "GET",
                headers: buildRequestHeaders(accessToken),
            });
            if (!response.ok) {
                throw new Error(`Error ${response.status}`);
            }
            return response.json();
        },
        onError: (error) => {
            showMutationAlert(error, alert);
        },
        staleTime: QUERY_STALE_TIME,
        enabled: !!filteredId
    });
};

export default useQueryBuildings;
