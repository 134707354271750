import { useMutation, useQueryClient } from "react-query";
import { useAccessToken } from "hooks/useAccessToken";
import { showMutationAlert } from "utils/helpers";
import useAlert from "hooks/useAlert";
import mutationFn from "../ApiLayer/Funds/setPropertyFund";

export const useSetPropertyFund = (
    propertyUids: string[],
    buildingModelUids: string[]
) => {
    const accessToken = useAccessToken();
    const queryClient = useQueryClient();
    const alert = useAlert();

    return useMutation({
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ["funds"],
            });

            buildingModelUids.forEach((buildingModelUid) => {
                queryClient.invalidateQueries({
                    queryKey: ["buildingModelHeader", buildingModelUid],
                });
            });

            queryClient.invalidateQueries({
                queryKey: ["buildingModelsTable"],
            });

            queryClient.invalidateQueries({
                queryKey: ["properties"],
            });

            queryClient.invalidateQueries({
                queryKey: ["funds"],
            });
        },
        mutationFn: async (propertyName: string | null) =>
            mutationFn(accessToken, propertyUids, propertyName),
        onError: (errors) => {
            showMutationAlert(errors, alert);
        }
    });
};
