import { useState } from "react";

import ViewTab from "components/atoms/ViewTab";
import FiltersSidebar from "components/organisms/FiltersSidebar";
import MeasuresExportButton from "components/atoms/MeasuresExportButton/MeasuresExportButton";
import MeasuresProjectsReport from "./MeasuresProjectsReport";

import "./MeasuresPage.scss";
import MeasuresMeasuresReport from "./MeasuresMeasuresReport";

function MeasuresPage() {
    const [currentActiveTab, setCurrentActiveTab] =
        useState<MeasuresTab>("projects");

    const [filterSidebarIsOpen, setFilterSidebarIsOpen] = useState(false);
    
    const [exportProjectsData, setExportProjectsData] = useState([]);
    const [exportMeasuresData, setExportMeasuresData] = useState([]);

    const exportData = {
        "projects": {
            data: exportProjectsData,
        },
        "measures": {
            data: exportMeasuresData,
        }
    };
    
    return (
        <div className="measures-page">
            <div className="measures-page--view-tabs">
                <div className="measures-page--view-tabs">
                    <ViewTab
                        id="measures-projects-tab"
                        name="Projects"
                        selected={currentActiveTab === "projects"}
                        onClick={() => setCurrentActiveTab("projects")}
                    />
                    <ViewTab
                        id="measures-measures-tab"
                        name="Measures"
                        selected={currentActiveTab === "measures"}
                        onClick={() => setCurrentActiveTab("measures")}
                    />
                </div>
                <div className="measures-page-filter-with-export">
                    <MeasuresExportButton 
                            data={exportData[currentActiveTab].data}
                            currentActiveTab={currentActiveTab}
                        />                
                </div>
            </div>
            <FiltersSidebar
                closeSidebar={() => setFilterSidebarIsOpen(false)}
                sidebarOpen={filterSidebarIsOpen}
                page="measures" 
            />

            {currentActiveTab === "projects" &&
                <MeasuresProjectsReport 
                    setExportData={setExportProjectsData}
                />
            }

            {currentActiveTab === "measures" &&
                <MeasuresMeasuresReport 
                setExportData={setExportMeasuresData}
                />
            }
        </div>
    );
}

export default MeasuresPage;
