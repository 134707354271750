import "./index.css";
import { CloseButton } from "components/atoms/Icon";
import { Paragraph } from "components/atoms/Typography";
import { DataCoverageIcon } from "components/molecules/DataCoverage/DataCoverage";
import { useCreateReportFilterset } from "mutations/Measures/useCreateReportFiltersetMutation";
import { uniqueEnergyTypes } from "utils/constants";
import { getDisplayInfo } from "utils/formatting";
import useFilters from "recoilStore/useFilters";
import posthog from "posthog-js";
import { filterConditionToDisplayValueMap } from "../FilterSidebar.constants";

interface FilterLabelCardsProps {
    page: Page;
}

export function FilterLabelCards({ page }: FilterLabelCardsProps) {
    const { filters, removeFilter, removeFilters, numberOfFilters } =
        useFilters(page);
    const { stringFilters, numericFilters, dateFilters, dataCoverageFilter } =
        filters;

        const isReportPage = page === "reports";

        const { mutate: mutateCreateReportFilterset } = useCreateReportFilterset();
        const removeFiltersHandler = async () => {
            await Promise.resolve(removeFilters()); 

            if (isReportPage) {
                mutateCreateReportFilterset()
            } 
        }

    const combinedFilters = [
        ...stringFilters,
        ...numericFilters,
        ...dateFilters,
    ];

    if (numberOfFilters === 0) return null;
    return (
        <div className="filter-name-cards">
            {dataCoverageFilter.length > 0 && (
                <DataCoverageLabelCard
                    filter={dataCoverageFilter}
                    onRemove={() => removeFilter("data_coverage")}
                />
            )}
            {combinedFilters.map((filter) => {
                const { field } = filter;
                
                const handleRemove = async () => {
                    posthog.capture("Filter Removed");
                    await Promise.resolve(removeFilter(field)); 

                    removeFilter(field);
                        if (isReportPage) {
                            mutateCreateReportFilterset()
                        } 
                        
                }

                return (
                    <FilterLabelCard
                        key={field}
                        filter={filter}
                        onRemove={handleRemove}
                    />
                );
            })}
            <button
                style={{
                    color: "var(--audette-gray-600)",
                    marginLeft: "auto",
                }}
                type="button"
                onClick={removeFiltersHandler}
            >
                <Paragraph>Clear filters</Paragraph>
            </button>
        </div>
    );
}

interface FilterLabelCardProps {
    filter: StringFilter | NumericFilter | DateFilter;
    onRemove: () => void;
}

function FilterLabelCard({ filter, onRemove }: FilterLabelCardProps) {
    const label = buildLabel(filter);

    return (
        <div className="filter-label-card">
            <Paragraph size="small">{label}</Paragraph>
            <CloseButton onClick={onRemove} />
        </div>
    );
}

const buildLabel = (filter: StringFilter | NumericFilter | DateFilter) => {
    const { key, value, condition } = filter;
    const { humanReadable, formatFunction } = getDisplayInfo(key);

    if (key === "tags") {
        // don't format tags
        return `${humanReadable}: ${value.join(", ")}`;
    }

    const formattedValue: (string | number)[] = [];
    if (key === "energyType") {
        // Do not include inferred energy type values
        value.forEach((item) => {
            if (uniqueEnergyTypes.includes(item.toString()))
                formattedValue.push(formatFunction(item));
        });
    } else {
        value.forEach((v) => formattedValue.push(formatFunction(v)));
    }

    if (condition === "between") {
        return `${humanReadable}: is between ${formattedValue[0]} and ${formattedValue[1]}`;
    }
    const formattedCondition =
        filterConditionToDisplayValueMap[condition].toLowerCase();

    if (typeof formattedValue[0] === "string")
        return `${humanReadable}: ${formattedCondition} ${formattedValue.join(
            ", "
        )}`;

    return (
        <div style={{ display: "flex", gap: "4px", flexWrap: "wrap" }}>
            {formattedValue.map((value) => value)}
        </div>
    );
};

interface DataCoverageLabelCardProps {
    filter: DataCoverageEnum[];
    onRemove: () => void;
}

function DataCoverageLabelCard({
    filter,
    onRemove,
}: DataCoverageLabelCardProps) {
    return (
        <div className="filter-label-card">
            {filter.map((identifier) => (
                <DataCoverageIcon key={identifier} identifier={identifier} />
            ))}
            <CloseButton onClick={onRemove} />
        </div>
    );
}
