import "./ChartHeader.scss";
import Dropdown, { Item } from "components/atoms/Dropdown";
import { Expand, Collapse, ChartIcon } from "components/atoms/Icon";
import Tooltip from "components/atoms/Tooltip";
import { Header } from "components/atoms/Typography";
import { useEffect } from "react";

interface ChartHeaderProps {
    items?: Item[];
    title?: string;
    onClickExpandCollapse: () => void;
    onSelectItem?: (id: string) => void;
    expand: boolean;
    onClickTable?: () => void;
    showTable: boolean;
    setShowTable: React.Dispatch<React.SetStateAction<boolean>>;
    defaultDropdownItem?: Item;
    tooltip?: string;
}

function ChartHeader({
    items,
    onClickExpandCollapse,
    onSelectItem,
    expand,
    title,
    onClickTable,
    showTable,
    setShowTable,
    defaultDropdownItem,
    tooltip,
}: ChartHeaderProps) {
    if (!title && !items)
        throw new Error("Please define either a title or dropdown items");

    if (items && !onSelectItem)
        throw new Error("Please define onSelectItem when adding prop items");
    
    useEffect(() => {
        if (defaultDropdownItem && onSelectItem) {
            onSelectItem(defaultDropdownItem.id);
        }
    }, []);

    return (
        <div className="chart-header">
            {title && <Header size="small">{title}</Header>}
            {items && onSelectItem && (
                <div 
                className={`dropdown-with-items ${(title === "Energy and emission intensity by " || title === "Decarbonization efficiency by ") ? "energy-emission" : ""}`}
                >
                    <Dropdown
                        items={items}
                        onSelectItem={({ id }) => onSelectItem(id)}
                        defaultItem={defaultDropdownItem || items[0]}
                        />
                </div>
            )}
            <div className="margin-right-auto">
                {tooltip && <Tooltip tooltipBody={tooltip} />}
            </div>
            <div className="chart-header--icons">
                {onClickTable && (
                    <button
                        type="button"
                        onClick={() => {
                            setShowTable((prev) => !prev);
                            onClickTable();
                        }}
                    >
                        {showTable ? <ChartIcon /> : <ChartIcon />}
                    </button>
                )}
                <button type="button" onClick={onClickExpandCollapse}>
                    {expand ? <Expand /> : <Collapse />}
                </button>
            </div>
        </div>
    );
}

export default ChartHeader;
