import { request } from "graphql-request";
import { graphql } from "gql/gql";

import { BACKEND_DOMAIN } from "utils/constants";
import { buildRequestHeaders } from "utils/helpers";

const queryFn = (
    accessToken: string,
    data: {
        reportFiltersetUid: string | null;
    }
) =>
    request({
        url: `${BACKEND_DOMAIN}/graphql`,
        document: query,
        variables: {
            reportFiltersetUid: data.reportFiltersetUid,
        },
        requestHeaders: buildRequestHeaders(accessToken),
    }).then((data) => {
        if (
            data.prefilteredReports?.prefilteredBaselineReport
                ?.cumulativeEnergyConsumptionCard.error
        )
            throw new Error(
                // eslint-disable-next-line max-len
                data.prefilteredReports.prefilteredBaselineReport.cumulativeEnergyConsumptionCard.error
            );
        return (
            (data.prefilteredReports?.prefilteredBaselineReport
                ?.cumulativeEnergyConsumptionCard as CumulativeEnergyConsumptionCardData) ||
            null
        );
    });

const query = graphql(`
    query cumulativeEnergyConsumptionCard($reportFiltersetUid: Uuid) {
        prefilteredReports(reportFiltersetUid: $reportFiltersetUid) {
            prefilteredBaselineReport {
                cumulativeEnergyConsumptionCard {
                    error
                    totalEnergyConsumption
                    totalEnergyConsumptionElectricity
                    totalEnergyConsumptionNaturalGas
                }
            }
        }
    }
`);

export default queryFn;
