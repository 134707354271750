import { ReactNode, useState } from "react";
import { Item } from "components/atoms/Dropdown";
import ChartContainer from "components/molecules/ChartContainer";
import { ScatterPlot } from "components/molecules/Charts";
import useQueryBuildings from "ApiLayer/Building/queryBuildings";
import { ChartNoDataState } from "components/molecules/NoDataState";
import LoadingSpinner from "components/atoms/LoadingSpinner/LoadingSpinner";
import { useFeatureFlagEnabled } from "posthog-js/react";
import { scatterPlotGroupOptionsAssets, ScatterPlotAssetGroup, ScatterPlotGroup, energyIntensityChartTooltip } from "utils/constants";
import useFiltersetState from "recoilStore/Filters/useFilterset";
import { UserPermissions } from "utils/enums";
import { usePermissions } from "hooks/usePermissions";
import { ScatterPlotDatum } from "components/molecules/Charts/ScatterPlot";
import { getBuildingAggregator } from "./EnergyAndEmissionIntensityAggregator";
import { refineBuildingData } from "./EnergyAndEmissionIntensityChart.helpers";

function EnergyAndEmissionIntensityChart() {
    const featureEnabled = useFeatureFlagEnabled("prioritization-chart");
    const [scatterPlotGroup, setScatterPlotGroup] = useState<ScatterPlotGroup>("building");
    const { state: filtersetState } = useFiltersetState();
    const filtersetId = filtersetState.filterset;
    const { data: buildingsResponse, isLoading: buildingsIsLoading } = (
        useQueryBuildings(filtersetId)
    );
    const { hasPermission, isLoading: permissionIsLoading } = usePermissions();

    const canUseInternalTools = hasPermission(UserPermissions.UseInternalTools);
    if (!featureEnabled || !canUseInternalTools) {
        return <div style={{ display: "none" }} />;
    }

    let groupedData: ScatterPlotDatum[] = [];
    if (buildingsResponse) {
        const { buildings } = buildingsResponse;

        if (buildings && buildings.length > 0) {
            const buildingUngroupedData = refineBuildingData(buildings);
            const buildingAggregator = getBuildingAggregator(
                scatterPlotGroup as ScatterPlotAssetGroup
            );
            groupedData = buildingAggregator(buildingUngroupedData);
        }
    }
    const isLoading = buildingsIsLoading || permissionIsLoading;
    if (isLoading) return (
        <EnergyAndEmissionIntensityChartContainer
            setScatterPlotGroup={setScatterPlotGroup}
            isLoading={isLoading}
        >
            <LoadingSpinner />
        </EnergyAndEmissionIntensityChartContainer>
    )

    return (
        groupedData.length > 0 ? (
            <EnergyAndEmissionIntensityChartContainer
                setScatterPlotGroup={setScatterPlotGroup}
                isLoading={isLoading}
            >
                <ScatterPlot
                    data={groupedData}
                    xLabel="Energy intensity (kWh/ft²/yr)"
                    yLabel="Emission intensity (kg CO₂e/ft²/yr)"
                />
            </EnergyAndEmissionIntensityChartContainer>
        ) :
            <ChartNoDataState />
    )
}

interface EnergyAndEmissionIntensityChartContainerProps {
    children: ReactNode;
    setScatterPlotGroup: Function;
    isLoading: boolean;
}

function EnergyAndEmissionIntensityChartContainer({
    children,
    setScatterPlotGroup,
    isLoading,
}: EnergyAndEmissionIntensityChartContainerProps) {
    const items: Item[] = scatterPlotGroupOptionsAssets.map(
        ({ key, label }: ScatterPlotGroupOption) => ({ id: key, displayValue: label })
    );

    const defaultItem = items.find(item => item.id === 'property');

    return (
        <ChartContainer
            title="Energy and emission intensity by "
            onSelectItem={(x) => setScatterPlotGroup(x)}
            items={items}
            loading={isLoading}
            defaultDropdownItem={defaultItem}
            tooltip={energyIntensityChartTooltip}
        >
            {children}
        </ChartContainer>
    )

}

export default EnergyAndEmissionIntensityChart;
