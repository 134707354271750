import InteractiveTooltipWrapper from "./InteractiveTooltipWrapper";

function Bar({ width, height, index }: any) {
    return (
        <InteractiveTooltipWrapper index={index}>
            <rect
                width={width}
                height={height}
                rx={0}
                ry={0}
                fill="transparent"
                strokeWidth={0}
                stroke="transparent"
            />
        </InteractiveTooltipWrapper>
    );
}
export default Bar;