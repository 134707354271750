import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { Button } from "components/atoms/Button";
import LoadingSpinner from "components/atoms/LoadingSpinner/LoadingSpinner";
import { Header } from "components/atoms/Typography";
import Sidebar from "components/molecules/Sidebar";
import MUITheme from "components/MUITheme";
import { useCustomPlanUpdate } from "hooks/CustomPlan/useCustomPlanUpdate";
import { useState } from "react";
import { projectsCustomPlan } from "utils/constants";

interface NewProjectSidebarProps {
    closeSidebar: () => void;
    sidebarOpen: boolean;
    buildingModelUid: string;
    currentCarbonReductionPlanUid: string;
}

function NewProjectSidebar({
    closeSidebar,
    sidebarOpen,
    buildingModelUid,
    currentCarbonReductionPlanUid,
}: NewProjectSidebarProps) {
    const currentYear = new Date().getFullYear();

    const [newProject, setNewProject] = useState<NewProject>({
        selectedProject: "",
        measureYear: currentYear
    });

    const [isApplyDisabled, setIsApplyDisabled] = useState(true);

    const handleProjectChange = (e: SelectChangeEvent<string>) => {
        const projectValue = e.target.value;

        if (projectValue) {
            setIsApplyDisabled(false);
        } else {
            setIsApplyDisabled(true);
        }
        
        setNewProject(prev => ({ ...prev, selectedProject: projectValue }));
    };

    const onClickCancel = () => {
        closeSidebar();
    };

    const { updateCustomPlanMeasures, isLoading } = useCustomPlanUpdate({
        buildingModelUid,
        currentCarbonReductionPlanUid,
    });

    const onClickApply = () => {
        
        updateCustomPlanMeasures("create", {
            onSuccess: closeSidebar,
            newProject
        });
    };

    if (isLoading) return (
        <Sidebar
            onCancel={closeSidebar}
            sidebarOpen={sidebarOpen}
            title="Create Project"
        >
            <LoadingSpinner />
        </Sidebar>
    )

    return (
        <Sidebar
            onCancel={closeSidebar}
            sidebarOpen={sidebarOpen}
            title="Create Project"
        >
            <div className="filter-container">
                <div className="filter-container">
                    <Header size="small">Select Project</Header>
                    <MUITheme primaryColor="#EB03AD">
                        <Select
                            value={newProject.selectedProject || ""}
                            onChange={handleProjectChange}
                            style={{
                                width: "100%",
                                height: "35px",
                                minWidth: 0,
                            }}
                            required
                        >
                            {projectsCustomPlan.map((project: ProjectCustomPlan) => (
                                <MenuItem key={project.value} value={project.value}>
                                    {project.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </MUITheme>
                    
                </div>
            </div>
            <div className="filter-container">
                <div className="filter-container">
                    <Header size="small">Implementation Year</Header>
                    <input
                        key="yearBuiltOriginal"
                        required={true}
                        type="number"
                        value={newProject.measureYear}
                        className="filter-input"
                        onChange={(e) => {
                            const projectValue = Number(e.target.value);
                            setNewProject(prev => ({ ...prev, measureYear: projectValue }));
                          }}
                        placeholder="Enter project year"
                        maxLength={50}
                    />
                </div>
            </div>

            {/* Footer */}
            <div className="filters-sidebar__footer">
                <div className="filters-sidebar__footer__buttons">
                    <Button
                        type="primary"
                        onClick={onClickApply}
                        disabled={isLoading || isApplyDisabled}
                    >
                        {isLoading ? "Loading" : "Apply"}
                    </Button>

                    {!isLoading && (
                        <Button
                            onClick={onClickCancel}
                            type="link"
                            disabled={isLoading}
                        >
                            Cancel
                        </Button>
                    )}
                </div>
            </div>
        </Sidebar>
    );
}

export default NewProjectSidebar;
