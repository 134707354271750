import { request } from "graphql-request";
import { graphql } from "gql/gql";

import { BACKEND_DOMAIN } from "utils/constants";
import {
    buildRequestHeaders,
} from "utils/helpers";

const queryFn = (
    accessToken: string, 
    data: {
        reportFiltersetUid: string | null;
    }
) =>    request({
        url: `${BACKEND_DOMAIN}/graphql`,
        document: query,
        variables: {
            reportFiltersetUid: data.reportFiltersetUid,
        },         requestHeaders: buildRequestHeaders(accessToken),
    }).then((data) => {
        if (data.prefilteredReports?.prefilteredBaselineReport?.annualOperatingCostCard.error)
            throw new Error(
                data.prefilteredReports.prefilteredBaselineReport.annualOperatingCostCard.error
            );
        return (
            data.prefilteredReports?.prefilteredBaselineReport?.annualOperatingCostCard.data || null
        );
    });

const query = graphql(`
    query annualOperatingCostCard($reportFiltersetUid: Uuid) {
        prefilteredReports(reportFiltersetUid: $reportFiltersetUid) {
            prefilteredBaselineReport {
                annualOperatingCostCard {
                    error
                    data {
                        averageCarbonTaxCostIntensity
                        averageUtilityCostIntensity
                        totalCarbonTax
                        totalOperatingCost
                        totalUtilityCost
                        year
                    }
                }
            }
        }
    }
`);

export default queryFn;
