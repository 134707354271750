import { request } from "graphql-request";
import { graphql } from "gql/gql";

import { BACKEND_DOMAIN } from "utils/constants";
import {
    buildRequestHeaders,
} from "utils/helpers";

const queryFn = (accessToken: string, buildingModelRunUid: string) =>
    request({
        url: `${BACKEND_DOMAIN}/graphql`,
        document: query,
        variables: { buildingModelRunUid },
        requestHeaders: buildRequestHeaders(accessToken),
    }).then((data) => {
        if (data.carbonReductionPlanReports.error)
            throw new Error(data.carbonReductionPlanReports.error);
        return data.carbonReductionPlanReports
            ?.cumulativeOperatingCostCard as CumulativeOperatingCostCardData;
    });

const query = graphql(`
    query buildingModelRecommendationsCumulativeOperatingCostCard(
        $buildingModelRunUid: Uuid!
    ) {
        carbonReductionPlanReports(buildingModelRunUid: $buildingModelRunUid) {
            error
            cumulativeOperatingCostCard {
                totalCarbonTax
                totalCarbonTaxIntensity
                totalCarbonTaxPercentChange
                totalEnergyCost
                totalEnergyCostPercentChange
                totalUtilityCost
                totalUtilityCostIntensity
                totalUtilityCostPercentChange
            }
        }
    }
`);

export default queryFn;
