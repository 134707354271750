import { graphql } from "gql";
import request from "graphql-request";
import { BACKEND_DOMAIN } from "utils/constants";
import { buildRequestHeaders } from "utils/helpers";

const mutationDeleteCustomCarbonReductionPlan = (
    accessToken: string,
    data: {
        carbonReductionPlanUid: string,
    }
) => 
    request({
        url: `${BACKEND_DOMAIN}/graphql`,
        document: mutationQuery,
        variables: {
            carbonReductionPlanUid: data.carbonReductionPlanUid,
        },
        requestHeaders: buildRequestHeaders(accessToken),   
    }).then((data) => {
        if (data) {
            return data;
        }
        throw new Error("Error deleting custom plan");
    })
    .catch((error) => {
        console.error("GraphQL Error:", error);
        throw error; 
    });

const mutationQuery = graphql(`
    mutation deleteCustomCarbonReductionPlan ($carbonReductionPlanUid: Uuid!) {
        deleteCustomCarbonReductionPlan (carbonReductionPlanUid: $carbonReductionPlanUid)
    }
`);        

export default mutationDeleteCustomCarbonReductionPlan;