import { InlineBoldParagraph } from "components/atoms/Typography";

export const buildDeleteProjectModalOptions = (
    numberOfSelectedIds: number,
    loading: boolean
) => ({
    title: "Remove Project from Plan",
    body: (
        <>
            This will{" "}
            <InlineBoldParagraph>
                remove{" "}
                {numberOfSelectedIds === 1 ? "this project" : "these projects"}{" "}
                ({numberOfSelectedIds}) and all associated data
            </InlineBoldParagraph>
            . Do you wish to proceed?
        </>
    ),
    deleteButtonText: loading
        ? "Loading..."
        : `Remove project${numberOfSelectedIds > 1 ? "s" : ""}`,
});

export const buildDeleteCustomPlanModalOptions = (
    loading: boolean
) => ({
    title: "Delete Custom Plan",
    body: (
        <>
            This will{" "}
            <InlineBoldParagraph>
                remove this Custom Plan and all associated data
            </InlineBoldParagraph>
            . Do you wish to proceed?
        </>
    ),
    deleteButtonText: loading
        ? "Loading..."
        : `Delete Custom Plan`,
});