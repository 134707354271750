import { HandHoldingMoneyIcon, ImplementationIcon, LeafIcon, MeasureTypeIcon, SizingIcon, UnitCostIcon } from "components/atoms/Icon";
import { Header, Paragraph } from "components/atoms/Typography";
import { ReactNode } from "react";
import { displayValueWithUnit, getDisplayInfo } from "utils/formatting";

export const buildHeaderTitleAndUnitsStruct = (data: MeasureReportHeader) => {
    const { formatFunction: formatSize } = getDisplayInfo(
        "carbonReductionMeasureSize"
    );

    const { formatFunction: formatUnitCost, unit: costUnit } = getDisplayInfo(
        "carbonReductionMeasureUnitCost"
    );

    const options: ProjectReportHeaderAttributeProps[] = [
        {
            title: "Type",
            titleValue: data.carbonReductionMeasureCategory,
            icon: <MeasureTypeIcon />,
        },
        {
            title: "Implementation",
            titleValue: `${data.yearApplied} (${data.carbonReductionMeasureLife} yrs)`,
            icon: <ImplementationIcon />,
        },
    ];

    if (
        data.carbonReductionMeasureSize !== undefined &&
        data.carbonReductionMeasureSizeUnit
    )
        options.push({
            title: "Sizing",
            titleValue: formatSize(data.carbonReductionMeasureSize),
            icon: <SizingIcon />,
            unit: ` ${getCarbonReductionMeasureSizeUnitHumanReadableString(
                data.carbonReductionMeasureSizeUnit,
                data.carbonReductionMeasureSize > 1 ? "plural" : "singular"
            )}`,
        });

    if (
        data.carbonReductionMeasureUnitCost !== undefined &&
        data.carbonReductionMeasureSizeUnit
    )
        options.push({
            title: "Unit cost",
            titleValue: displayValueWithUnit(
                data.carbonReductionMeasureUnitCost,
                costUnit,
                (v) => formatUnitCost(v)
            ),
            icon: <UnitCostIcon />,
            unit: `/${getCarbonReductionMeasureSizeUnitHumanReadableString(
                data.carbonReductionMeasureSizeUnit,
                "singular"
            )}`,
        });

    return options;
};

const getCarbonReductionMeasureSizeUnitHumanReadableString = (
    unit: string,
    singularOrPlural: "singular" | "plural"
) => {
    const humanReadable = measureSizeUnitToHumanReadableSingular[unit];

    if (singularOrPlural === "singular") return humanReadable || unit;
    return measureSizeUnitSingularPluralMap[unit] || humanReadable || unit;
};



interface ProjectReportHeaderAttributeProps {
    title: string;
    titleValue: string;
    icon: ReactNode;
    unit?: string;
}

export function ProjectReportHeaderAttribute({
    title,
    titleValue,
    icon,
    unit,
}: ProjectReportHeaderAttributeProps) {
    return (
        <div className="project-report-header__measure_attribute">
            {icon}
            <Header size="small">{title}:</Header>
            <Paragraph size="regular">{`${titleValue}${unit || ""}`}</Paragraph>
        </div>
    );
}

export function ImpactCardLoading() {
    return (
        <div className="small-cards">
            {Array.from({ length: 5 }).map((_, i) => (
                <div
                    className="card small--card"
                    data-testid="card-loading"
                    // eslint-disable-next-line react/no-array-index-key
                    key={i}
                >
                    <div
                        className="loading"
                        style={{
                            height: "12px",
                            width: "30%",
                            borderRadius: "12px",
                        }}
                    />
                    <div
                        className="loading"
                        style={{
                            height: "12px",
                            width: "80%",
                            borderRadius: "12px",
                        }}
                    />
                </div>
            ))}
        </div>
    );
}

export function LargeCardLoading() {
    const widths = [86, 150, 50, 172, 50, 150, 50, 150, 50];

    return (
        <div
            className="large-card large-card--loading"
            data-testid="card-loading"
        >
            {widths.map((width, index) => (
                <div
                    className="large-card--loading__grid-item"
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${width}$${index}`}
                >
                    <div
                        className="loading"
                        style={{
                            height: "12px",
                            width: `${width}px`,
                            borderRadius: "12px",
                        }}
                    />
                </div>
            ))}
        </div>
    );
}

export const buildTotalCarbonEmissionSavingsCardStruct = (
    carbonEmissionsData: MeasureReportTotalCarbonEmissionsCard,
    energyData: MeasureReportTotalEnergyConsumptionCard
): CardSection<string>[] => [
    {
        title: {
            key: "totalEnergyConsumptionSavings",
            icon: <LeafIcon />,
            value: energyData.totalEnergyConsumptionSavings,
        },
        body: [
            {
                key: "totalEnergyConsumptionSavingsElectricity",
                value: energyData.totalEnergyConsumptionSavingsElectricity,
            },
            {
                key: "totalEnergyConsumptionSavingsNaturalGas",
                value: energyData.totalEnergyConsumptionSavingsNaturalGas,
            },
        ],
    },
    {
        title: {
            key: "totalCarbonEmissionSavings",
            icon: <LeafIcon />,
            value: carbonEmissionsData.totalCarbonEmissionSavings,
        },
        body: [
            {
                key: "totalCarbonEmissionSavingsElectricity",
                value: carbonEmissionsData.totalCarbonEmissionSavingsElectricity,
            },
            {
                key: "totalCarbonEmissionSavingsNaturalGas",
                value: carbonEmissionsData.totalCarbonEmissionSavingsNaturalGas,
            },
        ],
    },
];

export interface CardSection<T extends keyof any> {
    title: {
        key: T;
        icon: React.ReactNode;
        value: number;
    };
    body: {
        key: T;
        value: number;
        percentage?: number;
    }[];
}

export const buildTotalCostAndSavingsCardDataStruct = (
    data:
        | ProjectReportTotalCostAndSavingsCardDatum
        | ProjectReportTotalCostAndSavingsCardTotal
): CardSection<string>[] => {
    const struct: CardSection<string>[] = [
        {
            title: {
                key: "totalEnergyCostSavings",
                icon: <LeafIcon />,
                value: data.totalEnergyCostSavings,
            },
            body: [
                {
                    key: "totalUtilityCostSavingsElectricity",
                    value: data.totalUtilityCostSavingsElectricity,
                },
                {
                    key: "totalUtilityCostSavingsNaturalGas",
                    value: data.totalUtilityCostSavingsNaturalGas,
                },
                {
                    key: "totalCarbonTaxSavings",
                    value: data.totalCarbonTaxSavings,
                },
            ],
        },
        {
            title: {
                key: "totalMeasureCost",
                icon: <HandHoldingMoneyIcon />,
                value: data.totalMeasureCost,
            },
            body: [
                {
                    key: "incrementalCost",
                    value: data.incrementalCost,
                },
            ],
        },
    ];

    if (data.likeForLikeCost !== undefined) {
        struct[1].body.unshift({
            key: "likeForLikeCost",
            value: data.likeForLikeCost,
        });
    }
    return struct;
};

export const buildCashFlowTableColumnKeys = (selected: "total" | "incremental") => {
    const keys = [
        "calendarYear",
        "likeForLikeCost",
        "incrementalCost",
        "utilityCostSavingsNaturalGas",
        "utilityCostSavingsElectricity",
        "carbonTaxSavings",
        "cashFlowAnnual",
    ];
    if (selected === "incremental") {
        keys.splice(1, 1);
    }
    return keys;
};

export const buildCashFlowChartKeys = (selected: "total" | "incremental") => {
    const keys = [
        "likeForLikeCost",
        "incrementalCost",
        "utilityCostSavingsNaturalGas",
        "utilityCostSavingsElectricity",
        "carbonTaxSavings",
    ];
    if (selected === "incremental") {
        keys.splice(0, 1);
    }
    return keys;
};

export const measureSizeUnitToHumanReadableSingular: {
    [unit: string]: string;
} = {
    cfm_of_ahu_supply_air_rate: "cfm",
    heating_load_cop: "ton",
    kw: "kW",
    kwh: "kWh",
    m2: "m²",
    m2_of_gfa: "m² of GFA",
    m2_of_roof_area: "m² of roof",
    m2_of_window_area: "m² of window",
    seal: "seal",
    ton: "ton",
};

export const measureSizeUnitSingularPluralMap: {
    [unit: string]: string;
} = {
    dryer: "dryers",
    elevator: "elevators",
    escalator: "escalators",
    seal: "seals",
    suite: "suites",
    ton: "tons",
    zone: "zones",
    heating_load_cop: "tons",
};

export const measureNameToDescriptionMap: { [type: string]: string } = {
    "Increase Roof Insulation to R50":
        "Upgrade existing roof insulation to R50 during the next replacement at the end of its useful life.",
    "Increase Roof Insulation to R60":
        "Upgrade existing roof insulation to R60 during the next replacement at the end of its useful life.",
    "Install Advanced Glazing (~R6)":
        "Replace existing glazing with high performance glazing (R6/U-0.167) and climate-appropriate solar heat gain at the end of its useful life.",
    "Install Advanced Glazing (~R8)":
        "Replace existing glazing with high-performance glazing (R8/U-0.125) and climate-appropriate solar heat gain at the end of its useful life.",
    "Install Pressure-Applied Aerosolized Sealant":
        "Improve building energy efficiency by implementing a phased application of pressure-applied atomized sealant to reduce air infiltration during periods of low occupancy, such as weekends, vacancies, or tenant turnover.",
    "Install Air-Source Heat Pump Sized to Replace the Chiller and Supplement/Replace Boiler":
        "Upgrade your building's HVAC system with an Air Source Heat Pump (ASHP), offering efficient heating and cooling while replacing the chiller and potentially supplementing or replacing the boiler. With a calculated ASHP load ratio of 1.0 and a seasonal heating coefficient of performance (COP) of 3.72, this measure ensures energy-efficient operation across various climate zones.",
    "Replace existing dryer(s) with dryer air-source heat pump(s)":
        "Replace existing clothes dryer(s) with heat pump clothes dryer(s).",
    "Replace Existing Boiler with a Condensing Boiler":
        "Replace the existing standard efficiency boiler(s) with modulating condensing boiler(s) at the end of their useful life.",
    "Install DCV Controls":
        "Install Demand-Controlled Ventilation (DCV) controls in all applicable zones while maintaining adequate ventilation rates (assumes 25% ventilation rate reduction for 0.65 of the gross building area).",
    "Install Domestic Hot Water Ambient Air-Source Heat Pump":
        "Replace existing domestic hot water heater(s) with an ambient air heat pump water heater(s).",
    "Install a Micro Combined Heat and Power System for Domestic Hot Water":
        "Enhance your building's energy efficiency and reduce carbon emissions by installing a micro combined heat and power (CHP) system designed to replace the domestic hot water (DHW) load.",
    "Install Drain Water Heat Recovery (Gravity Film Exchanger)":
        "Implement drain water heat recovery (DWHR) systems, specifically gravity film exchanger plumbing stacks, to recover heat from wastewater and reduce energy consumption.",
    "Install VFDs on Escalator Drives":
        "Install regenerative drives on passenger escalator(s).",
    "Install Furnace (Ducted) Air-Source Heat Pump(s) [Aux. Elec. Resistance]":
        "Upgrade your HVAC system with a Furnace (Ducted) Air-Source Heat Pump (ASHP) to reduce carbon emissions. Sized to handle the full cooling load and up to 37.5% of the heating load variability, this measure ensures efficient operation across seasons.",
    "Install Furnace (Ducted) Air-Source Heat Pump(s) [Aux. Gas Burner]":
        "Upgrade your HVAC system with a Furnace (Ducted) Air-Source Heat Pump (ASHP), incorporating an auxiliary gas burner for efficient heating and cooling. Sized at the full cooling load plus or minus 37.5% of the heating load, the ASHP ensures optimal performance in various climate conditions.",
    "Install Ground-Source Heat Pump Size to Replace the Chiller and Supplement/Replace Boiler":
        "Upgrade your outdated gas furnace with a high-efficiency model boasting a COP of 0.96, delivering optimal heating performance while minimizing energy consumption.",
    "Include Energy Recovery During AHU(s) Replacement":
        "Include energy recovery thermal wheel in AHU(s) at replacement.",
    "Install Heat Recovery Water-Source Heat Pump":
        "Upgrade your heating system with a Heat Recovery Water-Source Heat Pump to efficiently utilize wasted heat, reduce energy costs, and lower carbon emissions.",
    "Install Heat-Only Air-Source Heat Pump":
        "This measure involves installing a heat-only air-source heat pump (ASHP) to meet the building's heating needs down to -10°C, with a backup boiler to cover heating requirements below that temperature.",
    "Install Packaged Terminal Heat Pump(s) [Aux. Electric Resistance]":
        "Replace existing Packaged Terminal Air Conditioner(s) [PTAC(s)] with Packaged Terminal Heat Pump(s) [with Aux. Electric Resistance] sized to the cooling load",
    "Replace Existing Gas Furnace with a High-Efficiency Gas Furnace":
        "Upgrade your outdated gas furnace with a high-efficiency model boasting a COP of 0.96, delivering optimal heating performance while minimizing energy consumption.",
    "Replace Existing Steam Heat Exchanger with a High-Efficiency Heat Exchanger":
        "Enhance your building's efficiency by replacing your current steam heat exchanger with a high-efficiency model, reducing energy consumption and carbon emissions.",
    "Install LED Lighting":
        "Replace existing lighting with LED lighting throughout of the property ensuring adequate lighting levels are maintained.",
    "Replace Chiller with Oil-Free, Magnetic-Bearing Compressor Chiller":
        "Replace the existing chiller(s) with magnetic bearing, oil-free compressor chiller(s) at the end of their useful life.",
    "Install Regen Drives on Passenger Elevator(s)":
        "Install regenerative drives on passenger elevator(s).",
    "Install a No-Export Rooftop PV System":
        "Install a no-export, ballasted rooftop PV system with a shallow tilt, sized to the smaller of available roof space and summer mid-day electric demand average.",
    "Install Hybrid RTU Air-Source Heat Pump(s) [Aux. Elec. Resistance]":
        "Replace existing RTU(s) with hybrid, inverter-compressor air-source heat pump RTU(s) sized up to 1.25 of the cooling load with a electric resistance backup.",
    "Install Hybrid RTU Air-Source Heat Pump(s) [Aux. Gas Burner]":
        "Replace existing RTU(s) with hybrid, inverter-compressor air-source heat pump RTU(s) sized up to 1.25 of the cooling load with a gas backup.",
    "Install Run-Around Heat Recovery Loop on Ventilation System":
        "Integrate run-around heat recovery loops into the ventilation system to recover heat from the exhaust air and preheat the incoming fresh air.",
    "Install Split (Ductless) Air-Source Heat Pump(s) to Supplement Existing Baseboard Heating":
        "Install split (ductless) air-source heat pump(s) to supplement existing baseboard heating.",
    "Install Heat Recovery Ventilator(s)":
        "Implement heat recovery ventilators (HRVs) to enhance indoor air quality and energy efficiency by exchanging heat between outgoing and incoming air streams.",
    "Replace Existing Low-Voltage Dry Transformers with Ultra-Low Loss Transformers":
        "Replace existing low-voltage dry transformers with ultra-low loss transformers.",
    "Install Window Air-Source Heat Pump Sized to Supplement the Primary Heating System":
        "Upgrade your home's cooling system with a window air-source heat pump, providing efficient cooling and supplemental heating for improved comfort and energy savings.",
    "Install Low-E Window Film with Climate Appropriate SHGC":
        "Improve building energy efficiency by installing low-emissivity (low-e) window film tailored to the climate, effectively reducing solar heat gain while maintaining natural light levels, on existing glazing surfaces.",
    "Reduce Air Infiltration with Traditional Weatherization":
        "Enhance building energy efficiency by implementing traditional weatherization techniques, such as caulking, taping, and weatherstripping, to reduce air infiltration.",
};


