export const buildingsFilterKeyToInfoMap: {
    [key: string]: {
        type: "multi-select" | "text" | "numeric" | "date";
        field:
            | NumericFilterField
            | StringFilterField
            | "data_coverage"
            | "created_at";
    };
} = {
    createdAt: {
        type: "date",
        field: "created_at",
    },
    tags: {
        type: "multi-select",
        field: "tag",
    },
    buildingName: {
        type: "text",
        field: "building_name",
    },
    propertyName: {
        type: "text",
        field: "property_name",
    },
    fundName: {
        type: "text",
        field: "fund_name",
    },
    dataCoverage: {
        type: "multi-select",
        field: "data_coverage",
    },
    streetAddress: {
        type: "text",
        field: "street_address",
    },
    city: {
        type: "text",
        field: "city",
    },
    stateProvince: {
        type: "text",
        field: "state_province",
    },
    country: {
        type: "text",
        field: "country",
    },
    buildingArchetype: {
        type: "multi-select",
        field: "building_archetype",
    },
    energyType: {
        type: "multi-select",
        field: "energy_type",
    },
    yearBuiltOriginal: {
        type: "numeric",
        field: "year_built_original",
    },
    grossFloorArea: {
        type: "numeric",
        field: "gross_floor_area",
    },
    annualCarbonEmissionsCurrent: {
        type: "numeric",
        field: "annual_carbon_emissions",
    },
    annualCarbonEmissionIntensityCurrent: {
        type: "numeric",
        field: "annual_carbon_emission_intensity",
    },
    annualEnergyConsumptionCurrent: {
        type: "numeric",
        field: "annual_energy_consumption",
    },
};

export const reportProjectsFilterKeyToInfoMap: {
    [key: string]: {
        type: "multi-select" | "text" | "numeric" | "date";
        field:
            | NumericFilterField
            | StringFilterField
            | "data_coverage"
            | "created_at";
    };
} = {
    createdAt: {
        type: "date",
        field: "created_at",
    },
    tags: {
        type: "text",
        field: "tag",
    },
    buildingName: {
        type: "text",
        field: "building_name",
    },
    propertyName: {
        type: "text",
        field: "property_name",
    },
    fundName: {
        type: "text",
        field: "fund_name",
    },
    dataCoverage: {
        type: "multi-select",
        field: "data_coverage",
    },
    streetAddress: {
        type: "text",
        field: "street_address",
    },
    city: {
        type: "text",
        field: "city",
    },
    stateProvince: {
        type: "multi-select",
        field: "state_province",
    },
    country: {
        type: "text",
        field: "country",
    },
    buildingArchetype: {
        type: "multi-select",
        field: "building_archetype",
    },
    energyType: {
        type: "multi-select",
        field: "energy_type",
    },
    yearBuiltOriginal: {
        type: "numeric",
        field: "year_built_original",
    },
    grossFloorArea: {
        type: "numeric",
        field: "gross_floor_area",
    },
    annualCarbonEmissionsCurrent: {
        type: "numeric",
        field: "annual_carbon_emissions",
    },
    annualCarbonEmissionIntensityCurrent: {
        type: "numeric",
        field: "annual_carbon_emission_intensity",
    },
    annualEnergyConsumptionCurrent: {
        type: "numeric",
        field: "annual_energy_consumption",
    },
    yearApplied: {
        type: "numeric",
        field: "year_applied",
    },
    netPresentValueIncremental: {
        type: "numeric",
        field: "net_present_value_incremental"
    },
    netPresentValueTotal: {
        type: "numeric",
        field: "net_present_value_total"
    },
    simplePaybackYearsIncremental: {
        type: "numeric",
        field: "simple_payback_years_incremental"
    },
    simplePaybackYearsTotal: {
        type: "numeric",
        field: "simple_payback_years_total"
    },
    carbonReductionMeasureCategory: {
        type: "text",
        field: "carbon_reduction_measure_category"
    },
    carbonReductionMeasureType: {
        type: "text",
        field: "carbon_reduction_measure_id"
    },
};

export const measuresFilterKeyToInfoMap: {
    [key: string]: {
        type: "multi-select" | "text" | "numeric" | "date";
        field:
            | NumericFilterField
            | StringFilterField
            | "data_coverage"
            | "created_at";
    };
} = {
    buildingArchetype: {
        type: "text",
        field: "building_archetype",
    },
    // annualCarbonEmissionIntensityCurrent: {
    //     type: "numeric",
    //     field: "annual_carbon_emission_intensity",
    // },
    // annualEnergyConsumptionCurrent: {
    //     type: "numeric",
    //     field: "annual_energy_consumption",
    // },
};

export const filterConditionToDisplayValueMap = {
    after: "After",
    before: "Before",
    between: "Is between",
    contains: "Contains",
    does_not_contain: "Does not contain",
    ends_with: "Ends with",
    equal: "Is",
    greater_than_equal: "Is more than",
    less_than_equal: "Is less than",
    not_equal: "Is not",
    starts_with: "Starts with",
};

export const DateSelectConditions: DateFilterCondition[] = [
    "equal",
    "before",
    "after",
    "not_equal",
    "between",
];

export const MultiSelectConditions: StringFilterCondition[] = [
    "equal",
    "not_equal",
];
export const NumericSelectConditions: NumericFilterCondition[] = [
    "equal",
    "greater_than_equal",
    "less_than_equal",
    "not_equal",
    "between",
];
export const StringSelectConditions: StringFilterCondition[] = [
    "contains",
    "does_not_contain",
    "equal",
    "not_equal",
    "starts_with",
];

export const DataCoverageEnums: DataCoverageEnum[] = [
    "error",
    "processing",
    "no_equipment_and_no_utility",
    "equipment",
    "equipment_warning",
    "utility",
    "utility_warning",
    "equipment_and_utility",
    "equipment_and_utility_warning",
];
