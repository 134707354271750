import { useQuery } from "react-query";
import { useAccessToken } from "hooks/useAccessToken";
import { QUERY_STALE_TIME } from "utils/constants";
import queryAnnualCarbonEmissionsCard from "ApiLayer/Reports/Baseline/queryAnnualCarbonEmissionsCard";
import useFiltersetState from "recoilStore/Filters/useFilterset";

export const useAnnualCarbonEmissionsCard = () => {
    const accessToken = useAccessToken();
    const { state: filtersetState } = useFiltersetState();
    const filters = {
        reportFiltersetUid: filtersetState.filterset
    }
    
    return useQuery({
        queryKey: ["annualCarbonEmissionsCard", filters],
        queryFn: async () =>
            queryAnnualCarbonEmissionsCard(accessToken, filters),
        staleTime: QUERY_STALE_TIME,
    });
};
