import { useMutation, useQueryClient } from "react-query";
import { useAccessToken } from "hooks/useAccessToken";
import { showMutationAlert } from "utils/helpers";
import useAlert from "hooks/useAlert";
import mutationFn from "../ApiLayer/deleteBuildingModels";

export const useDeleteBuildingModels = (onSuccess?: () => void) => {
    const accessToken = useAccessToken();
    const queryClient = useQueryClient();
    const alert = useAlert();

    return useMutation({
        mutationFn: async (buildingModelUids: string[]) =>
            mutationFn(accessToken, buildingModelUids),
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ["report"],
            });
            queryClient.invalidateQueries({
                queryKey: ["buildingModelsTable"],
            });
            if (onSuccess) onSuccess();
        },
        onError: (errors: Error) => {
             showMutationAlert(errors, alert);
        }
    });
};
