import { graphql } from "gql/gql";
import { request } from "graphql-request";

import { BACKEND_DOMAIN } from "utils/constants";
import {
    buildRequestHeaders
} from "utils/helpers";

const queryFn = (
    accessToken: string,
    data: {
        reportFiltersetUid: string | null;
    }
) =>
    request({
        url: `${BACKEND_DOMAIN}/graphql`,
        document: query,
        variables: {
            reportFiltersetUid: data.reportFiltersetUid,
        },        
        requestHeaders: buildRequestHeaders(accessToken),
    }).then(
        (data) =>
            (data.prefilteredReports?.prefilteredRecommendationsReport
                .cumulativeNetPresentValueCard as CumulativeNetPresentValueCardData) ||
            null
    );

const query = graphql(`
    query recommendationsCumulativeNetPresentValueCard(
        $reportFiltersetUid: Uuid) {
        prefilteredReports(reportFiltersetUid: $reportFiltersetUid) {
            prefilteredRecommendationsReport {
                cumulativeNetPresentValueCard {
                    netPresentValue
                    discountRate
                    years
                    totalMeasureCost
                    totalLikeForLikeCost
                    totalIncrementalCost
                    totalEnergyCostSavings
                    returnOnInvestment
                    averageMarginalAbatementCost
                }
            }
        }
    }
`);

export default queryFn;
