import { request } from "graphql-request";
import { graphql } from "gql/gql";

import { BACKEND_DOMAIN } from "utils/constants";
import {
    buildRequestHeaders,
} from "utils/helpers";

const queryFnCurrentCostCard = (
    accessToken: string,
    data: {
        reportFiltersetUid: string | null;
    }
) =>
    request({
        url: `${BACKEND_DOMAIN}/graphql`,
        document: query,
        variables: {
            reportFiltersetUid: data.reportFiltersetUid,
        },
        requestHeaders: buildRequestHeaders(accessToken),
    }).then((data) => {
        if (data.prefilteredReports?.prefilteredBaselineReport.currentAnnualOperatingCostCard.error)
            throw new Error(
                // eslint-disable-next-line max-len
                data.prefilteredReports?.prefilteredBaselineReport.currentAnnualOperatingCostCard.error
            );
        return (
            // eslint-disable-next-line max-len
            data.prefilteredReports?.prefilteredBaselineReport.currentAnnualOperatingCostCard || null
        );
    });

const query = graphql(`
    query currentAnnualOperatingCostCard($reportFiltersetUid: Uuid) {
        prefilteredReports(reportFiltersetUid: $reportFiltersetUid) {
            prefilteredBaselineReport {
                currentAnnualOperatingCostCard {
                    error
                    averageCarbonTaxCostIntensity
                    averageUtilityCostIntensity
                    totalCarbonTax
                    totalOperatingCost
                    totalUtilityCost
                }
            }
        }
    }
`);

export default queryFnCurrentCostCard;
