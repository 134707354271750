import { request } from "graphql-request";
import { graphql } from "gql/gql";

import { BACKEND_DOMAIN } from "utils/constants";
import {
    buildRequestHeaders,
} from "utils/helpers";

const queryFn = (
    accessToken: string, 
    data: {
        reportFiltersetUid: string | null;
    }
) =>
    request({
        url: `${BACKEND_DOMAIN}/graphql`,
        document: query,
        variables: {
            reportFiltersetUid: data.reportFiltersetUid,
        },      
        requestHeaders: buildRequestHeaders(accessToken),
    }).then(
        (data) =>
            data.prefilteredReports?.prefilteredBaselineReport
                ?.buildingBarChartAndMap as BuildingBarChartAndMap[]
    );

const query = graphql(`
    query buildingBarChartAndMap($reportFiltersetUid: Uuid) {
        prefilteredReports(reportFiltersetUid: $reportFiltersetUid) {
            prefilteredBaselineReport {
                buildingBarChartAndMap {
                    annualCarbonEmissionIntensityCurrent
                    annualCarbonEmissionsCurrent
                    annualEnergyConsumptionCurrent
                    annualEnergyUseIntensityCurrent
                    buildingModelUid
                    location {
                        streetAddress
                        city
                        stateProvince
                        postalZipCode
                        country
                        latitude
                        longitude
                        climateZone
                    }
                }
            }
        }
    }
`);

export default queryFn;
