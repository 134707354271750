import { useParams, useSearchParams } from "react-router-dom";
import { useBuildingModel } from "hooks/Building/useBuildingModel";
import LoadingSpinner from "components/atoms/LoadingSpinner/LoadingSpinner";
import styles from "./Sandbox.module.scss";

function SandboxBuildingPage() {
    const { buildingModelUid } = useParams();
    const [searchParams] = useSearchParams();
    const { data: buildingModel, isLoading } = useBuildingModel(
        buildingModelUid || ""
    );

    if (isLoading) return <LoadingSpinner />;
    if (!buildingModel) return <div>No building data found</div>;

    return (
        <div>
            <div className={styles["sandbox-item"]}>
                <h3>URL Parameters</h3>
                <pre>
                    {JSON.stringify(
                        {
                            buildingModelUid,
                            tab: searchParams.get("tab"),
                            url: window.location.href,
                        },
                        null,
                        2
                    )}
                </pre>
            </div>

            <div className={styles["sandbox-item"]}>
                <h3>Test Actions</h3>
                <button
                    onClick={() =>
                        console.log("Building Model:", buildingModel)
                    }
                    className={styles["sandbox-button"]}
                    type="button"
                >
                    Log Building Data
                </button>
            </div>

            <div className={styles["sandbox-item"]}>
                <h3>Building Model</h3>
                <pre>
                    {JSON.stringify(
                        {
                            buildingModel,
                        },
                        null,
                        2
                    )}
                </pre>
            </div>
        </div>
    );
}

export default SandboxBuildingPage;
