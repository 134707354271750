import { ScatterPlotDatum } from "components/molecules/Charts/ScatterPlot";
import { buildingTypeOptions, ScatterPlotAssetGroup } from "utils/constants";

export interface BuildingDatum {
    buildingName: string;
    streetAddress: string;
    city: string;
    stateProvince: string;
    buildingArchetype: string;
    propertyName: string;
    fundName: string;
    grossFloorArea: number;
    firstYearAnnualCarbonEmissions: number;
    firstYearEnergyConsumptionTotal: number;
}

type BuildingAggregator = (buildingData: BuildingDatum[]) => ScatterPlotDatum[];

function buildingAggregator(buildingData: BuildingDatum[]): ScatterPlotDatum[] {
    return buildingData.map((d) => (
        formatData({
            firstYearAnnualCarbonEmissions: d.firstYearAnnualCarbonEmissions,
            firstYearEnergyConsumptionTotal: d.firstYearEnergyConsumptionTotal,
            grossFloorArea: d.grossFloorArea,
            title: d.buildingName,
            subtitle: `${d.streetAddress}, ${d.city}, ${d.stateProvince}`,
        })
    ));
}

function buildingArchetypeAggregator(buildingData: BuildingDatum[]): ScatterPlotDatum[] {
    const groupedData = Object.groupBy(buildingData, ({ buildingArchetype }) => {
        const humanReadable = (
            buildingTypeOptions.find(({ key }) => key === buildingArchetype)?.label ?? "undefined"
        );
        return humanReadable;
    });
    const scatterPlotData = aggregateGroupedData(groupedData);
    return scatterPlotData;
}

function regionAggregator(buildingData: BuildingDatum[]): ScatterPlotDatum[] {
    const groupedData = Object.groupBy(buildingData, ({ stateProvince }) => stateProvince);
    const scatterPlotData = aggregateGroupedData(groupedData);
    return scatterPlotData;
}

function propertyAggregator(buildingData: BuildingDatum[]): ScatterPlotDatum[] {
    const groupedData = Object.groupBy(buildingData, ({ propertyName }) => propertyName ?? "no property");
    const scatterPlotData = aggregateGroupedData(groupedData);
    return scatterPlotData;
}

function fundAggregator(buildingData: BuildingDatum[]): ScatterPlotDatum[] {
    const groupedData = Object.groupBy(buildingData, ({ fundName }) => fundName ?? "no fund");
    const scatterPlotData = aggregateGroupedData(groupedData);
    return scatterPlotData;
}

export function getBuildingAggregator(
    scatterPlotAssetGroup: ScatterPlotAssetGroup
): BuildingAggregator {
    const groupToAggregator = {
        "building": buildingAggregator,
        "property": propertyAggregator,
        "fund": fundAggregator,
        "region": regionAggregator,
        "buildingArchetype": buildingArchetypeAggregator,
    };
    return groupToAggregator[scatterPlotAssetGroup];
}

function aggregateGroupedData(
    groupedData: Partial<Record<string, BuildingDatum[]>>,
    subtitleKey?: string,
): ScatterPlotDatum[] {
    return Object.entries(groupedData).flatMap(([groupName, groupData]) => {
        if (groupData === undefined || groupData.length === 0) return [];

        const firstYearAnnualCarbonEmissions = (
            groupData.reduce(
                (acc, groupDatum) => acc + groupDatum.firstYearAnnualCarbonEmissions, 0
            )
        );
        const grossFloorArea = (
            groupData.reduce((acc, groupDatum) => acc + groupDatum.grossFloorArea, 0)
        );
        const firstYearEnergyConsumptionTotal = (
            groupData.reduce(
                (acc, groupDatum) => acc + groupDatum.firstYearEnergyConsumptionTotal,
                0
            ) 
        );

        return formatData({
            firstYearAnnualCarbonEmissions,
            firstYearEnergyConsumptionTotal,
            grossFloorArea,
            title: groupName,
            subtitle: groupData[0][subtitleKey as keyof BuildingDatum] || undefined,
        })
    });
}

interface UnformattedDatum {
    firstYearAnnualCarbonEmissions: number;
    firstYearEnergyConsumptionTotal: number;
    grossFloorArea: number;
    title: string;
    subtitle?: string | number;
}

function formatData(data: UnformattedDatum): ScatterPlotDatum {
    const annualCarbonEmissionIntensityCurrent = (
        data.firstYearAnnualCarbonEmissions / data.grossFloorArea * 1000
    );
    const annualEnergyUseIntensityCurrent = (
        data.firstYearEnergyConsumptionTotal / data.grossFloorArea
    );

    return {
        x: annualEnergyUseIntensityCurrent,
        y: annualCarbonEmissionIntensityCurrent,
        z: data.grossFloorArea,
        tooltip: {
            title: data.title,
            subtitle: data?.subtitle,
            tooltipData: [
                {
                    formatKey: "grossFloorArea",
                    value: data.grossFloorArea,
                },
                {
                    formatKey: "annualCarbonEmissionIntensityCurrent",
                    value: annualCarbonEmissionIntensityCurrent,
                },
                {
                    formatKey: "annualEnergyUseIntensityCurrent",
                    value: annualEnergyUseIntensityCurrent,
                },
            ]
        }
    }
}
