import CustomPlanSidebar from "components/organisms/CustomPlanSidebar/CustomPlanSidebar";
import useMeasureSidebarState from "recoilStore/CustomPlan/useMeasureSidebarState";
import EditMeasureSidebar from "components/organisms/MeasureSidebars/EditMeasureSidebar";
import NewProjectSidebar from "components/organisms/MeasureSidebars/NewProjectSidebar";
import { useParams } from "react-router-dom";
import { useBuildingModelHeaderQuery } from "queries/useBuildingModelHeaderQuery";
import BuildingSidebar from "../BuildingSidebar";

interface BuildingPageSidebarsProps {
    isSidebarOpen: boolean;
    handleOnClose: () => void;
    isCustomPlanSidebarOpen: boolean;
    currentCustomPlanDetails: {
        id: string;
        plan: any;
        name: string;
    } | null;
    setIsCustomPlanSidebarOpen: (isOpen: boolean) => void;
}

function BuildingPageSidebars({
    isSidebarOpen,
    handleOnClose,
    isCustomPlanSidebarOpen,
    currentCustomPlanDetails,
    setIsCustomPlanSidebarOpen,
}: BuildingPageSidebarsProps) {
    const { buildingModelUid } = useParams();

    // Recoil store
    const { state: measureSidebarState, setState: setMeasureSidebarState } =
        useMeasureSidebarState();

    if (!buildingModelUid)
        throw new Error("BuildingModelUid param needs to be defined");

    const {
        data: headerData,
    } = useBuildingModelHeaderQuery(buildingModelUid);

    return (
        <>
            <BuildingSidebar
                buildingModelUid={buildingModelUid}
                show={isSidebarOpen}
                onClose={handleOnClose}
                buildingName={headerData?.buildingName || ""}
            />

            {/* Sidebars */}
            <CustomPlanSidebar
                sidebarOpen={isCustomPlanSidebarOpen}
                closeSidebar={() => setIsCustomPlanSidebarOpen(false)}
                buildingModelUid={buildingModelUid}
                currentCustomPlanName={currentCustomPlanDetails?.name || ""}
                currentCarbonReductionPlanUid={
                    currentCustomPlanDetails?.id || ""
                }
            />

            <EditMeasureSidebar
                sidebarOpen={measureSidebarState.isEditOpen}
                closeSidebar={() =>
                    setMeasureSidebarState((prevState) => ({
                        ...prevState,
                        isEditOpen: false,
                    }))
                }
                buildingModelUid={buildingModelUid}
                currentCarbonReductionPlanUid={
                    currentCustomPlanDetails?.id || ""
                }
            />

            <NewProjectSidebar
                sidebarOpen={measureSidebarState.isCreateOpen}
                closeSidebar={() =>
                    setMeasureSidebarState((prevState) => ({
                        ...prevState,
                        isCreateOpen: false,
                    }))
                }
                buildingModelUid={buildingModelUid}
                currentCarbonReductionPlanUid={
                    currentCustomPlanDetails?.id || ""
                }
            />
        </>
    );
}

export default BuildingPageSidebars;
