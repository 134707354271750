// import "./BaselineReport.scss";
import {
    AnnualCumulativePicker,
} from "components/organisms/Charts";
import { RadioButtonSelectMode } from "components/organisms/Charts/AnnualCumulativePicker";
import { useState } from "react";
import { CURRENT_YEAR } from "utils/constants";
import CumulativeCarbonEmissionsCard from "./CumulativeCarbonEmissionsCard";
import CumulativeEnergyConsumptionCard from "./CumulativeEnergyConsumptionCard";
import CumulativeOperatingCostCard from "./CumulativeOperatingCostCard";
import AnnualCarbonEmissionsCard from "./AnnualCarbonEmissionsCard";
import AnnualEnergyConsumptionCard from "./AnnualEnergyConsumptionCard";
import AnnualOperatingCostCard from "./AnnualOperatingCostCard";

function FutureProjectionCards() {
    const [selected, setSelected] =
        useState<RadioButtonSelectMode>("cumulative");
    const [sliderCurrentYear, setSliderCurrentYear] = useState(CURRENT_YEAR);

    const onChangeSelect = () => {
        setSelected((prev) => {
            if (prev === "cumulative") return "annual";
            return "cumulative";
        });
    };

    return (
        <div>
            <AnnualCumulativePicker
                selected={selected}
                onChangeSelect={onChangeSelect}
                currentSliderYear={sliderCurrentYear}
                setCurrentSliderYear={setSliderCurrentYear}
            />
            <div className="cards">
                {selected === "cumulative" ? (
                    <>
                        <CumulativeCarbonEmissionsCard />
                        <CumulativeEnergyConsumptionCard />
                        <CumulativeOperatingCostCard />
                    </>
                ) : (
                    <>
                        <AnnualCarbonEmissionsCard
                            selectedYear={sliderCurrentYear}
                        />
                        <AnnualEnergyConsumptionCard
                            selectedYear={sliderCurrentYear}
                        />
                        <AnnualOperatingCostCard
                            selectedYear={sliderCurrentYear}
                        />
                    </>
                )}
            </div>
        </div>
    );
}

export default FutureProjectionCards;