import { request } from "graphql-request";
import { graphql } from "gql/gql";

import { BACKEND_DOMAIN } from "utils/constants";
import {
    buildRequestHeaders,
} from "utils/helpers";

const queryFn = (accessToken: string, buildingModelRunUid: string) =>
    request({
        url: `${BACKEND_DOMAIN}/graphql`,
        document: query,
        variables: { buildingModelRunUid },
        requestHeaders: buildRequestHeaders(accessToken),
    }).then((data) => {
        if (data.carbonReductionPlanReports.error)
            throw new Error(data.carbonReductionPlanReports.error);
        else
            return data.carbonReductionPlanReports
                ?.cumulativeCarbonEmissionsCard as CumulativeCarbonEmissionsCardData;
    });

const query = graphql(`
    query buildingRecommendationsCumulativeCarbonEmissionsCard(
        $buildingModelRunUid: Uuid!
    ) {
        carbonReductionPlanReports(buildingModelRunUid: $buildingModelRunUid) {
            error
            cumulativeCarbonEmissionsCard {
                totalCarbonEmissions
                totalCarbonEmissionSavings
                totalCarbonEmissionsPercentChange
                totalCarbonEmissionIntensity
                totalCarbonEmissionsNaturalGas
                totalCarbonEmissionsElectricity
            }
        }
    }
`);

export default queryFn;
