import Bar from "./Bar";

function BarLayer({ bars, height }: any) {
    return (
        <>
            {bars.map(({ width, x, y, index, data }: any) => (
                <g key={`$wrapper${index}`} transform={`translate(${x}, ${y})`}>
                    <Bar
                        width={width}
                        height={height}
                        data={data}
                        index={index}
                    />
                </g>
            ))}
        </>
    );
}

export default BarLayer;