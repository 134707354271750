import { useAuth0 } from "@auth0/auth0-react";
import { BackArrow, CogWheelIcon, ToolIcon } from "components/atoms/Icon";
import { Logo } from "components/atoms/Logo";
import { usePermissions } from "hooks/usePermissions";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { UserPermissions } from "utils/enums";
import { Header as HeaderText, Paragraph } from "../../atoms/Typography";
import HeaderDropdownMenu from "./HeaderDropdownMenu";

interface HeaderNavBarProps {
    memberOrganizations: CustomerAccount[];
    activeOrganization: string;
}

function HeaderNavBar({
    memberOrganizations,
    activeOrganization,
}: HeaderNavBarProps) {

    const location = useLocation();
    const navigate = useNavigate();

    const { user } = useAuth0();
    const { hasPermission } = usePermissions();

    const userEmail = user?.email;
    if (!userEmail)
        throw new Error(`User email is ${userEmail} when it should be defined`);

    if (location.pathname.includes("building/")) {
        return (
            <div className="nav-bar">
                <Paragraph className="tabs">
                    <button
                        className="back-button"
                        type="button"
                        onClick={() => navigate("/")}
                    >
                        <BackArrow color="var(--audette-white)" />

                        {location.pathname.includes("energy")
                            // [DP 2025-03-13] Does this ever actually happen on the buildings page? 
                            // I could not think of or find a scenario where we didnt want to just go to the portfolio...
                            ? "Back"
                            : "Back to portfolio"}
                    </button>
                </Paragraph>
                <div className="nav-bar__user-controls">
                    {/* Use for the setting page */}

                    {hasPermission(UserPermissions.ReadUser) && (
                        <Tab title="Settings" route="/settings">
                            <CogWheelIcon />
                        </Tab>
                    )}

                    <HeaderDropdownMenu
                        userEmail={userEmail}
                        customerAccounts={memberOrganizations}
                        activeOrganization={activeOrganization}
                    />
                </div>
            </div>
        );
    }

    return (
        <div className="nav-bar">
            <Logo />
            <div className="tabs">
                <Tab title="Portfolio" route="/portfolio" />
                <Tab title="Reports" route="/reports" />
            </div>
            <div className="nav-bar__user-controls">
            {hasPermission(UserPermissions.UseInternalTools) && (
                        <Tab title="Sandbox" route="/sandbox">
                            <ToolIcon />
                        </Tab>
            )}

            {hasPermission(UserPermissions.ReadUser) && (
                        <Tab title="Settings" route="/settings">
                            <CogWheelIcon />
                        </Tab>
            )}

                <HeaderDropdownMenu
                    userEmail={userEmail}
                    customerAccounts={memberOrganizations}
                    activeOrganization={activeOrganization}
                />
            </div>
        </div>
    );
}

interface TabProps {
    route: string;
    title: string;
    children?: React.ReactNode;
}

function Tab({ route, title, children }: TabProps) {
    return (
        <NavLink
            to={route}
            style={({ isActive }) =>
                isActive
                    ? {
                          height: "100%",
                      }
                    : undefined
            }
            id={`${title.toLowerCase()}-nav-bar-tab`}
        >
            <HeaderText size="small">
                <button className={children ? "cogWheel" : "tab"} type="button">
                    {children || title}
                </button>
            </HeaderText>
        </NavLink>
    );
}

export default HeaderNavBar;
