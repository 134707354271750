import { CURRENT_YEAR } from "utils/constants";

export interface EditMeasureState {
    measureYear: number;
    totalMeasureCost: number | null;
    measureSize: number | null;
    dirtyFields: {
        year: boolean;
        cost: boolean;
        size: boolean;
    };
}

export type EditMeasureAction =
    | { type: "SET_YEAR"; value: number | null }
    | { type: "SET_COST"; value: number | null }
    | { type: "SET_SIZE"; value: number | null }
    | { type: "RESET"; initialState: EditMeasureState };

export const editMeasureSidebarReducer = (
    state: EditMeasureState,
    action: EditMeasureAction
): EditMeasureState => {
    switch (action.type) {
        case "SET_YEAR":
            return {
                ...state,
                measureYear: action.value ?? CURRENT_YEAR,
                dirtyFields: { ...state.dirtyFields, year: true },
            };
        case "SET_COST":
            return {
                ...state,
                totalMeasureCost: action.value,
                dirtyFields: { ...state.dirtyFields, cost: true },
            };
        case "SET_SIZE":
            return {
                ...state,
                measureSize: action.value,
                dirtyFields: { ...state.dirtyFields, size: true },
            };
        case "RESET":
            return action.initialState;
        default:
            return state;
    }
};
