import { ResponsiveScatterPlot } from '@nivo/scatterplot'
import { getDisplayInfo } from "utils/formatting";
import ChartTooltip, { TooltipRow } from "./ChartTooltip";
import { theme } from "./ChartTheme";

interface TooltipDatum {
    formatKey: string;
    value: number;
}

interface TooltipProps {
    title: string;
    subtitle?: string | number;
    tooltipData: TooltipDatum[];
}

export interface ScatterPlotDatum {
    x: number;
    y: number;
    z: number;
    tooltip: TooltipProps;
}

interface ScatterPlotProps {
    data: ScatterPlotDatum[];
    margin?: any;
    xLabel: string;
    yLabel: string;
}

function ScatterPlot({
    data,
    margin = { top: 40, right: 200, bottom: 80, left: 200 },
    xLabel,
    yLabel,
}: ScatterPlotProps) {
    const xVals = data.map((d) => d.x);
    const yVals = data.map((d) => d.y);
    const zVals = data.map((d) => d.z);

    const X_AXIS_MAX = 50;
    
    let xMin = Math.min(...xVals);
    let xMax = Math.max(...xVals);
    // [NIB 250207] without this update to xMin and xMax, when there is only a
    // single point on the plot, an error occurs which causes the single gridline
    // shown for the single point plot to carry over to all other dropdown
    // selections. This value update doesn't prevent the centre line from showing
    // when there's only a single data point, but it does preven that centre
    // line from carrying over to other dropdown selections
    if (xMin === xMax) {
        const xPercentOffset = 0.1;
        xMin -= Math.abs(xMin) * xPercentOffset;
        xMax += Math.abs(xMax) * xPercentOffset;
    }

    const yMin = 0;
    const yMax = Math.max(...yVals);
    const gridXValues = [xMin, xMax];
    const gridYValues = [yMin, yMax];
    const zMinMax: [number, number] = [Math.min(...zVals), Math.max(...zVals)];

    const clampedData = data.map(d => ({
        ...d,
        x: Math.min(d.x, X_AXIS_MAX) // Max value displayed on x-axis
    }));

    const plotData = [
        {
            "id": "scatter plot",
            "data": clampedData
        }
    ]

    const tooltipFunction = ({ node }: any): any => {
        const { tooltip }: ScatterPlotDatum = node.data;
        const rows: TooltipRow[] = tooltip.tooltipData.map(({ formatKey, value }) => {
            const { formatFunction, formatFromUnit, humanReadable, unit } = (
                getDisplayInfo(formatKey)
            );
            const showDecimals = false;
            return {
                label: humanReadable,
                unit,
                value: formatFromUnit ? formatFromUnit(unit)(value, showDecimals)
                    : formatFunction(value),
            };
        });
        return (
            <div style={{
                maxWidth: '350px', 
                whiteSpace: 'normal',
                wordWrap: 'break-word'
            }}>
                <ChartTooltip
                    title={tooltip.title}
                    subtitle={tooltip.subtitle}
                    rows={rows}
                />
            </div>
        );
    };

    // make sure parent container have a defined height when using
    // responsive component, otherwise height will be 0 and
    // no chart will be rendered.
    return (
        <div className="scatter-plot" style={{ height: 460, width: "100%" }}>
            <ResponsiveScatterPlot
                data={plotData}
                colors="var(--audette-purple-100)"
                margin={margin}
                xScale={{ type: 'linear', min: "auto", max: 'auto' }}
                xFormat=">-.2f"
                yScale={{ type: 'linear', min: 0, max: 'auto' }}
                yFormat=">-.2f"
                gridXValues={gridXValues}
                gridYValues={gridYValues}
                blendMode="multiply"
                theme={theme}
                tooltip={tooltipFunction}
                nodeSize={{
                    key: "data.z",
                    values: zMinMax,
                    sizes: [20, 100]
                }}
                axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: xLabel,
                    legendPosition: 'middle',
                    legendOffset: 40,
                    truncateTickAt: 0
                }}
                axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: yLabel,
                    legendPosition: 'middle',
                    legendOffset: -60,
                    truncateTickAt: 0
                }}
            />
        </div>
    )
}

export default ScatterPlot;
