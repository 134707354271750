import { PlusIcon } from "components/atoms/Icon";
import useMeasureSidebarState from "recoilStore/CustomPlan/useMeasureSidebarState";
import { Paragraph } from "components/atoms/Typography";
import styles from "./EditCustomPlanButton.module.scss";

function CreateProjectButton() {
    const { setState: setMeasureSidebarState } = useMeasureSidebarState();

    return (
        <button
            className={styles.createProjectButton}
            type="button"
            onClick={() =>
                setMeasureSidebarState((prevState) => ({
                    ...prevState,
                    isCreateOpen: true,
                }))
            }
        >
            <PlusIcon />
            <Paragraph>Create Project</Paragraph>
        </button>
    );
}

export default CreateProjectButton;
