import createReportFiltersetFn from "ApiLayer/Measures/queryCreateReportFilterset";
import { useAccessToken } from "hooks/useAccessToken";
import useAlert from "hooks/useAlert";
// import { userQueryKey } from "hooks/useUser";
import { useMutation } from "react-query";
import useFiltersetState from "recoilStore/Filters/useFilterset";
import useFilters from "recoilStore/useFilters";
import { showMutationAlert } from "utils/helpers";

export const useCreateReportFilterset = () => {
    const accessToken = useAccessToken();
    const alert = useAlert();
    const { filters } = useFilters("reports");
    const { setState: setFiltersetState } = useFiltersetState();

    return useMutation({
        onError: (error) => {
            showMutationAlert(error, alert);
        },
        onSuccess: (data) => {
            if (data.createReportFilterset) {
                setFiltersetState({ filterset: data.createReportFilterset });
            } else {
                alert.error("Failed to create report filterset");
            }
        },
        mutationFn: async () =>
            createReportFiltersetFn(accessToken, filters),
    });
};
