import errorFallbackUI from "components/molecules/ErrorFallbackUI.tsx/ErrorFallbackUI";
import { ComponentErrorBoundary } from "components/molecules/ErrorStates";
import { Table } from "components/molecules/Table";
import { usePermissions } from "hooks/usePermissions";
import { useFormattedCarbonReductionData } from "pages/BuildingPage/Helper/BuildingPage.helper";
import useCustomPlanSidebarState from "recoilStore/CustomPlan/useCustomPlanSidebarState";
import { UserPermissions } from "utils/enums";
import "./CustomPlan.scss";
import CustomPlanHeader from "./Header/CustomPlanHeader";

interface CustomPlanProps {
    currentCustomPlan: CarbonReductionPlan;
    grossFloorArea: number;
    currentCarbonReductionPlanUid: string;
}

function CustomPlan({
    currentCustomPlan,
    grossFloorArea,
    currentCarbonReductionPlanUid,
}: CustomPlanProps) {
    const { hasPermission } = usePermissions();
    const {
        state: { selectedRows },
        setState: setCustomPlanSidebarState,
    } = useCustomPlanSidebarState();

    const { formattedData, tableColumns, dataSource } =
        useFormattedCarbonReductionData(
            currentCustomPlan.carbonReductionMeasures,
            grossFloorArea,
            currentCustomPlan.carbonReductionPlanUid
        );
    const handleSelectedRowsChange = (rows: CustomPlanDataSource[]) => {
        setCustomPlanSidebarState(state => ({ ...state, selectedRows: rows }));
    };

    return (
        <div className="custom-plan-report">
            <ComponentErrorBoundary
                fallback={errorFallbackUI("tableError")}
                originComponent="ProjectsReport"
            >
                {hasPermission(UserPermissions.UseInternalTools) && (
                    <CustomPlanHeader
                        formattedData={formattedData}
                        currentCarbonReductionPlanUid={
                            currentCarbonReductionPlanUid
                        }
                    />
                )}
                <Table
                    dataSource={dataSource}
                    columns={tableColumns}
                    className="measures-projects-report-table"
                    pagination={false}
                    selectedRows={selectedRows}
                    setSelectedRows={handleSelectedRowsChange}
                    // onRowClick={handleRowClick}
                />
            </ComponentErrorBoundary>
        </div>
    );
}

export default CustomPlan;
