import { useQuery } from "react-query";
import { useAccessToken } from "hooks/useAccessToken";
import { QUERY_STALE_TIME } from "utils/constants";
import queryFn from "ApiLayer/Reports/Baseline/queryCumulativeCarbonEmissionsCard";
import useFiltersetState from "recoilStore/Filters/useFilterset";

export const useCumulativeCarbonEmissionsCard = () => {
    const accessToken = useAccessToken();
    const { state: filtersetState } = useFiltersetState();
    const filters = {
        reportFiltersetUid: filtersetState.filterset
    }

    return useQuery({
        queryKey: ["cumulativeCarbonEmissionsCard", filters],
        queryFn: async () => queryFn(accessToken, filters),
        staleTime: QUERY_STALE_TIME,
    });
};
