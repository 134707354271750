import { useBuildingModel } from "hooks/Building/useBuildingModel";
import { useUpdateCustomPlanMeasures } from "mutations/CustomPlans/useUpdateCustomPlanMeasures";
import useCustomPlanSidebarState from "recoilStore/CustomPlan/useCustomPlanSidebarState";
import { addProjectToMeasures, currentYear, editProjectInMeasures, getSelectedMeasuresUids, getSelectedMeasureUid, getSelectedPlan, measuresToMeasureInput, removeMeasureInputUid, removeProjectsFromMeasures } from "./useCustomPlanUpdate.utils";

interface UseCustomPlanUpdateParams {
    buildingModelUid: string;
    currentCarbonReductionPlanUid: string;
}

export const useCustomPlanUpdate = ({
    buildingModelUid,
    currentCarbonReductionPlanUid,
}: UseCustomPlanUpdateParams) => {
    const { data: buildingModel } = useBuildingModel(buildingModelUid);
    const { mutate, isLoading } = useUpdateCustomPlanMeasures();
    const { resetSelections } = useCustomPlanSidebarState();

    const updateCustomPlanMeasures = (
        updateType: "edit" | "delete" | "create",
        options: {
            selectedRows?: CustomPlanDataSource[];
            measureYear?: number;
            totalMeasureCost?: number | null;
            measureSize?: number | null;
            onSuccess?: () => void;
            onComplete?: () => void;
            newProject?: NewProject;
        } = {}
    ) => {
        if (buildingModel === undefined) return;
        const planToModify = getSelectedPlan(
            buildingModel,
            currentCarbonReductionPlanUid
        );

        const measures = planToModify?.plan?.carbonReductionMeasures;
        if (!measures) return;
        const measureInputs = measuresToMeasureInput(measures);

        let updatedMeasures: CarbonReductionMeasureInput[] = [];

        if (updateType === "edit") {
            const {
                selectedRows = [],
                measureYear = currentYear,
                totalMeasureCost,
                measureSize,
            } = options;

            
            const selectedMeasureUid = getSelectedMeasureUid(selectedRows);
            updatedMeasures = editProjectInMeasures(
                measureInputs,
                selectedMeasureUid,
                measureYear,
                totalMeasureCost,
                measureSize
            );
        }

        if (updateType === "delete") {
            const { selectedRows = [] } = options;
            const selectedCarbonReductionMeasureUids =
                getSelectedMeasuresUids(selectedRows);
            updatedMeasures = removeProjectsFromMeasures(
                measureInputs,
                selectedCarbonReductionMeasureUids
            );
        }

        if (updateType === "create") {
            const { newProject } = options;
            if (!newProject) return;
            updatedMeasures = addProjectToMeasures(measureInputs, newProject);
        }

        mutate(
            {
                customPlanId: currentCarbonReductionPlanUid,
                buildingModelUid,
                measures: removeMeasureInputUid(updatedMeasures) || [],
            },
            {
                onSuccess: () => {
                    options.onSuccess?.();
                    resetSelections();
                }
            }
        );
    };

    return {
        updateCustomPlanMeasures,
        isLoading,
    };
};
