import { request } from "graphql-request";
import { graphql } from "gql/gql";

import { BACKEND_DOMAIN } from "utils/constants";
import {
    buildRequestHeaders,
} from "utils/helpers";

const queryFn = (
    accessToken: string,
    data: {
        reportFiltersetUid: string | null;
    }
) =>    
    request({
        url: `${BACKEND_DOMAIN}/graphql`,
        document: query,
        variables: {
            reportFiltersetUid: data.reportFiltersetUid,
        },
        requestHeaders: buildRequestHeaders(accessToken),
    }).then(
        (data) =>
            (data.prefilteredReports?.prefilteredRecommendationsReport
                .cumulativeEmissionSavingsCard as CumulativeEmissionSavingsCardData) ||
            null
    );

const query = graphql(`
    query cumulativeEmissionSavingsCard($reportFiltersetUid: Uuid) {
        prefilteredReports(reportFiltersetUid: $reportFiltersetUid) {
            prefilteredRecommendationsReport {
                cumulativeEmissionSavingsCard {
                    totalCarbonEmissionSavings
                    totalCarbonEmissionSavingsFromMeasures
                    totalCarbonEmissionSavingsFromGrid
                }
            }
        }
    }
`);

export default queryFn;
