import { ExportIcon, Delete } from "components/atoms/Icon";
import { Paragraph } from "components/atoms/Typography";
import CsvDownloadButton from "react-json-to-csv";
import DangerousModal from "components/molecules/DangerousModal";
import { useState } from "react";
import useCustomPlanSidebarState from "recoilStore/CustomPlan/useCustomPlanSidebarState";
import { useNavigate, useParams } from "react-router-dom";
import { useCustomPlanUpdate } from "hooks/CustomPlan/useCustomPlanUpdate";
import { useDeleteCustomCarbonReductionPlan } from "mutations/CustomPlans/useDeleteCustomCarbonReductionPlan";
import styles from "./HeaderSharedStyles.module.scss";
import EditCustomPlanButton from "./EditCustomPlanButton";
import CreateProjectButton from "./CreateProjectButton";
import DeleteCustomPlanButton from "./DeleteCustomPlanButton";
import { buildDeleteCustomPlanModalOptions, buildDeleteProjectModalOptions } from "./CustomPlanHeader.utils";

interface CustomPlanHeaderProps {
    formattedData: FormattedCarbonReductionData[];
    currentCarbonReductionPlanUid: string;
}

function CustomPlanHeader({
    formattedData,
    currentCarbonReductionPlanUid,
}: CustomPlanHeaderProps) {
    const [deleteProjectModalIsOpen, setDeleteProjectModalIsOpen] = useState(false);
    const [deleteCustomPlanModalIsOpen, setDeleteCustomPlanModalIsOpen] = useState(false);

    const { 
        state: { selectedRows }, 
        setState: setCustomPlanSidebarState 
    } = useCustomPlanSidebarState();

    const { buildingModelUid, buildingModelRunUid } = useParams();

    if (!buildingModelUid)
        throw new Error("BuildingModelUid param needs to be defined"); // TODO: create a custom hook for this

    // Hooks
    const navigate = useNavigate();

    const { 
        updateCustomPlanMeasures, 
        isLoading: updateCustomPlanMeasuresLoading 
    } = useCustomPlanUpdate({
        buildingModelUid,
        currentCarbonReductionPlanUid,
    });
    
    const { 
        mutate: mutationDeleteCustomCRM, 
        isLoading: deleteCustomPlanLoading,
    } = useDeleteCustomCarbonReductionPlan();

    const handleDeleteCRM = () => {
        updateCustomPlanMeasures( "delete", {
                selectedRows,
                onSuccess: () => {
                    setCustomPlanSidebarState(state => ({ ...state, selectedRows: [] }));
                    setDeleteProjectModalIsOpen(false);
                },
            }
        );
    };

    const handleDeleteCustomPlan = () => {
        mutationDeleteCustomCRM(
            {
                carbonReductionPlanUid: currentCarbonReductionPlanUid,
                buildingModelUid,
            },
            {
                onSuccess: () => {
                    navigate(`/building/${buildingModelUid}/baseline/${buildingModelRunUid}`);
                    setDeleteCustomPlanModalIsOpen(false);
                },
            }
        );
    }
    
    const deleteProjectsModalOptions = buildDeleteProjectModalOptions(
        selectedRows.length,
        updateCustomPlanMeasuresLoading
    );

    const deleteCustomPlanModalOptions = buildDeleteCustomPlanModalOptions(
        deleteCustomPlanLoading
    );

    return (
        <div className={styles.customPlanHeader}>
            {selectedRows.length > 0 && (
                <>
                    <EditCustomPlanButton
                        selectedCustomPlanLength={selectedRows.length}
                    />

                    {/* // Delete button */}
                    <Paragraph
                        style={{ color: "var(--audette-content-negative)" }}
                    >
                        <button
                            className={styles.deleteCustomPlanButton}
                            type="button"
                            onClick={() => setDeleteProjectModalIsOpen(true)}
                        >
                            <Delete />
                            Delete project
                            {selectedRows.length === 1 ? "" : "s"} (
                            {selectedRows.length})
                        </button>
                    </Paragraph>
                </>
            )}

            {/* Modal to delete measure */}
            <DangerousModal
                open={deleteProjectModalIsOpen}
                onCancel={() => setDeleteProjectModalIsOpen(false)}
                onDelete={handleDeleteCRM}
                options={deleteProjectsModalOptions}
                styleType="primary-negative"
                loading={updateCustomPlanMeasuresLoading}
            />

            {/* Modal to delete plan */}
            <DangerousModal
                open={deleteCustomPlanModalIsOpen}
                onCancel={() => setDeleteCustomPlanModalIsOpen(false)}
                onDelete={handleDeleteCustomPlan}
                options={deleteCustomPlanModalOptions}
                styleType="primary-negative"
                loading={deleteCustomPlanLoading}
            />

            <DeleteCustomPlanButton 
                setDeleteCustomPlanModalIsOpen={setDeleteCustomPlanModalIsOpen}
            />

            <CreateProjectButton />

            <CsvDownloadButton
                data={formattedData}
                filename="buildingProjectsData.csv"
                delimiter=","
            >
                <div className="measures-page-flex-container">

                    <ExportIcon />
                    <Paragraph>Export</Paragraph>
                </div>
            </CsvDownloadButton>
        </div>
    );
}

export default CustomPlanHeader;
