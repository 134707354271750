import SandboxLayout from "./SandboxLayout";
import styles from './Sandbox.module.scss';

function SandboxPage() {
    return (
        <SandboxLayout>
            <div className={styles['sandbox-grid']}>
                <div className={styles['sandbox-item']}>
                    <h3>Test Component 1</h3>
                </div>
                <div className={styles['sandbox-item']}>
                    <h3>Test Component 2</h3>
                </div>
            </div>
        </SandboxLayout>
    );
}

export default SandboxPage;
