import { Button } from "components/atoms/Button";
import LoadingSpinner from "components/atoms/LoadingSpinner/LoadingSpinner";
import Sidebar from "components/molecules/Sidebar";
import { useCustomPlanUpdate } from "hooks/CustomPlan/useCustomPlanUpdate";
import { useEffect, useReducer } from "react";
import useCustomPlanSidebarState from "recoilStore/CustomPlan/useCustomPlanSidebarState";
import { CURRENT_YEAR } from "utils/constants";
import MeasureInput from "./MeasureInput";
import {
    editMeasureSidebarReducer,
} from "./editMeasureSidebarReducer";

interface EditMeasureSidebarProps {
    closeSidebar: () => void;
    sidebarOpen: boolean;
    buildingModelUid: string;
    currentCarbonReductionPlanUid: string;
}

function EditMeasureSidebar({
    closeSidebar,
    sidebarOpen,
    buildingModelUid,
    currentCarbonReductionPlanUid,
}: EditMeasureSidebarProps) {
    const { state: customPlanSidebarState } = useCustomPlanSidebarState();
    const currentMeasureYear = customPlanSidebarState?.selectedRows[0]?.yearApplied;
    const currentMeasureCost = customPlanSidebarState?.selectedRows[0]?.totalMeasureCost;
    const currentMeasureSize = customPlanSidebarState?.selectedRows[0]?.measureSize;

    const createInitialState = () => ({
        measureYear: currentMeasureYear ?? CURRENT_YEAR,
        totalMeasureCost: currentMeasureCost ?? null,
        measureSize: currentMeasureSize ?? null,
        dirtyFields: { year: false, cost: false, size: false },
    });

    const [state, dispatch] = useReducer(
        editMeasureSidebarReducer,
        createInitialState()
    );

    // Initializing reducer with selected row data
    useEffect(() => {
        dispatch({
            type: "RESET",
            initialState: createInitialState()
        });
    }, [currentMeasureYear, currentMeasureCost, currentMeasureSize]);

    const { updateCustomPlanMeasures, isLoading } = useCustomPlanUpdate({
        buildingModelUid,
        currentCarbonReductionPlanUid,
    });

    const onClickCancel = () => {
        closeSidebar();
    };

    const onClickApply = () => {
        updateCustomPlanMeasures("edit", {
            selectedRows: customPlanSidebarState.selectedRows,
            ...(state.dirtyFields.year && { measureYear: state.measureYear }),
            ...(state.dirtyFields.cost && {
                totalMeasureCost: state.totalMeasureCost,
            }),
            ...(state.dirtyFields.size && { measureSize: state.measureSize }),
            onSuccess: closeSidebar,
        });
    };

    if (isLoading) {
        return (
            <Sidebar
                onCancel={closeSidebar}
                sidebarOpen={sidebarOpen}
                title="Edit Measure"
            >
                <LoadingSpinner />
            </Sidebar>
        );
    }

    return (
        <Sidebar
            onCancel={closeSidebar}
            sidebarOpen={sidebarOpen}
            title="Edit Measure"
        >
            <div className="filter-container">
                <MeasureInput
                    label="Implementation Year"
                    value={state.measureYear}
                    onChange={(value) => dispatch({ type: "SET_YEAR", value })}
                    placeholder="Edit measure year"
                />
                <MeasureInput
                    label="Project Total Cost"
                    value={state.totalMeasureCost}
                    onChange={(value) => dispatch({ type: "SET_COST", value })}
                    placeholder="Edit measure cost (optional)"
                />
                <MeasureInput
                    label="Project Size"
                    value={state.measureSize}
                    onChange={(value) => dispatch({ type: "SET_SIZE", value })}
                    placeholder="Edit measure size (optional)"
                />
            </div>

            {/* Footer */}
            <div className="filters-sidebar__footer">
                <div className="filters-sidebar__footer__buttons">
                    <Button
                        type="primary"
                        onClick={onClickApply}
                        disabled={isLoading}
                    >
                        {isLoading ? "Loading" : "Apply"}
                    </Button>

                    {!isLoading && (
                        <Button
                            onClick={onClickCancel}
                            type="link"
                            disabled={isLoading}
                        >
                            Cancel
                        </Button>
                    )}
                </div>
            </div>
        </Sidebar>
    );
}

export default EditMeasureSidebar;
