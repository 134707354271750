// hooks
import { useNavigate } from "react-router-dom";

// store
import useFilters from "recoilStore/useFilters";

// components
import { EmissionIntensityMap } from "components/organisms/Charts";
import { ErrorPage } from "pages/ErrorState";
import TableLoading from "components/molecules/TableLoading";
import { FilterLabelCards } from "../../components/organisms/FiltersSidebar/FilterLabelCards";
import { TableColumn, Table } from "../../components/molecules/Table";

// helpers
import { BuildingsTableData } from "./helpers";

// styling
import "./Buildings.scss";
import { BuildingsData } from "./constants";

interface BuildingsProps {
    buildingsData: BuildingsData;
    view: "map" | "table";
}

function Buildings({ buildingsData, view }: BuildingsProps) {
    const {
        error,
        isLoading,
        selectedBuildingRows,
        setSelectedBuildingRows,
        formattedTableViewColumns,
        tableViewTableData,
    } = buildingsData;

    const { numberOfFilters } = useFilters("buildings");

    if (error) return <ErrorPage />;
    if (isLoading) return <TableLoading />;

    return (
        <div className="buildings-page">
            {numberOfFilters !== 0 && <FilterLabelCards page="buildings" />}
            <ViewBody
                tableColumns={formattedTableViewColumns}
                tableData={tableViewTableData}
                currentView={view}
                selectedRows={selectedBuildingRows}
                setSelectedRows={setSelectedBuildingRows}
            />
        </div>
    );
}
interface ViewBodyProps {
    currentView: PortfolioPageView;
    tableColumns: TableColumn[];
    tableData: BuildingsTableData[];
    selectedRows: BuildingsTableData[];
    setSelectedRows: React.Dispatch<React.SetStateAction<BuildingsTableData[]>>;
}

function ViewBody({
    currentView,
    tableColumns,
    tableData,
    selectedRows,
    setSelectedRows,
}: ViewBodyProps) {
    const navigate = useNavigate();

    const handleBuildingRowClick = (row: BuildingsTableData) => {
        const uid = row.buildingModelUid;
        if (uid) navigate(`/building/${uid}`);
    };

    const convertedTableData = tableData.map(row => ({
        ...row,
        lastUpdated: new Date(row.lastUpdated)
      }));

    return (
        <div className="view-body">
            {currentView === "table" ? (
                <Table
                    columns={tableColumns}
                    dataSource={convertedTableData}
                    selectedRows={selectedRows}
                    setSelectedRows={setSelectedRows}
                    onRowClick={(row) => handleBuildingRowClick(row)}
                />
            ) : (
                <EmissionIntensityMap includeHeader={false} />
            )}
        </div>
    );
}

export default Buildings;
