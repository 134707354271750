import { Tooltip } from "@mui/material";
import MUITheme from "components/MUITheme";
import { ReactElement } from "react";
import ByBuildingChartTooltip from "./ByBuildingChartTooltip";

interface TooltipWrapperProps {
    children: ReactElement<any, any>;
    index: number;
}

function InteractiveTooltipWrapper({ children, index }: TooltipWrapperProps) {
    const tooltipPopperConfig = {
        popperOptions: {
            modifiers: [
                {
                    name: "flip",
                    options: {
                        fallbackPlacements: ["bottom", "left"],
                    },
                },
            ],
        },
    };
    return (
        <MUITheme>
            <Tooltip
                title={<ByBuildingChartTooltip currentDataIndex={index} />}
                placement="top"
                style={{ padding: 0 }}
                leaveDelay={50}
                PopperProps={tooltipPopperConfig}
            >
                {children}
            </Tooltip>
        </MUITheme>
    );
}

export default InteractiveTooltipWrapper;
