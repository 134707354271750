/* eslint-disable max-len */
import { request } from "graphql-request";
import { graphql } from "gql/gql";

import { BACKEND_DOMAIN } from "utils/constants";
import {
    buildRequestHeaders,
} from "utils/helpers";

const queryFn = (
    accessToken: string,
    data: {
        reportFiltersetUid: string | null;
    }
) =>
    request({
        url: `${BACKEND_DOMAIN}/graphql`,
        document: query,
        variables: {
            reportFiltersetUid: data.reportFiltersetUid,
        },
        requestHeaders: buildRequestHeaders(accessToken),
    }).then(
        (data) =>
            (data.prefilteredReports?.prefilteredRecommendationsReport
                .annualEmissionSavingsByMeasureTable as AnnualEmissionSavingsByMeasureCategoryTableData) ||
            null
    );

const query = graphql(`
    query annualEmissionSavingsByMeasureTable($reportFiltersetUid: Uuid) {
        prefilteredReports(reportFiltersetUid: $reportFiltersetUid) {
            prefilteredRecommendationsReport {
                annualEmissionSavingsByMeasureTable {
                    impactPercentage
                    carbonReductionMeasureCategory
                    measureCount
                    totalAnnualCarbonEmissionSavings
                    totalAnnualEnergyConsumptionSavings
                    totalAnnualEnergyCostSavings
                    totalMeasureCost
                }
            }
        }
    }
`);

export default queryFn;
