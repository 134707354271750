import { Header } from "components/atoms/Typography";
import { useState } from "react";
import { Button } from "components/atoms/Button";
import { AddIcon } from "components/atoms/Icon";
import BuildingsCsvUploader from "../BuildingsCsvUploader";
import AddBuildingSidebar from "../AddBuildingSidebar";

function PageHeader() {
    const [buildingSidebarIsOpen, setBuildingSidebarIsOpen] = useState(false);

    return (
        <>
            <div className="page-header">
                <div>
                    <Header size="large">Portfolio</Header>
                </div>
                <BuildingsCsvUploader />
                <Button
                    type="primary"
                    id="add-building-button"
                    onClick={() => setBuildingSidebarIsOpen(true)}
                    icon={<AddIcon color="var(--audette-white)" />}
                >
                    Add building
                </Button>
            </div>
            <AddBuildingSidebar
                onCancel={() => setBuildingSidebarIsOpen(false)}
                sidebarOpen={buildingSidebarIsOpen}
                closeSidebar={() => setBuildingSidebarIsOpen(false)}
            />
        </>
    );
}

export default PageHeader;