import { graphql } from "gql";
import request from "graphql-request";
import { BACKEND_DOMAIN } from "utils/constants";
import { buildRequestHeaders } from "utils/helpers";

const mutationUpdateCustomPlanMeasures = (
    accessToken: string,
    data: {
        customPlanId: string,
        measures: CarbonReductionMeasureInput[]
    }
) => 
    request({
        url: `${BACKEND_DOMAIN}/graphql`,
        document: mutationQuery,
        variables: {
            id: data.customPlanId,
            measures: data.measures,
        },
        requestHeaders: buildRequestHeaders(accessToken),   
    }).then((data) => {
        if (data) {
            return data;
        }
        throw new Error("Error updating custom plan measures");
    })
    .catch((error) => {
        console.error("GraphQL Error:", error);
        throw error; 
    });

const mutationQuery = graphql(`
    mutation updateCustomPlanMeasures ($id: Uuid!, $measures: [CarbonReductionMeasureInput]!) {
        updateCustomPlanMeasures (id: $id, measures: $measures)
    }
`);        

export default mutationUpdateCustomPlanMeasures;
