import { PropsWithChildren, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { UserPermissions } from "utils/enums";
import LoadingSpinner from "components/atoms/LoadingSpinner/LoadingSpinner";
import { usePermissions } from "./usePermissions";

interface ProtectedRouteProps extends PropsWithChildren {
  permissionRequired: UserPermissions;
}

function ProtectedRoute({ children, permissionRequired }: ProtectedRouteProps) {
  const navigate = useNavigate();
  const { hasPermission, isLoading } = usePermissions();


  useEffect(() => {
    if (!isLoading && !hasPermission(permissionRequired)) {
      navigate("/portfolio");
    }
  }, [hasPermission, isLoading, navigate]);

  if (isLoading) {
    return (
        <LoadingSpinner />
    );
}

  return <div>{children}</div>;
}

export default ProtectedRoute;