import "./BaselineReport.css";
import { Header } from "components/atoms/Typography";
import {
    ByBuildingBarChart,
    EmissionIntensityMap,
} from "components/organisms/Charts";
import { CURRENT_YEAR } from "utils/constants";
import EnergyAndEmissionIntensityChart from "components/organisms/Charts/Baseline/EnergyAndEmissionIntensityChart";
import CurrentAnnualCarbonEmissionsCard from "./ReportCards/CurrentAnnualCarbonEmissionsCard";
import CurrentAnnualEnergyConsumptionCard from "./ReportCards/CurrentAnnualEnergyConsumptionCard";
import TotalAnnualOperatingCostCard from "./ReportCards/TotalAnnualOperatingCostCard";
import CurrentAnnualEndUseChart from "./ReportCards/CurrentAnnualEndUseChart";
import AnnualCarbonEmissionsChart from "./ReportCards/AnnualCarbonEmissionsChart";
import AnnualCarbonEmissionsNaturalGasChart from "./ReportCards/AnnualCarbonEmissionsNaturalGasChart";
import AnnualCarbonEmissionsElectricityChart from "./ReportCards/AnnualCarbonEmissionsElectricityChart";
import FutureProjectionCards from "./ReportCards/FutureProjectionCards";

function BaselineReport() {

    
    return (
        <div className="baseline-report">
            <Header size="medium">
                Your buildings at a glance - {CURRENT_YEAR}
            </Header>
            <div className="cards-and-chart-grid">
                <div className="cards">
                    <CurrentAnnualCarbonEmissionsCard />
                    <CurrentAnnualEnergyConsumptionCard />
                    <TotalAnnualOperatingCostCard />
                </div>
                <ByBuildingBarChart />
            </div>
            <EnergyAndEmissionIntensityChart />
            <div className="side-by-side-charts-grid">
                <CurrentAnnualEndUseChart />
                <EmissionIntensityMap />
            </div>
            <Header size="medium">Future projection</Header>
            <div className="cards-and-chart-grid">
                <FutureProjectionCards />
                <AnnualCarbonEmissionsChart />
            </div>
            <div className="side-by-side-charts-grid">
                <AnnualCarbonEmissionsNaturalGasChart />
                <AnnualCarbonEmissionsElectricityChart />
            </div>
        </div>
    );
}

export default BaselineReport;
