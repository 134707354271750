import { useEffect } from "react";
import { Tab } from "utils/enums";

interface ViewTabsProps {
    customPlans: any[];
    searchParams: URLSearchParams;
    setCurrentActiveTab: (tab: string) => void;
    setSearchParams: (params: any, options?: any) => void;
    setCurrentCustomPlanDetails: (plan: any) => void;
    setIsCustomPlanOpen: (isOpen: boolean) => void;
}

export const useCustomPlanState = ({
    customPlans, 
    searchParams, 
    setCurrentActiveTab, 
    setSearchParams,
    setCurrentCustomPlanDetails,
    setIsCustomPlanOpen,
} :ViewTabsProps) => {

    useEffect(() => {
        const currentParams = Object.fromEntries(searchParams);
        const tabParam = currentParams.tab;
        const customPlanParam = window.location.pathname.match(/customPlan=([^/]+)/)?.[1];

        
        if (!customPlanParam) return; 


        // Check if the tab param matches any custom plan UIDs
        const matchingCustomPlan = customPlans.find(
            plan => plan?.id === customPlanParam
        );
    
        if (matchingCustomPlan) {
            // Making sure plan is valid for TS typing
            const validPlan = {
                ...matchingCustomPlan.plan,
                carbonReductionMeasures: (
                    matchingCustomPlan.plan.carbonReductionMeasures as CarbonReductionMeasure[]
                )?.filter(
                    (measure): measure is CarbonReductionMeasure => measure != null
                ) || [],
            };
            
            setCurrentCustomPlanDetails({
                id: matchingCustomPlan.id,
                plan: validPlan,
                name: matchingCustomPlan.name,
            });

            setCurrentActiveTab(tabParam);
            setIsCustomPlanOpen(true);
            
            if (currentParams.tab !== tabParam) {
                setSearchParams(
                    { ...currentParams, tab: tabParam },
                    { replace: true }
                );
            }
        } else if (
            tabParam && [Tab.Baseline,Tab.Decarbonization, Tab.Projects].includes(tabParam as Tab)
        ) {
            setCurrentActiveTab(tabParam);
            setIsCustomPlanOpen(false);
            
            if (currentParams.tab !== tabParam) {
                setSearchParams(
                    { ...currentParams, tab: tabParam },
                    { replace: true }
                );
            }
        }
    }, [searchParams, customPlans]);

    
}