import { useEffect, useState } from "react";

// Components
import { ComponentErrorBoundary } from "components/molecules/ErrorStates";
import { useFormattedCarbonReductionData } from "pages/BuildingPage/Helper/BuildingPage.helper";

// hooks
import posthog from "posthog-js";
import CsvDownloadButton from "react-json-to-csv";

// styling
import { ExportIcon } from "components/atoms/Icon";
import { Paragraph } from "components/atoms/Typography";
import errorFallbackUI from "components/molecules/ErrorFallbackUI.tsx/ErrorFallbackUI";
import { Table } from "components/molecules/Table";
import { useBuildingModel } from "hooks/Building/useBuildingModel";
import { useBuildingModelUid } from "hooks/useBuildingModelUid";
import useBuildingLoadingState from "recoilStore/useBuildingLoadingState";
import { FinancialImpact } from "./Cards/FinancialImpact";
import PerformanceImpact from "./Cards/PerformanceImpact";
import ProjectReportHeader from "./Cards/ProjectReportHeader";
import "./ProjectsReport.scss";

interface ProjectsReportProps{
    carbonReductionMeasureTypeUrl?: string | null,
    carbonReductionMeasureCategoryUrl?: string | null
}

function ProjectsReport({
    carbonReductionMeasureTypeUrl,
    carbonReductionMeasureCategoryUrl
}: ProjectsReportProps) {
    const buildingModelUid = useBuildingModelUid();
    const { loadingState } = useBuildingLoadingState(buildingModelUid);

    const { data: buildingModel } = useBuildingModel(buildingModelUid);

    useEffect(() => {
        if (loadingState.buildingReportsLoading) {
            setActiveProjectName(undefined);
        }
    }, [loadingState.buildingReportsLoading]);

    const [activeProjectName, setActiveProjectName] = 
        useState<string | undefined>(carbonReductionMeasureTypeUrl ?? undefined);
    const [activeProjectCategory, setActiveProjectCategory] =
        useState<string | undefined>(carbonReductionMeasureCategoryUrl ?? undefined);


    const handleRowClick = (row: BuildingProjectTableRow) => {
        setActiveProjectName(row.carbonReductionMeasureType);
        setActiveProjectCategory(row.carbonReductionMeasureCategory);
        posthog.capture("projects_table_row_clicked", {
            project: row.carbonReductionMeasureType,
        });
    };

    const buildingModelFetched = buildingModel?.buildingModel;

    const {
        tableColumns,
        dataSource
    } = useFormattedCarbonReductionData(
            (buildingModelFetched?.recommendedCrp?.carbonReductionMeasures ?? [])
                .filter((measure) => measure !== null) as CarbonReductionMeasure[],
            buildingModelFetched?.grossFloorArea ?? 1
        )
    
    if (!dataSource || !tableColumns) return null;

    if (activeProjectName && activeProjectCategory)
        return (
            <ProjectReport
                handleBackClick={() => setActiveProjectName(undefined)}
                carbonReductionMeasureType={activeProjectName}
                carbonReductionMeasureCategory={activeProjectCategory}
            />
        );

    return (
        <div className="projects-report">
            <ComponentErrorBoundary
                fallback={errorFallbackUI("tableError")}
                originComponent="ProjectsReport"
            >
                <CsvDownloadButton
                    data={dataSource}
                    filename="buildingProjectsData.csv"
                    className="projects-report-export-button"
                    delimiter=","
                >
                    <div className="measures-page-flex-container">
                        <ExportIcon />
                        <Paragraph>Export</Paragraph>
                    </div>
                </CsvDownloadButton>
                <Table
                    dataSource={dataSource}
                    columns={tableColumns}
                    className="projects-report-table"
                    pagination={false}
                    onRowClick={handleRowClick}
                />
            </ComponentErrorBoundary>
        </div>
    );
}

interface ProjectReportProps {
    handleBackClick: () => void;
    carbonReductionMeasureType: string;
    carbonReductionMeasureCategory: string;
}

export function ProjectReport({
    handleBackClick,
    carbonReductionMeasureType,
    carbonReductionMeasureCategory,
}: ProjectReportProps) {
    return (
        <div className="project-report">
            <ProjectReportHeader
                carbonReductionMeasureType={carbonReductionMeasureType}
                handleBackClick={handleBackClick}
            />
            <FinancialImpact
                carbonReductionMeasureType={carbonReductionMeasureType}
            />
            <PerformanceImpact
                carbonReductionMeasureCategory={carbonReductionMeasureCategory}
                carbonReductionMeasureType={carbonReductionMeasureType}
            />
        </div>
    );
}

export default ProjectsReport;
