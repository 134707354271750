import { EnergyTypeInferredIcon } from "components/molecules/EnergyTypeInferredIcon/EnergyTypeInferredIcon";
import { Header, Paragraph } from "components/atoms/Typography";
import { Tag } from "pages/BuildingPage/BuildingTags";
import DataCoverage from "components/molecules/DataCoverage";
import posthog from "posthog-js";
import {
    BUILDING_COLOURS,
    CURRENT_YEAR,
    PER_YEAR,
    buildingTypeOptions,
    energyTypeOptions,
} from "./constants";

const FORMATTING_ERROR_VALUE = 'n/a';

const returnFormattingError = (value: any, error: any, functionName: string) => {
    posthog.capture("Error", {
        error: error.toString(),
    });

    console.log(error);
    console.log("when formatting value in %s: %s", functionName, value)
    return FORMATTING_ERROR_VALUE
};

const buildTooltipNode = (introText: string, howToReadText: string) => (
    <div style={{ display: "grid", gap: "24px" }}>
        <Paragraph size="small">{introText}</Paragraph>
        <div style={{ display: "grid", gap: "12px" }}>
            <Header size="x-small" style={{ color: "var(--audette-gray-500)" }}>
                How to read
            </Header>
            <Paragraph size="small">{howToReadText}</Paragraph>
        </div>
    </div>
);

export const calculateRoundedPercentageFromRatio = (ratio: number) =>
    Math.round(ratio * 100);

export const formatAbatementCost = (value: number) =>
    formatNumberWithCommas(value, 0);

export const getCarbonEmissionIntensityColour = (
    carbonEmissionIntensity: number
) => {
    if (carbonEmissionIntensity >= 2.4868412767468726)
        return BUILDING_COLOURS.high;
    if (carbonEmissionIntensity >= 1.7732741126584752)
        return BUILDING_COLOURS.mid;
    return BUILDING_COLOURS.low;
};

export const getDebtToValueRatioColour = (debtToValueRatio: number) => {
    if (debtToValueRatio > 0.05) return BUILDING_COLOURS.high;
    if (debtToValueRatio > 0.02) return BUILDING_COLOURS.mid;
    return BUILDING_COLOURS.low;
};

export const formatRatio = (value: number) => {
    try {
        return value.toFixed(2).toLowerCase();
    } catch (error) {
        return returnFormattingError(value, error, "formatRatio");
    }
}

export const formatRatioAsPercentage = (
    value: number,
    decimalPlaces: number = 2
) => {
    try {
        return parseFloat((value * 100).toFixed(decimalPlaces)).toLocaleString();
    } catch (error) {
        return returnFormattingError(value, error, "formatRatioAsPercentage");
    }
}

const formatTags = (value: string[] | string) => {
    try {
        if (Array.isArray(value)) {
            const numberOfTags = value.length;
            const maxTagsToShow = 4;
            return (
                <div
                    style={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: "4px",
                    }}
                >
                    {value.slice(0, maxTagsToShow).map((tag) => (
                        <Tag key={tag} value={tag} />
                    ))}
                    {numberOfTags > maxTagsToShow && (
                        <Tag
                            key={numberOfTags}
                            value={`+${numberOfTags - maxTagsToShow}`}
                        />
                    )}
                </div>
            );
        }
        return <Tag key={value} value={value} />;
    } catch (error) {
        return returnFormattingError(value, error, "formatTags");
    }

};

export const formatPercentage = (value: number) => {
    try {
        const formattedValue = value.toFixed(0).toLocaleString();
        if (formattedValue === "-0") return "0";
        return formattedValue;
    } catch (error) {
        return returnFormattingError(value, error, "formatPercentage");
    }
};

export const formatNumberWithCommas = (
    value: number,
    decimalPlaces: number
) => {
    try {
        const formattedValue = parseFloat(
            value.toFixed(decimalPlaces)
        ).toLocaleString(undefined, { minimumFractionDigits: decimalPlaces });
        if (formattedValue === "-0") return "0";
        return formattedValue;
    } catch (error) {
        return returnFormattingError(value, error, "formatNumberWithCommas");
    }
};

export const assembleBuildingAddress = (location: Location) => {
    try {
        const addressComponents = [
            location.streetAddress,
            location.city,
            location.stateProvince,
            location.postalZipCode,
            location.country,
        ].filter((i) => i !== null);
        return addressComponents.join(", ");
    } catch (error) {
        return returnFormattingError(location, error, "assembleBuildingAddress");
    }
};

const formatUtilityRate = (value: number | null) => {
    try {
        if (value === null) return "";
        return value.toFixed(3).toLocaleString();
    } catch (error) {
        return returnFormattingError(value, error, "formatUtilityRate");
    }
};

const formatLocationAttribute = (attribute: string | null) => {
    try {
        return attribute || "-";
    } catch (error) {
        return returnFormattingError(attribute, error, "formatLocationAttribute");
    }
}

const getMonetaryUnit = (value: number, key?: string) => {
    const thousand = 1000;
    const million = 1000000;
    const billion = 1000000000;

    const absValue = Math.abs(value);

    if (absValue >= billion) return "B\xa0$";
    if (absValue >= million) return "k\xa0$"; // swapped M for k for standardization
    if (absValue >= thousand) return "k\xa0$";

    if (key === "totalMeasureCostIntensity" || key === "netPresentValueIntensity") return "$";

    return "k\xa0$";
};

const formatCostFromUnit = (unit: string, showCents: boolean) => {
    const thousand = 1000;
    const million = 1000000;
    const billion = 1000000000;

    const newUnit = unit.replace(PER_YEAR, "");

    return (value: number, showDecimals: boolean = true) => {
        try {
            let numberOfDecimals = showDecimals ? 2 : 0;

            if (newUnit === "B\xa0$")
                return formatNumberWithCommas(value / billion, numberOfDecimals);
            if (newUnit === "M\xa0$")
                return formatNumberWithCommas(value / million, numberOfDecimals);
            if (newUnit === "k\xa0$"){
                if (value > 1000 || value < 0.1) numberOfDecimals = 0;
                return formatNumberWithCommas(value / thousand, numberOfDecimals);
            }

            return formatNumberWithCommas(value, showCents ? 2 : 0);
        } catch (error) {
            return returnFormattingError(value, error, "formatCostFromUnit");
        }
    };
};

const formatCostIntensityFromUnit = (unit: string) =>
    formatCostFromUnit(unit, true);

const formatMonetaryValueFromUnit = (unit: string) =>
    formatCostFromUnit(unit, false);

const getGrossFloorAreaUnit = (value: number) => {
    const million = 1000000;
    const billion = 1000000000;

    const absValue = Math.abs(value);

    if (absValue >= billion) return "Bft²";
    if (absValue >= million) return "Mft²";

    return "ft²";
};

const formatGrossFloorAreaFromUnit = (unit: string) => {
    const million = 1000000;
    const billion = 1000000000;

    return (value: number, showDecimals: boolean = true) => {
        try {
            let numberOfDecimals = showDecimals ? 2 : 0;

            if (unit.includes("B"))
                return formatNumberWithCommas(value / billion, numberOfDecimals);
            if (unit.includes("M"))
                return formatNumberWithCommas(value / million, numberOfDecimals);

            numberOfDecimals = 0; // No decimals for ft²
            return formatNumberWithCommas(value, numberOfDecimals);
        } catch (error) {
            return returnFormattingError(value, error, "formatGrossFloorAreaFromUnit");
        }
    };
};

const formatMonetaryValue = (value: number) => formatNumberWithCommas(value, 2);

export const formatEmissions = (emissions: number) =>
    formatNumberWithCommas(parseFloat(emissions.toPrecision(3)), 1);

export const formatIntensity = (intensity: number) =>
    formatNumberWithCommas(intensity, 4);

const formatCostIntensity = (intensity: number) =>
    formatNumberWithCommas(intensity, 2);

export const formatEnergy = (energy: number) =>
    formatNumberWithCommas(energy, 0);

const formatEnergyFromUnit = (unit: string) => {
    const thousand = 1000;
    const million = 1000000;
    const billion = 1000000000;
    const trillion = 1000000000000;

    const newUnit = unit.replace(PER_YEAR, "");

    return (value: number, showDecimals = true) => {
        try {
            const numberOfDecimals = showDecimals ? 2 : 0;

            if (newUnit === "kWh")
                return formatNumberWithCommas(value, numberOfDecimals);
            if (newUnit === "MWh")
                return formatNumberWithCommas(
                    (value * thousand) / million,
                    numberOfDecimals
                );
            if (newUnit === "GWh")
                return formatNumberWithCommas(
                    (value * thousand) / billion,
                    numberOfDecimals
                );
            if (newUnit === "TWh")
                return formatNumberWithCommas(
                    (value * thousand) / trillion,
                    numberOfDecimals
                );
            return formatNumberWithCommas(value, numberOfDecimals);
        } catch (error) {
            return returnFormattingError(value, error, "formatEnergyFromUnit");
        }
    };
};

const getEnergyUnit = (value: number) => {
    const absValue = Math.abs(value);

    const million = 1000000;
    const billion = 1000000000;
    const trillion = 1000000000000;
    const thousand = 1000;

    if (absValue * thousand >= trillion) return "TWh"; // divide by thousand because raw value is always kWh
    if (absValue * thousand >= billion) return "GWh";
    if (absValue * thousand >= million) return "MWh";
    return "kWh";
};

const getEmissionsUnit = (value: number) => {
    const kTonnes = 1000;
    const MTonnes = 1000000;
    const gTonnes = 1000000000;

    const absValue = Math.abs(value);

    if (absValue >= gTonnes) return "GtCO₂e";
    if (absValue >= MTonnes) return "MtCO₂e";
    if (absValue >= kTonnes) return "tCO₂e"; // Initially ktCo2e, changed to tCO₂e for standardization accross app
    return "tCO₂e";
};

const formatEmissionsFromUnit = (unit: string) => {
    const kTonnes = 1000;
    const MTonnes = 1000000;
    const gTonnes = 1000000000;

    const newUnit = unit.replace(PER_YEAR, "");

    return (value: number, showDecimals: boolean = true) => {
        const numberOfDecimals = showDecimals ? 2 : 0;

        if (newUnit === "tCO₂e") return formatNumberWithCommas(value, 0); //  UX requirement: don't show decimals for this unit
        if (newUnit === "ktCO₂e")
            return formatNumberWithCommas(value / kTonnes, numberOfDecimals);
        if (newUnit === "MtCO₂e")
            return formatNumberWithCommas(value / MTonnes, numberOfDecimals);
        if (newUnit === "GtCO₂e")
            return formatNumberWithCommas(value / gTonnes, numberOfDecimals);
        return formatNumberWithCommas(value, numberOfDecimals);
    };
};

export const formatGrossFloorArea = (grossFloorArea: number) =>
    formatNumberWithCommas(parseFloat(grossFloorArea.toPrecision(2)), 0);

export const formatReturnRatio = (returnRatio: number) =>
    formatRatioAsPercentage(returnRatio, 0);

export const formatBuildingArchetype = (buildingArchetype: string) => {
    try {
        return buildingTypeOptions.filter((x) => x.key === buildingArchetype)[0]?.label ||
        "Unknown";
    } catch (error) {
        return returnFormattingError(buildingArchetype, error, "formatBuildingArchetype");
    }
}

export const formatEnergyType = (energyType: EnergyTypeEnum) => {
    try {
        const label =
            energyTypeOptions.filter((x) => x.key === energyType)[0]?.label ||
            "Unknown";

        if (energyType.toString().includes("inferred"))
            return <EnergyTypeInferredIcon label={label} />;

        return label;
    } catch (error) {
        return returnFormattingError(energyType, error, "formatEnergyType");
    }
};

export const formatMeasureCoverage = (
    measureCoverage: number,
    numberOfBuildings: number
) => {
    try {
        return `${measureCoverage} (${(
            (measureCoverage / numberOfBuildings) *
            100
        ).toFixed()}%)`;
    } catch (error) {
        return returnFormattingError(numberOfBuildings, error, "formatMeasureCoverage");
    }
}

const formatUnit = (unit: string) => {
    if (unit === "kwh") return "kWh";
    if (unit === "gj") return "GJ";
    if (unit === "m3") return "m³";
    if (unit === "ft3") return "ft³";
    return unit;
};

export const formatSimplePayback = (simplePayback: number) => {
    try {
        const payback = Math.ceil(simplePayback);
        if (payback > 50) return "50+";
        if (payback < 0) return "None";
        return payback;
    } catch (error) {
        return returnFormattingError(simplePayback, error, "formatSimplePayback");
    }
}

const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];

// accepts date in format yyyy-mm-dd
const formatDate = (date: string, long: boolean = true) => {
    try {
        const splitDate = date.split("-");
        const year = splitDate[0];
        const month = Number(splitDate[1]);
        const day = splitDate[2];

        if (long) return `${day} ${months[month - 1]}, ${year}`;
        return `${day}/${month}/${year}`;
    } catch (error) {
        return returnFormattingError(date, error, "formatDate");
    }
};

export const formatIsoToDateString = (iso: string) => {
    try {
        const newDate = new Date(iso);
        return newDate.toLocaleDateString("en-uk", {
            day: "numeric",
            month: "numeric",
            year: "numeric",
        });
    } catch (error) {
        return returnFormattingError(iso, error, "formatIsoToDateString");
    }
};

const formatIsoToDatetimeString = (iso: string) => {
    try {
        const newDate = new Date(iso);
        return newDate.toLocaleDateString("en-uk", {
            month: "long",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
        });
    } catch (error) {
        return returnFormattingError(iso, error, "formatIsoToDatetimeString");
    }

};

const formatLatitudeLongitude = (v: number) => formatNumberWithCommas(v, 5);

export const formatYearApplied = (yearApplied: number) => {
    try {
        return Math.ceil(yearApplied);
    } catch (error) {
        return returnFormattingError(yearApplied, error, "formatYearApplied");
    }
}

export const formatDebtToValueRatio = (debtToValueRatio: number) => {
    try {
       return (debtToValueRatio * 100).toFixed();
    } catch (error) {
        return returnFormattingError(debtToValueRatio, error, "formatDebtToValueRatio");
    }
}

const formatBuildingAttribute = (attribute: number) => {
    try {
        return parseFloat(attribute.toFixed(2)).toLocaleString();
    } catch (error) {
        return returnFormattingError(attribute, error, "formatBuildingAttribute");
    }
}

const formatUtilityType = (attribute: string) => attribute
        .split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');


export const formatRatioForDisplay = (
    value: number | string,
    propertyKey: keyof any
) => {
    try {
        if (typeof value === "string") return value;
        if (ratioKeys.includes(propertyKey)) return formatRatioAsPercentage(value);
        return value;
    } catch (error) {
        return returnFormattingError(value, error, "formatRatioForDisplay");
    }
};

export const formatRatioForAPI = (
    value: number,
    buildingPropertyKey: keyof BuildingProperties
) => {
    try {
        if (ratioKeys.includes(buildingPropertyKey))
            return formatPercentageAsRatio(value);
        return value;
    } catch (error) {
        return returnFormattingError(value, error, "formatRatioForAPI");
    }
};

const formatBuildingName = (name: string) => {
    try {
        if (name.length < 1) return name;
        return name[0].toUpperCase() + name.slice(1);
    } catch (error) {
        return returnFormattingError(name, error, "formatBuildingName");
    }
};

const ratioKeys: (keyof any)[] = [
    "availableRoofAreaRatio",
    "ledInstalledRatio",
];

const formatPercentageAsRatio = (value: number) => {
    try {
        return parseFloat((Number(value) * 0.01).toPrecision(12));
    } catch (error) {
        return returnFormattingError(value, error, "formatPercentageAsRatio");
    }
}

const validateYear = (year: number) => year >= 1900 && year <= CURRENT_YEAR;
const validatePercentage = (perc: number) => perc >= 0 && perc <= 100;

export const semanticTermToDisplayInfo: {
    [key: string]: DisplayInfo;
} = {
    accountNumber: {
        humanReadable: "Account number",
    },
    annualCarbonEmissionIntensityCurrent: {
        humanReadable: "Emissions intensity",
        unit: "kg\xa0CO₂e/ft²/yr",
        formatFunction: formatIntensity,
        placeholder: "Enter amount",
        tooltip:
            "The annual emissions of a building in the first year of the plan normalized by gross floor area.",
    },
    annualCarbonEmissionsCurrent: {
        humanReadable: "Annual carbon emissions",
        unit: "tCO₂e",
        formatFunction: formatEmissions,
        getUnit: getEmissionsUnit,
        formatFromUnit: formatEmissionsFromUnit,
        placeholder: "Enter amount",
    },
    annualCarbonEmissionSavingsElectricity: {
        humanReadable: "Emission savings electricity",
        unit: `tCO₂e${PER_YEAR}`,
        formatFunction: formatEmissions,
        getUnit: (value: number) => `${getEmissionsUnit(value)}${PER_YEAR}`,
        formatFromUnit: formatEmissionsFromUnit,
        tooltip:
            "The average electricity emission savings expected after project implementation, accounting for future grid projections.",
    },
    annualCarbonEmissionSavingsNaturalGas: {
        humanReadable: "Emission savings natural gas",
        unit: `tCO₂e${PER_YEAR}`,
        formatFunction: formatEmissions,
        getUnit: (value: number) => `${getEmissionsUnit(value)}${PER_YEAR}`,
        formatFromUnit: formatEmissionsFromUnit,
        tooltip:
            "The average natural gas emission savings expected after project implementation, accounting for future grid projections.",
    },
    annualCarbonEmissionSavingsSteam: {
        humanReadable: "Emission savings steam",
        unit: `tCO₂e${PER_YEAR}`,
        formatFunction: formatEmissions,
        getUnit: (value: number) => `${getEmissionsUnit(value)}${PER_YEAR}`,
        formatFromUnit: formatEmissionsFromUnit,
        tooltip:
            "The average steam emission savings expected after project implementation, accounting for future grid projections.",
    },
    annualCarbonEmissionSavingsTotal: {
        humanReadable: "Emission savings total",
        unit: `tCO₂e${PER_YEAR}`,
        formatFunction: formatEmissions,
        getUnit: (value: number) => `${getEmissionsUnit(value)}${PER_YEAR}`,
        formatFromUnit: formatEmissionsFromUnit,
        tooltip:
            "The average total emission savings expected after project implementation, accounting for future grid projections.",
    },
    annualCarbonEmissionSavings: {
        humanReadable: "Emission savings total",
        unit: `tCO₂e${PER_YEAR}`,
        formatFunction: formatEmissions,
        getUnit: (value: number) => `${getEmissionsUnit(value)}${PER_YEAR}`,
        formatFromUnit: formatEmissionsFromUnit,
        tooltip:
            "The average total emission savings expected after project implementation, accounting for future grid projections.",
    },
    annualCarbonEmissionSavings2050: {
        humanReadable: "Annual carbon emission savings in 2050",
        unit: "tCO₂e",
        formatFunction: formatEmissions,
        getUnit: getEmissionsUnit,
        formatFromUnit: formatEmissionsFromUnit,
    },
    annualCarbonTaxIntensityCurrent: {
        humanReadable: "Carbon tax intensity",
        unit: "$/ft²/yr",
        formatFunction: formatCostIntensity,
        tooltip: "The average annual carbon tax cost normalized by floor area.",
    },
    annualCarbonTaxSavings: {
        humanReadable: "Carbon tax savings",
        unit: `$${PER_YEAR}`,
        formatFunction: formatMonetaryValue,
        getUnit: (value: number) => `${getMonetaryUnit(value)}${PER_YEAR}`,
        formatFromUnit: formatMonetaryValueFromUnit,
        tooltip:
            "The average annual carbon tax savings expected after project implementation.",
    },
    annualEnergyCostSavings: {
        humanReadable: "Utility savings",
        unit: `$${PER_YEAR}`,
        getUnit: (value: number) => `${getMonetaryUnit(value)}${PER_YEAR}`,
        formatFromUnit: formatMonetaryValueFromUnit,
        formatFunction: formatMonetaryValue,
        tooltip:
            "The average annual utility savings expected after project implementation.",
    },
    annualEnergyConsumptionCurrent: {
        humanReadable: "Annual energy consumption",
        unit: "kWh",
        getUnit: getEnergyUnit,
        formatFromUnit: formatEnergyFromUnit,
        formatFunction: formatEnergy,
        placeholder: "Enter amount",
    },
    annualEnergyConsumptionSavingsElectricity: {
        humanReadable: "Energy savings electricity",
        unit: `kWh${PER_YEAR}`,
        getUnit: (value: number) => `${getEnergyUnit(value)}${PER_YEAR}`,
        formatFromUnit: formatEnergyFromUnit,
        formatFunction: formatEnergy,
        tooltip:
            "The annual energy savings from electricity expected after project implementation.",
    },
    annualEnergyConsumptionSavingsNaturalGas: {
        humanReadable: "Energy savings natural gas",
        unit: `kWh${PER_YEAR}`,
        getUnit: (value: number) => `${getEnergyUnit(value)}${PER_YEAR}`,
        formatFromUnit: formatEnergyFromUnit,
        formatFunction: formatEnergy,
        tooltip:
            "The annual energy savings from natural gas expected after project implementation.",
    },
    annualEnergyConsumptionSavingsSteam: {
        humanReadable: "Energy savings steam",
        unit: `kWh${PER_YEAR}`,
        getUnit: (value: number) => `${getEnergyUnit(value)}${PER_YEAR}`,
        formatFromUnit: formatEnergyFromUnit,
        formatFunction: formatEnergy,
        tooltip:
            "The annual energy savings from steam expected after project implementation.",
    },
    annualEnergyConsumptionSavingsTotal: {
        humanReadable: "Energy savings total",
        unit: `kWh${PER_YEAR}`,
        getUnit: (value: number) => `${getEnergyUnit(value)}${PER_YEAR}`,
        formatFromUnit: formatEnergyFromUnit,
        formatFunction: formatEnergy,
        tooltip:
            "The annual total energy savings expected after project implementation.",
    },
    annualEnergyConsumptionSavings: {
        humanReadable: "Energy savings total",
        unit: `kWh${PER_YEAR}`,
        getUnit: (value: number) => `${getEnergyUnit(value)}${PER_YEAR}`,
        formatFromUnit: formatEnergyFromUnit,
        formatFunction: formatEnergy,
        tooltip:
            "The annual total energy savings expected after project implementation.",
    },
    annualEnergyUseIntensityCurrent: {
        humanReadable: "Energy intensity",
        unit: "kWh/ft²/yr",
        formatFunction: formatIntensity,
        tooltip:
            "The annual energy consumption of a building in the first year of the plan normalized by gross floor area.",
    },
    annualUtilityCostIntensityCurrent: {
        humanReadable: "Utility cost intensity",
        unit: "$/ft²/yr",
        formatFunction: formatCostIntensity,
        tooltip: "The average annual utility cost normalized by floor area.",
    },
    annualUtilityCostSavings2050: {
        humanReadable: "Annual utility cost savings in 2050",
        unit: "$",
        getUnit: getMonetaryUnit,
        formatFromUnit: formatMonetaryValueFromUnit,
        formatFunction: formatMonetaryValue,
    },
    annualMeanCarbonEmissionSavings: {
        humanReadable: "Annual carbon emissions savings",
        unit: "tCO₂e",
        getUnit: getEmissionsUnit,
        formatFromUnit: formatEmissionsFromUnit,
        formatFunction: formatEmissions,
    },
    annualUtilityCostSavingsElectricity: {
        humanReadable: "Annual utility cost savings electricity",
        unit: "$",
        getUnit: getMonetaryUnit,
        formatFromUnit: formatMonetaryValueFromUnit,
        formatFunction: formatMonetaryValue,
    },
    annualUtilityCostSavingsNaturalGas: {
        humanReadable: "Annual utility cost savings natural gas",
        unit: "$",
        getUnit: getMonetaryUnit,
        formatFromUnit: formatMonetaryValueFromUnit,
        formatFunction: formatMonetaryValue,
    },
    annualUtilityCostSavingsSteam: {
        humanReadable: "Annual utility cost savings steam",
        unit: "$",
        getUnit: getMonetaryUnit,
        formatFromUnit: formatMonetaryValueFromUnit,
        formatFunction: formatMonetaryValue,
    },
    annualUtilityCostSavingsTotal: {
        humanReadable: "Annual utility cost savings total",
        unit: "$",
        getUnit: getMonetaryUnit,
        formatFromUnit: formatMonetaryValueFromUnit,
        formatFunction: formatMonetaryValue,
    },
    annualMeanUtilityCostSavingsTotal: {
        humanReadable: "Annual utility cost savings total",
        unit: "$",
        getUnit: getMonetaryUnit,
        formatFromUnit: formatMonetaryValueFromUnit,
        formatFunction: formatMonetaryValue,
    },
    annualMeanUtilityCostSavings: {
        humanReadable: "Annual utility cost savings total",
        unit: "$",
        getUnit: getMonetaryUnit,
        formatFromUnit: formatMonetaryValueFromUnit,
        formatFunction: formatMonetaryValue,
    },
    availableRoofAreaRatio: {
        humanReadable: "Available area",
        unit: "%",
        description:
            "The percentage of the total roof area that is unobstructed (ie. free from equipment, parapets)",
        formatFunction: formatRatioAsPercentage,
        errorMessage: "Must be a valid percentage between 0 and 100",
        isValidFn: validatePercentage,
    },
    averageRate: {
        humanReadable: "Average rate",
        formatFunction: formatUtilityRate,
        unit: "$/kWh",
    },
    averageMarginalAbatementCost: {
        humanReadable: "Average marginal abatement cost",
        unit: "$/tCO₂e saved",
        formatFunction: formatAbatementCost,
        tooltip: buildTooltipNode(
            "The negative NPV $ divided by the lifetime carbon emission savings of the proposed project.",
            "A negative value means the project offers a net benefit, where benefits exceed costs. Contrary, a positive value indicates a net cost, where project costs exceed benefits."
        ),
    },
    buildingArchetype: {
        humanReadable: "Building type",
        formatFunction: formatBuildingArchetype,
        placeholder: "type",
    },
    buildingCount: {
        humanReadable: "Building count",
    },
    buildingHeight: {
        humanReadable: "Building height",
        unit: "ft",
        formatFunction: formatBuildingAttribute,
        placeholder: "Enter height",
        errorMessage: "Floor area should be a positive number",
        isValidFn: (area: number) => area > 0,
    },
    buildingModelUid:{
        humanReadable: "Building model UID",
    },
    buildingName: {
        humanReadable: "Building name",
        formatFunction: formatBuildingName,
        placeholder: "Enter name",
    },
    calendarYear: {
        humanReadable: "Year",
    },
    carbonEmissions: {
        humanReadable: "Total annual carbon emissions",
        unit: "tCO₂e",
        getUnit: getEmissionsUnit,
        formatFromUnit: formatEmissionsFromUnit,
        formatFunction: formatEmissions,
    },
    carbonEmissionIntensity: {
        humanReadable: "Average emission intensity",
        unit: "kg\xa0CO₂e/ft²",
        formatFunction: formatIntensity,
        placeholder: "25",
    },
    carbonEmissionReductionPotential: {
        humanReadable: "Emissions reduction potential ",
        unit: "%",
        formatFunction: (v: number) => formatRatioAsPercentage(v, 0),
        tooltip:
            "Emissions reduction potential by 2050 compared to the baseline scenario, assuming all projects are implemented.",
    },
    carbonReductionMeasureCategory: {
        humanReadable: "Measure category",
    },
    carbonReductionMeasureLife: {
        humanReadable: "Lifetime",
        unit: "yrs",
    },
    carbonReductionMeasureSize: {
        humanReadable: "Sizing",
        formatFunction: (v: number) => v.toFixed(0),
    },
    carbonReductionMeasureType: {
        humanReadable: "Measure name",
    },
    carbonReductionMeasureUnitCost: {
        humanReadable: "Unit cost",
        unit: "$",
        formatFunction: (v: number) => v.toFixed(0),
    },
    carbonTax: {
        humanReadable: "Carbon tax",
        unit: "$",
        getUnit: getMonetaryUnit,
        formatFromUnit: formatMonetaryValueFromUnit,
        formatFunction: formatMonetaryValue,
    },
    carbonTaxIntensity: {
        humanReadable: "Average carbon tax intensity cost",
        unit: "$/ft²",
        formatFunction: formatCostIntensity,
        tooltip: "The average annual carbon tax cost normalized by floor area.",
    },
    carbonTaxSavings: {
        humanReadable: "Carbon tax savings",
        unit: "$",
        getUnit: getMonetaryUnit,
        formatFromUnit: formatMonetaryValueFromUnit,
        formatFunction: formatMonetaryValue,
    },
    cashFlowAnnual: {
        humanReadable: "Net cash flow",
        unit: "$",
        getUnit: getMonetaryUnit,
        formatFromUnit: formatMonetaryValueFromUnit,
        formatFunction: formatMonetaryValue,
    },
    cashFlowAnnualHidden: {
        humanReadable: "Net cash flow",
        formatFunction: () =>  ''
    },
    cashFlowCumulative: {
        humanReadable: "Net cash flow",
        unit: "$",
        getUnit: getMonetaryUnit,
        formatFromUnit: formatMonetaryValueFromUnit,
        formatFunction: formatMonetaryValue,
    },
    createdAt: {
        humanReadable: "Date created",
        formatFunction: formatIsoToDateString,
    },
    currency: {
        humanReadable: "Currency",
        formatFunction: (currency: string) => currency.toUpperCase(),
    },
    city: {
        humanReadable: "City",
        formatFunction: formatLocationAttribute,
        placeholder: "Enter city",
    },
    climateZone: {
        humanReadable: "Climate Zone",
    },
    country: {
        humanReadable: "Country",
        placeholder: "Enter country",
    },
    dataCoverage: {
        humanReadable: "Data coverage",
        formatFunction: (dataCoverage: DataCoverage) => (
            <DataCoverage dataCoverage={dataCoverage} />
        ),
    },
    dataCoverages: {
        humanReadable: "Data coverage",
    },
    energyType: {
        humanReadable: "Energy type",
        formatFunction: formatEnergyType,
    },
    endDate: {
        humanReadable: "End date",
        formatFunction: formatDate,
    },
    debtToValueRatio: {
        humanReadable: "Carbon debt to asset value ratio",
        unit: "%",
        formatFunction: formatDebtToValueRatio,
    },
    decarbonizationPercentage: {
        humanReadable: "Decarbonization",
        unit: "%",
        formatFunction: formatPercentage,
    },
    discountRate: {
        humanReadable: "Discount rate",
        unit: "%",
        formatFunction: formatPercentage,
    },
    energyConsumption: {
        humanReadable: "Total annual energy consumption",
        unit: "kWh",
        getUnit: getEnergyUnit,
        formatFunction: formatEnergy,
        formatFromUnit: formatEnergyFromUnit,
        placeholder: "19,700,000",
    },
    energyConsumptionUnit: {
        humanReadable: "Unit",
        formatFunction: formatUnit,
    },
    energyConsumptionSavings: {
        humanReadable: "Annual utility consumption savings in 2050",
        unit: "kWh",
        getUnit: getEnergyUnit,
        formatFunction: formatEnergy,
        formatFromUnit: formatEnergyFromUnit,
    },
    energyUseIntensity: {
        humanReadable: "Average energy intensity",
        unit: "kWh/ft²",
        formatFunction: formatIntensity,
    },
    floorsAboveGrade: {
        humanReadable: "Floors above grade",
        formatFunction: formatBuildingAttribute,
        placeholder: "Enter floors",
        errorMessage: `Floors above grade should be a valid positive integer`,
        isValidFn: (floors: number) => floors > 0 && Number.isInteger(floors),
    },
    footprintArea: {
        humanReadable: "Footprint area",
        unit: "ft²",
        formatFunction: formatBuildingAttribute,
    },
    fundName: {
        humanReadable: "Fund name",
    },
    grossFloorArea: {
        humanReadable: "Gross floor area",
        unit: "ft²",
        formatFunction: formatGrossFloorArea,
        getUnit: getGrossFloorAreaUnit,
        formatFromUnit: formatGrossFloorAreaFromUnit,
        placeholder: "Enter amount",
        errorMessage: "Floor area should be a positive number",
        isValidFn: (area: number) => area > 0,
    },
    impactPercentage: {
        humanReadable: "Impact",
        unit: "%",
        formatFunction: (value: number) => {
            if (Math.round(value) < 1) return "< 1";
            return formatPercentage(value);
        },
    },
    incrementalCost: {
        humanReadable: "Incremental cost",
        unit: "$",
        getUnit: getMonetaryUnit,
        formatFromUnit: formatMonetaryValueFromUnit,
        formatFunction: formatMonetaryValue,
        tooltip:
            "Incremental cost of the project compared to the baseline cost of the like-for-like replacement excluding installation costs.",
    },
    insertTime: {
        humanReadable: "Entry date",
        formatFunction: formatIsoToDateString,
    },
    internalRateOfReturn: {
        humanReadable: "IRR",
        unit: "%",
        formatFunction: (v: number) => formatRatioAsPercentage(v, 0),
        tooltip: buildTooltipNode(
            "The rate at which the present value of all future cash flows from an investment equals to its initial cost.",
            "If the IRR is greater than the cost of capital, the project is considered potentially profitable."
        ),
    },
    likeForLikeCost: {
        humanReadable: "Like for like cost",
        unit: "$",
        getUnit: getMonetaryUnit,
        formatFromUnit: formatMonetaryValueFromUnit,
        formatFunction: formatMonetaryValue,
        tooltip:
            "Baseline cost of a like-for-like replacement excluding installation costs.",
    },
    lastUpdated: {
        humanReadable: "Last updated",
        formatFunction: formatIsoToDatetimeString,
    },
    latitude: {
        humanReadable: "Latitude",
        placeholder: "latitude",
        errorMessage: "Latitude should be between -90 and 90",
        formatFunction: formatLatitudeLongitude,
        isValidFn: (lat: number) => lat >= -90 && lat <= 90,
    },
    location: {
        humanReadable: "Address",
        formatFunction: assembleBuildingAddress,
    },
    longitude: {
        humanReadable: "Longitude",
        placeholder: "longitude",
        errorMessage: "Longitude should be between -180 and 180",
        formatFunction: formatLatitudeLongitude,
        isValidFn: (lat: number) => lat >= -180 && lat <= 180,
    },
    marginalAbatementCost: {
        humanReadable: "Marginal abatement cost",
        unit: "$/tCO₂e saved",
        formatFunction: formatAbatementCost,
        tooltip: buildTooltipNode(
            "The negative NPV $ divided by the lifetime carbon emission savings of the proposed project.",
            "A negative value means the project offers a net benefit, where benefits exceed costs. Contrary, a positive value indicates a net cost, where project costs exceed benefits."
        ),
    },
    measureCount: {
        humanReadable: "Measure count",
        formatFunction: (count: number) => count,
        unit: "",
    },
    measureCost: {
        humanReadable: "Low carbon upgrade cost",
        unit: "$",
        getUnit: getMonetaryUnit,
        formatFromUnit: formatMonetaryValueFromUnit,
        formatFunction: formatMonetaryValue,
    },
    measureCoverage: {
        humanReadable: "Project coverage by number of buildings",
        formatFunction: formatMeasureCoverage,
    },
    netCashFlow: {
        humanReadable: "Net cash flow",
        unit: "$",
        getUnit: getMonetaryUnit,
        formatFromUnit: formatMonetaryValueFromUnit,
        formatFunction: formatMonetaryValue,
    },
    netPresentValue: {
        humanReadable: "NPV",
        unit: "$",
        getUnit: getMonetaryUnit,
        formatFromUnit: formatMonetaryValueFromUnit,
        formatFunction: formatMonetaryValue,
        tooltip: buildTooltipNode(
            "The sum of all cash flows discounted back to their present value using the discount rate.",
            "If the NPV is positive, it suggests that the investment is expected to generate value and meet the required rate of return."
        ),
    },
    netPresentValueIncremental: {
        humanReadable: "Incremental NPV",
        unit: "$",
        getUnit: getMonetaryUnit,
        formatFromUnit: formatMonetaryValueFromUnit,
        formatFunction: formatMonetaryValue,
        tooltip: buildTooltipNode(
            "The sum of all cash flows discounted back to their present value using the discount rate.",
            "If the NPV is positive, it suggests that the investment is expected to generate value and meet the required rate of return."
        ),
    },
    netPresentValueIntensity: {
        humanReadable: "NPV intensity",
        unit: "$/ft²",
        getUnit: (v: number) => `${getMonetaryUnit(v, "netPresentValueIntensity")}/ft²`,
        formatFromUnit: formatMonetaryValueFromUnit,
        formatFunction: formatMonetaryValue,
        tooltip: buildTooltipNode(
            "NPV normalized by the building's gross floor area.",
            "Positive NPV suggests that the investment is expected to generate value. "
        ),
    },
    netPresentValueTotal: {
        humanReadable: "Total NPV",
        unit: "$",
        getUnit: getMonetaryUnit,
        formatFromUnit: formatMonetaryValueFromUnit,
        formatFunction: formatMonetaryValue,
    },
    numberOfBuildings: {
        humanReadable: "Number of buildings",
        unit: "Buildings",
    },
    numberOfIdentifiedProjects: {
        humanReadable: "Number of identified projects",
    },
    numberOfProjects: {
        humanReadable: "Number of projects",
    },
    operatingCost: {
        humanReadable: "Total energy cost",
        unit: "$",
        getUnit: getMonetaryUnit,
        formatFromUnit: formatMonetaryValueFromUnit,
        formatFunction: formatMonetaryValue,
    },
    postalZipCode: {
        humanReadable: "Postal code",
        placeholder: "Enter postal code",
    },
    propertyArchetype: {
        humanReadable: "Property type",
        formatFunction: formatBuildingArchetype,
        placeholder: "type",
    },
    propertyCount: {
        humanReadable: "Property count",
    },
    propertyName: {
        humanReadable: "Property name",
    },
    presentValue: {
        humanReadable: "Present Value",
        unit: "$",
        getUnit: getMonetaryUnit,
        formatFromUnit: formatMonetaryValueFromUnit,
        formatFunction: formatMonetaryValue,
    },
    returnOnInvestment: {
        humanReadable: "ROI",
        unit: "%",
        formatFunction: (value: number) => formatNumberWithCommas(value, 2),
        tooltip: buildTooltipNode(
            "The lifetime utility cost savings and carbon tax savings divided by the total cost of the project.",
            "A positive ROI indicates that the project has generated utility savings.  A negative ROI indicates that the project has resulted in increased utility costs."
        ),
    },
    roofArea: {
        humanReadable: "Area",
        unit: "ft²",
        formatFunction: formatBuildingAttribute,
    },
    roofInstallationYear: {
        humanReadable: "Installation year",
        errorMessage: `Must be between 1900 and ${CURRENT_YEAR}`,
        isValidFn: validateYear,
    },
    roofRValue: {
        humanReadable: "R value",
        formatFunction: formatBuildingAttribute,
    },
    simplePayback: {
        humanReadable: "Simple payback",
        unit: "yrs",
        formatFunction: formatSimplePayback,
        tooltip:
            "The number of years after which the money saved from implementing the project will cover the initial investment.",
    },
    simplePaybackAverage: {
        humanReadable: "Simple payback average",
        unit: "yrs",
        formatFunction: formatSimplePayback,
        tooltip:
            "The average number of years after which the money saved from implementing the project will cover the initial investment.",
    },
    simplePaybackYearsIncremental: {
        humanReadable: "Simple payback years incremental"
    },
    simplePaybackYearsTotal: {
        humanReadable: "Average simple payback",
        unit: "yrs",
        formatFunction: formatSimplePayback,
        tooltip:
            "The number of years after which the money saved from implementing the project will cover the initial investment.",
    },
    startDate: {
        humanReadable: "Start date",
        formatFunction: formatDate,
    },
    stateProvince: {
        humanReadable: "State/Province",
        placeholder: "Enter state/province",
    },
    streetAddress: {
        humanReadable: "Address",
        placeholder: "Enter address",
    },
    tags: {
        humanReadable: "Tags",
        formatFunction: formatTags,
    },
    totalAnnualCarbonEmissionsCurrent: {
        humanReadable: "Annual carbon emissions",
        unit: "tCO₂e",
        getUnit: getEmissionsUnit,
        formatFromUnit: formatEmissionsFromUnit,
        formatFunction: formatEmissions,
    },
    totalAnnualOperatingCost: {
        humanReadable: "Total energy cost",
        unit: "$",
        getUnit: getMonetaryUnit,
        formatFromUnit: formatMonetaryValueFromUnit,
        formatFunction: formatMonetaryValue,
    },
    totalCarbonEmissions: {
        humanReadable: "Total emissions produced",
        unit: "tCO₂e",
        formatFunction: formatEmissions,
        getUnit: getEmissionsUnit,
        formatFromUnit: formatEmissionsFromUnit,
    },
    totalCarbonEmissionsElectricity: {
        humanReadable: "Electricity",
        unit: "tCO₂e",
        formatFunction: formatEmissions,
        getUnit: getEmissionsUnit,
        formatFromUnit: formatEmissionsFromUnit,
    },
    totalCarbonEmissionsNaturalGas: {
        humanReadable: "Natural gas",
        unit: "tCO₂e",
        formatFunction: formatEmissions,
        getUnit: getEmissionsUnit,
        formatFromUnit: formatEmissionsFromUnit,
    },
    totalCarbonEmissionSavings: {
        humanReadable: "Total emission savings",
        unit: "tCO₂e",
        formatFunction: formatEmissions,
        getUnit: getEmissionsUnit,
        formatFromUnit: formatEmissionsFromUnit,
    },
    totalCarbonEmissionSavingsElectricity: {
        humanReadable: "Electricity",
        unit: "tCO₂e",
        formatFunction: formatEmissions,
        getUnit: getEmissionsUnit,
        formatFromUnit: formatEmissionsFromUnit,
    },
    totalCarbonEmissionSavingsNaturalGas: {
        humanReadable: "Natural gas",
        unit: "tCO₂e",
        formatFunction: formatEmissions,
        getUnit: getEmissionsUnit,
        formatFromUnit: formatEmissionsFromUnit,
    },
    totalAnnualCarbonEmissionSavings: {
        humanReadable: "Average annual emission savings",
        unit: "tCO₂e",
        formatFunction: formatEmissions,
        getUnit: getEmissionsUnit,
        formatFromUnit: formatEmissionsFromUnit,
    },
    totalAnnualEnergyCostSavings: {
        humanReadable: "Average annual energy cost savings",
        unit: "$",
        getUnit: getMonetaryUnit,
        formatFromUnit: formatMonetaryValueFromUnit,
        formatFunction: formatMonetaryValue,
    },
    totalAnnualEnergyConsumptionSavings: {
        humanReadable: "Average annual energy savings",
        unit: "kWh",
        getUnit: getEnergyUnit,
        formatFunction: formatEnergy,
        formatFromUnit: formatEnergyFromUnit,
    },
    totalCarbonTaxNoActions: {
        humanReadable: "Projected carbon tax | 2023 - 2050",
        unit: "$",
        getUnit: getMonetaryUnit,
        formatFromUnit: formatMonetaryValueFromUnit,
        formatFunction: formatMonetaryValue,
    },
    totalCarbonTaxSavings: {
        humanReadable: "Carbon tax savings",
        unit: "$",
        getUnit: getMonetaryUnit,
        formatFromUnit: formatMonetaryValueFromUnit,
        formatFunction: formatMonetaryValue,
    },
    totalCostSavings: {
        humanReadable: "Total savings",
        unit: "$",
        getUnit: getMonetaryUnit,
        formatFromUnit: formatMonetaryValueFromUnit,
        formatFunction: formatMonetaryValue,
    },
    totalEnergyCostSavings: {
        humanReadable: "Total savings",
        unit: "$",
        getUnit: getMonetaryUnit,
        formatFromUnit: formatMonetaryValueFromUnit,
        formatFunction: formatMonetaryValue,
    },
    totalEnergyConsumptionSavings: {
        humanReadable: "Total energy savings",
        unit: "kWh",
        getUnit: getEnergyUnit,
        formatFunction: formatEnergy,
        formatFromUnit: formatEnergyFromUnit,
    },
    totalEnergyConsumptionSavingsElectricity: {
        humanReadable: "Electricity",
        unit: "kWh",
        getUnit: getEnergyUnit,
        formatFunction: formatEnergy,
        formatFromUnit: formatEnergyFromUnit,
    },
    totalEnergyConsumptionSavingsNaturalGas: {
        humanReadable: "Natural gas",
        unit: "kWh",
        getUnit: getEnergyUnit,
        formatFunction: formatEnergy,
        formatFromUnit: formatEnergyFromUnit,
    },
    totalEnergyConsumption: {
        humanReadable: "Total consumption",
        unit: "kWh",
        getUnit: getEnergyUnit,
        formatFunction: formatEnergy,
        formatFromUnit: formatEnergyFromUnit,
    },
    totalEnergyConsumptionElectricity: {
        humanReadable: "Electricity",
        unit: "kWh",
        getUnit: getEnergyUnit,
        formatFunction: formatEnergy,
        formatFromUnit: formatEnergyFromUnit,
    },
    totalEnergyConsumptionNaturalGas: {
        humanReadable: "Natural gas",
        unit: "kWh",
        getUnit: getEnergyUnit,
        formatFunction: formatEnergy,
        formatFromUnit: formatEnergyFromUnit,
    },
    totalEnergyUseIntensity: {
        humanReadable: "Energy intensity",
        unit: "kWh/ft²",
        formatFunction: formatIntensity,
    },
    totalIncrementalCost: {
        humanReadable: "Total incremental cost",
        unit: "$",
        getUnit: getMonetaryUnit,
        formatFromUnit: formatMonetaryValueFromUnit,
        formatFunction: formatMonetaryValue,
        tooltip:
            "Incremental cost of the project compared to the baseline cost of the like-for-like replacement excluding installation costs.",
    },
    totalLikeForLikeCost: {
        humanReadable: "Like for like cost",
        unit: "$",
        getUnit: getMonetaryUnit,
        formatFromUnit: formatMonetaryValueFromUnit,
        formatFunction: formatMonetaryValue,
    },
    totalUtilityCostSavingsElectricity: {
        humanReadable: "Electricity savings",
        unit: "$",
        getUnit: getMonetaryUnit,
        formatFromUnit: formatMonetaryValueFromUnit,
        formatFunction: formatMonetaryValue,
    },
    totalUtilityCostSavingsNaturalGas: {
        humanReadable: "Natural gas savings",
        unit: "$",
        getUnit: getMonetaryUnit,
        formatFromUnit: formatMonetaryValueFromUnit,
        formatFunction: formatMonetaryValue,
    },
    totalMeasureCostIntensity: {
        humanReadable: "Cost per ft²",
        unit: "$/ft²",
        getUnit: (value: number) => `${getMonetaryUnit(value, "totalMeasureCostIntensity")}/ft²`,
        formatFunction: formatCostIntensity,
        formatFromUnit: (unit: string) =>
            formatCostIntensityFromUnit(unit.replace("/ft²", "")),
        tooltip:
            "Project cost excluding installation costs normalized by floor area.",
    },
    totalUtilityCostSavings: {
        humanReadable: "Utility savings",
        unit: "$",
        getUnit: getMonetaryUnit,
        formatFromUnit: formatMonetaryValueFromUnit,
        formatFunction: formatMonetaryValue,
    },
    totalMeasureCost: {
        humanReadable: "Total cost",
        unit: "$",
        getUnit: getMonetaryUnit,
        formatFromUnit: formatMonetaryValueFromUnit,
        formatFunction: formatMonetaryValue,
        tooltip: "Project cost excluding installation costs.",
    },
    utilityCost: {
        humanReadable: "Utility cost",
        unit: "$",
        getUnit: getMonetaryUnit,
        formatFromUnit: formatMonetaryValueFromUnit,
        formatFunction: formatMonetaryValue,
    },
    utilityCostIntensity: {
        humanReadable: "Average utility intensity cost",
        unit: "$/ft²",
        formatFunction: formatCostIntensity,
    },
    utilityProvider: {
        humanReadable: "Supplier",
    },
    utilityRate: {
        humanReadable: "Rate",
        unit: "$/unit",
        formatFunction: formatUtilityRate,
    },
    utilityCostSavingsElectricity: {
        humanReadable: "Electricity savings",
        unit: "$",
        getUnit: getMonetaryUnit,
        formatFromUnit: formatMonetaryValueFromUnit,
        formatFunction: formatMonetaryValue,
    },
    utilityCostSavingsNaturalGas: {
        humanReadable: "Natural gas savings",
        unit: "$",
        getUnit: getMonetaryUnit,
        formatFromUnit: formatMonetaryValueFromUnit,
        formatFunction: formatMonetaryValue,
    },
    utilityType: {
        humanReadable: "Energy type",
        formatFunction: formatUtilityType,
    },
    wallRValue: {
        humanReadable: "Wall R Value",
        formatFunction: formatBuildingAttribute,
    },
    windowInstallationYear: {
        humanReadable: "Window installation year",
    },
    windowRValue: {
        humanReadable: "R value",
        formatFunction: formatBuildingAttribute,
    },
    windowSolarHeatGainCoefficient: {
        humanReadable: "Solar heat gain coefficient",
        formatFunction: formatBuildingAttribute,
    },
    windowToWallRatio: {
        humanReadable: "Window to wall ratio",
        formatFunction: formatRatio,
    },
    yearApplied: {
        humanReadable: "Implementation year",
        formatFunction: formatYearApplied,
        tooltip: "Suggested year for implementing this project.",
    },
    yearBuiltOriginal: {
        humanReadable: "Year built",
        placeholder: "Enter year",
        errorMessage: `Must be between 1900 and ${CURRENT_YEAR}`,
        isValidFn: validateYear,
    },
    year: {
        humanReadable: "Year",
    },
    years: {
        humanReadable: "Years",
        unit: "years",
    },
    ledInstalledRatio: {
        humanReadable: "LED installation",
        unit: "%",
        description:
            "The percentage of the total building floor area that is upgraded to LED (excluding exterior areas)",
        formatFunction: formatRatioAsPercentage,
        errorMessage: "Must be a valid percentage between 0 and 100",
        isValidFn: validatePercentage,
    },
};

interface DisplayInfoReturnValue extends DisplayInfo {
    formatFunction: Function;
    unit: string;
}

export const getDisplayInfo = (
    key: string,
    value?: number | number[]
): DisplayInfoReturnValue => {
    if (Array.isArray(value)) {
        const nonZeroAbsValues = value
            .map((d) => Math.abs(d))
            .filter((v) => v > 0.01); // this is a workaround because the min net cash flow is 0.01

        const min =
            nonZeroAbsValues.length > 0 ? Math.min(...nonZeroAbsValues) : 0;

        return getDisplayInfo(key, min);
    }
    const {
        formatFunction,
        unit: defaultUnit,
        formatFromUnit,
        getUnit,
        humanReadable,
        placeholder,
        isValidFn,
        errorMessage,
        tooltip,
    } = getDisplayInfoForKey(key);

    const unit = value && getUnit ? getUnit(value) : defaultUnit;

    if (formatFromUnit && getUnit) {
        const format = formatFromUnit ? formatFromUnit(unit) : formatFunction;
        return {
            formatFunction: format,
            humanReadable,
            unit,
            formatFromUnit,
            placeholder,
            errorMessage,
            isValidFn,
            tooltip,
        };
    }

    return {
        unit,
        formatFunction,
        humanReadable,
        placeholder,
        isValidFn,
        errorMessage,
        tooltip,
    };
};

export const displayValueWithUnit = (
    value: string | number,
    unit: string,
    formatFunction: (value: string | number) => string
) => {
    if (unit.includes("$")) {
        const remainingUnit = unit.replace("$", "");
        if (Number(value) < 0)
            return `-$${formatFunction(
                Math.abs(Number(value))
            )}${remainingUnit}`;
        return `$${formatFunction(value)}${remainingUnit}`;
    }
    return `${formatFunction(value)}${unit === "%" ? "" : " "}${unit}`;
};

const getDisplayInfoForKey = (key: string) => {
    const {
        humanReadable,
        unit,
        formatFunction,
        placeholder,
        isValidFn,
        getUnit,
        formatFromUnit,
        errorMessage,
        tooltip,
    } = semanticTermToDisplayInfo[key];

    if (!humanReadable)
        throw new Error(
            `Please define display info in the semanticTermToDisplayInfo dictionary for ${key}`
        );
    return {
        humanReadable,
        unit: unit || "",
        formatFunction: formatFunction || ((v: any) => v),
        placeholder,
        getUnit,
        formatFromUnit,
        isValidFn: isValidFn || ((v: any) => true),
        errorMessage,
        tooltip,
    };
};

export const endUseValueToDisplayLabelMap: { [key: string]: string } = {
    electricity: "Electricity",
    renewable: "Renewable",
    domestic_hot_water: "Domestic hot water",
    lighting: "Lighting",
    natural_gas: "Natural gas",
    fans: "Fans",
    pumps: "Pumps",
    outdoor_air_cooling: "Outdoor air cooling",
    outdoor_air_heating: "Outdoor air heating",
    plug_load: "Plug load",
    skin_cooling: "Skin cooling",
    skin_heating: "Skin heating",
    process: "Process",
    cooling: "Cooling",
    heating: "Heating",
};

export const endUseKeyToDisplayLabelMap: { [key: string]: string } = {
    electricity: "Electricity",
    renewable: "Renewable",
    domesticHotWater: "Domestic hot water",
    lighting: "Lighting",
    naturalGas: "Natural gas",
    fans: "Fans",
    pumps: "Pumps",
    outdoorAirCooling: "Outdoor air cooling",
    outdoorAirHeating: "Outdoor air heating",
    plugLoad: "Plug load",
    skinCooling: "Skin cooling",
    skinHeating: "Skin heating",
    process: "Process",
    cooling: "Cooling",
    heating: "Heating",
};

export const endUseValueToColorMap: { [endUse: string]: string } = {
    cooling: "#7CB7F2",
    domestic_hot_water: "#00BC98",
    electricity: "#C10098",
    fans: "#CA8727",
    heating: "#4BF7B5",
    lighting: "#A467FA",
    natural_gas: "#7A2AEB",
    outdoor_air_cooling: "#50E253",
    outdoor_air_heating: "#CC303C",
    plug_load: "#066ECC",
    process: "#CC303C",
    pumps: "#EF81DA",
    skin_cooling: "#7CB7F2",
    skin_heating: "#F94646",
};

export const getTextColorByValue = (value: number, reverse?: boolean) => {
    const isNegative = reverse ? value > 0 : value < 0;
    const isPositive = reverse ? value < 0 : value > 0;
    const isNeutral = Math.round(value) === 0;

    let color;
    if (isNegative) color = "var(--audette-content-negative)";
    if (isPositive) color = "var(--audette-content-positive)";
    if (isNeutral) color = "var(--audette-gray-500)";

    return color;
};
