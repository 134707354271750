import React from "react";
import { Navigate } from "react-router-dom";
import { usePermissions } from "hooks/usePermissions";
import { UserPermissions } from "utils/enums";
import LoadingSpinner from "components/atoms/LoadingSpinner/LoadingSpinner";
import styles from "./Sandbox.module.scss";

interface SandboxLayoutProps {
    children: React.ReactNode;
    title?: string;
}

function SandboxLayout({ children, title = "🧪 Development Sandbox" }: SandboxLayoutProps) {
    const { hasPermission, isFetching } = usePermissions();

    if (isFetching) return <LoadingSpinner />;

    if (!hasPermission(UserPermissions.UseInternalTools)) {
        return <Navigate to="/" replace />;
    }

    return (
        <div className={styles["sandbox-container"]}>
            <div className={styles["sandbox-header"]}>
                <h1>{title}</h1>
            </div>
            <div className={styles["sandbox-content"]}>{children}</div>
        </div>
    );
}

export default SandboxLayout;
