import { Modal } from "antd";
import { Button } from "components/atoms/Button";
import { Paragraph } from "components/atoms/Typography";
import { useState } from "react";
import { useCreateCustomPlanFromPlan } from "mutations/CustomPlans/useCreateCustomPlanFromPlan";
import styles from "./createCustomPlanModal.module.scss";

interface CreateCustomPlanModalProps {
    modalIsOpen: boolean;
    onCancel: () => void;
    setModalIsOpen: (isOpen: boolean) => void;
    carbonReductionPlanUid: string;
    buildingModelUid: string;
}

function CreateCustomPlanModal({
    modalIsOpen,
    onCancel,
    setModalIsOpen,
    carbonReductionPlanUid,
    buildingModelUid,
}: CreateCustomPlanModalProps) {
    const [planName, setPlanName] = useState("Custom Plan");
    const [errorMessage, setErrorMessage] = useState("");
    const [hasTriedToSubmit, setHasTriedToSubmit] = useState(false);
    const { mutate, isLoading } = useCreateCustomPlanFromPlan();

    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const planName = e.target.value;
        setPlanName(planName);
        if (hasTriedToSubmit && planName.trim()) {
            setErrorMessage('');
        }
    };

    const modalSubmitHandler = () => {
        setHasTriedToSubmit(true);
        if (!planName.trim()) {
            setErrorMessage("Custom plan name cannot be empty.");
            return;
        }
        mutate(
            {
                carbonReductionPlanUid,
                planName,
                buildingModelUid,
            },
            {
                onSuccess: () => {
                    setModalIsOpen(false);
                },
            }
        );
    };

    const isInvalid = !planName.trim();

    return (
        <Modal
            open={modalIsOpen}
            onCancel={onCancel}
            title="Create new plan?"
            className={styles.modalContainer}
            footer={
                <CreateCustomPlanModalFooter
                    onSave={() => modalSubmitHandler()}
                    onCancel={onCancel}
                    isInvalid={isInvalid}
                    isLoading={isLoading}
                />
            }
        >
            <div>
                <div className={styles.firstHeader}>
                <p>
                    After this Audette won't be able to "recommend" you carbon reduction measures. 
                    Do you wish to proceed?
                </p>
            </div>
                <div className="form-input-wrapper">
                    <div>
                        <input
                            style={{
                                width: "100%",
                            }}
                            placeholder="Custom plan name"
                            value={planName}
                            className="form-input"
                            onChange={handleNameChange}
                            disabled={isLoading}
                        />
                    </div>
                    {errorMessage && (
                        <Paragraph
                            size="small"
                            style={{ color: "var(--audette-content-negative)" }}
                        >
                            {errorMessage}
                        </Paragraph>
                    )}
                </div>
            </div>
        </Modal>
    );
}

interface CreateCustomPlanModalFooterProps {
    onSave: () => void;
    onCancel: () => void;
    isInvalid: boolean;
    isLoading: boolean;
}

function CreateCustomPlanModalFooter({
    onSave,
    onCancel,
    isInvalid,
    isLoading,
}: CreateCustomPlanModalFooterProps) {
    return (
        <div className="bulk-edit-tags__footer" style={{ marginTop: "30px" }}>
            <Button 
                type="primary" 
                onClick={onSave} 
                disabled={isInvalid || isLoading}
            >
                {isLoading ? "Creating..." : "Create"}
            </Button>
            {!isLoading && (
                <Button
                    type="link"
                    onClick={onCancel}
                    style={{ color: "var(--audette-black)" }}
                    disabled={isLoading}
                >
                    Cancel
                </Button>
            )}
        </div>
    );
}

export default CreateCustomPlanModal;