import { useQuery } from "react-query";
import { useAccessToken } from "hooks/useAccessToken";
import { QUERY_STALE_TIME } from "utils/constants";
import { useParams } from "react-router-dom";
import queryFn from "ApiLayer/BuildingReports/Recommendations/queryAnnualEmissionSavingsByMeasureTable";

export const useAnnualEmissionSavingsByMeasureTable = () => {
    const accessToken = useAccessToken();
    const { buildingModelRunUid } = useParams();
    if (!buildingModelRunUid)
        throw new Error("BuildingModelUid param needs to be defined");

    return useQuery({
        queryKey: [
            buildingModelRunUid,
            "carbonReductionPlanReport",
            "buildingModelRecommendationsAnnualEmissionSavingsByMeasureTable",
        ],
        queryFn: async () => queryFn(accessToken, buildingModelRunUid),
        staleTime: QUERY_STALE_TIME,
    });
};
