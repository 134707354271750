import useQueryBuildings from "ApiLayer/Building/queryBuildings";
import useQueryProjects from "ApiLayer/Project/queryProjects";
import { Item } from "components/atoms/Dropdown";
import LoadingSpinner from "components/atoms/LoadingSpinner/LoadingSpinner";
import ChartContainer from "components/molecules/ChartContainer";
import { ScatterPlot } from "components/molecules/Charts";
import { ScatterPlotDatum } from "components/molecules/Charts/ScatterPlot";
import { ChartNoDataState } from "components/molecules/NoDataState";
import { usePermissions } from "hooks/usePermissions";
import { useFeatureFlagEnabled } from "posthog-js/react";
import { ReactNode, useState } from "react";
import useFiltersetState from "recoilStore/Filters/useFilterset";
import { ScatterPlotAssetGroup, ScatterPlotGroup, ScatterPlotProjectGroup, decarbonizationEfficiencyChartTooltip, falseProjects, scatterPlotAssetGroupsArray, scatterPlotGroupOptionsAll } from "utils/constants";
import { UserPermissions } from "utils/enums";
import { getBuildingAggregator, getProjectAggregator } from "./DecarbonizationEfficiencyAggregator";
import { refineBuildingData, refineProjectData } from "./DecarbonizationEfficiencyChart.helpers";

function DecarbonizationEfficiencyChart() {
    const featureEnabled = useFeatureFlagEnabled("prioritization-chart");
    const [scatterPlotGroup, setScatterPlotGroup] = useState<ScatterPlotGroup>("building");
    const { state: filtersetState } = useFiltersetState();
    const filtersetId = filtersetState.filterset;
    const { data: buildingsResponse, isLoading: buildingsIsLoading } = (
        useQueryBuildings(filtersetId)
    );
    const { data: projectsResponse, isLoading: projectsIsLoading } = useQueryProjects(filtersetId);
    const { hasPermission, isLoading: permissionIsLoading } = usePermissions();

    const canUseInternalTools = hasPermission(UserPermissions.UseInternalTools);
    if (!featureEnabled || !canUseInternalTools) {
        return <div style={{ display: "none" }} />;
    }

    let groupedData: ScatterPlotDatum[] = [];
    if (buildingsResponse && projectsResponse) {
        const { buildings } = buildingsResponse;
        const { projects: allProjects } = projectsResponse;
        const projects = allProjects.filter(
            (project) => !falseProjects.includes(project.carbonReductionMeasureType)
        );

        if (projects && projects.length > 0 && buildings && buildings.length > 0) {
            const buildingUngroupedData = refineBuildingData(buildings, projects);
            const projectUngroupedData = refineProjectData(buildings, projects);
            
            // Uncomment to debug
            // console.log("projectUngroupedData", projectUngroupedData);  
            // console.log("buildingUngroupedData", buildingUngroupedData);  
            if (scatterPlotAssetGroupsArray.includes(scatterPlotGroup as ScatterPlotAssetGroup)) {
                const buildingAggregator = getBuildingAggregator(
                    scatterPlotGroup as ScatterPlotAssetGroup
                );
                groupedData = buildingAggregator(buildingUngroupedData);

            } else {
                const projectAggregator = getProjectAggregator(
                    scatterPlotGroup as ScatterPlotProjectGroup
                );
                groupedData = projectAggregator(projectUngroupedData);
            }
        }
    }

    const isLoading = buildingsIsLoading || projectsIsLoading || permissionIsLoading;
    if (isLoading) return (
        <DecarbonizationEfficiencyChartContainer
            setScatterPlotGroup={setScatterPlotGroup}
            isLoading={isLoading}
        >
            <LoadingSpinner />
        </DecarbonizationEfficiencyChartContainer>
    )

    return (
        groupedData.length > 0 ? (
            <DecarbonizationEfficiencyChartContainer
                setScatterPlotGroup={setScatterPlotGroup}
                isLoading={isLoading}
            >
                <ScatterPlot
                    data={groupedData}
                    xLabel="Simple payback average (years)"
                    yLabel="Emissions reduction potential (%)"
                />
            </DecarbonizationEfficiencyChartContainer>
        ) :
            <ChartNoDataState />
    )
}

interface DecarbonizationEfficiencyChartContainerProps {
    children: ReactNode;
    setScatterPlotGroup: Function;
    isLoading: boolean;
}

function DecarbonizationEfficiencyChartContainer({
    children,
    setScatterPlotGroup,
    isLoading,
}: DecarbonizationEfficiencyChartContainerProps) {
    const items: Item[] = scatterPlotGroupOptionsAll.map(
        ({ key, label }: ScatterPlotGroupOption) => ({ id: key, displayValue: label })
    );

    const defaultItem = items.find(item => item.id === 'property');
    
    return (
        <ChartContainer
            title="Decarbonization efficiency by "
            onSelectItem={(x) => setScatterPlotGroup(x)}
            items={items}
            loading={isLoading}
            defaultDropdownItem={defaultItem}
            tooltip={decarbonizationEfficiencyChartTooltip}
        >
            {children}
        </ChartContainer>
    )

}

export default DecarbonizationEfficiencyChart;
