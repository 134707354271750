
// css
import "mapbox-gl/dist/mapbox-gl.css";
import "./MapLegend.css";

// constants
import MUITheme from "components/MUITheme";

// hooks
import { NavigateFunction } from "react-router-dom";

// helpers

// components
import { Tooltip } from "@mui/material";
import MapTooltip from "./MapToolTip";

interface MapPopupProps {
    buildingModel: BarChartAndMapData;
    navigate: NavigateFunction;
}

function MapPopup({ buildingModel, navigate }: MapPopupProps) {
    return (
        <MUITheme>
            <Tooltip
                title={
                    <MapTooltip
                        buildingModel={buildingModel}
                        navigate={navigate}
                    />
                }
                placement="top"
                style={{ padding: 0, margin: 0 }}
                leaveDelay={60}
            >
                <div className="popup-node" />
            </Tooltip>
        </MUITheme>
    );
}

export default MapPopup;