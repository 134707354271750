import { request } from "graphql-request";
import { graphql } from "gql/gql";

import { BACKEND_DOMAIN } from "utils/constants";
import { buildRequestHeaders } from "utils/helpers";

const queryFn = (
    accessToken: string,
    data: {
        reportFiltersetUid: string | null;
    }
) =>
    request({
        url: `${BACKEND_DOMAIN}/graphql`,
        document: query,
        variables: {
            reportFiltersetUid: data.reportFiltersetUid,
        },
        requestHeaders: buildRequestHeaders(accessToken),
    }).then(
        (data) =>
            (data.prefilteredReports?.prefilteredRecommendationsReport
                .annualEmissionSavingsByMeasureChart as AnnualEmissionSavingsByMeasureChartData) ||
            null
    );

const query = graphql(`
    query annualEmissionSavingsByMeasureChart($reportFiltersetUid: Uuid) {
        prefilteredReports(reportFiltersetUid: $reportFiltersetUid) {
            prefilteredRecommendationsReport {
                annualEmissionSavingsByMeasureChart {
                    carbonEmissionSavingsFromGrid
                    carbonReductionMeasureCategories {
                        carbonReductionMeasureCategory
                        totalCarbonEmissionSavings
                    }
                    remainingCarbonEmissions
                    year
                }
            }
        }
    }
`);

export default queryFn;
