import { Header } from "components/atoms/Typography";

interface MeasureInputProps {
    label: string;
    value: number | null;
    onChange: (value: number | null) => void;
    placeholder: string;
}

function MeasureInput({ label, value, onChange, placeholder }: MeasureInputProps) {
    return (
        <div className="filter-container">
            <Header size="small">{label}</Header>
            <input
                type="number"
                value={value === null ? "" : value}
                className="filter-input"
                onChange={(e) =>
                    onChange(e.target.value === "" ? null : Number(e.target.value))
                }
                placeholder={placeholder}
                maxLength={50}
            />
        </div>
    );
};

export default MeasureInput;
