
// css
import "mapbox-gl/dist/mapbox-gl.css";
import "./MapLegend.css";
import { Paragraph } from "components/atoms/Typography";
import { getDisplayInfo } from "utils/formatting";
import BlankMap from "./BlankMap.png";

export function MapLegend({
    scaleMin,
    scaleMax,
}: {
    scaleMin: number;
    scaleMax: number;
}) {
    const { unit, formatFunction } = getDisplayInfo(
        "annualCarbonEmissionIntensityCurrent"
    );

    return (
        <div className="map-legend">
            <Paragraph size="small">{unit}</Paragraph>
            <div className="map-legend--scale" />
            <div className="map-legend--values">
                <Paragraph size="small">{formatFunction(scaleMin)}</Paragraph>
                <Paragraph size="small">
                    {formatFunction((scaleMin + scaleMax) / 2)}
                </Paragraph>
                <Paragraph size="small">{formatFunction(scaleMax)}</Paragraph>
            </div>
        </div>
    );
}

export function MapLoading() {
    return (
        <div className="emission-intensity-map map-loading">
            <img
                src={BlankMap}
                alt="Map loading..."
                height="100%"
                width="100%"
            />
            <div className="map-legend-loading">
                <div className="map-legend-loading--text__title loading" />
                <div className="map-legend-loading--text__body loading" />
            </div>
        </div>
    );
}



