import {
    useEffect,
    useRef,
    useState,
    CSSProperties,
    useLayoutEffect,
} from "react";
import {
    getDisplayInfo,
    formatRatioForDisplay,
    formatRatioForAPI,
} from "utils/formatting";
import { Paragraph, Header } from "components/atoms/Typography";
import classNames from "classnames";
import "./FormInput.scss";

interface FormInputProps {
    value: number | string | undefined;
    setValue: (v: number | string | undefined) => void;
    attributeKey: string;
    type: "number" | "string";
    allowNegativeNumbers?: boolean;
    setIsError: (error: boolean) => void;
    showTitle?: boolean;
    style?: CSSProperties;
    onBlur?: () => void;
    displayInfo?: DisplayInfo;
    required?: boolean;
}

function FormInput({
    value,
    setValue,
    attributeKey,
    type,
    allowNegativeNumbers = true,
    setIsError,
    showTitle = true,
    style,
    onBlur,
    required = true,
    displayInfo = getDisplayInfo(attributeKey),
}: FormInputProps) {
    const {
        placeholder,
        unit,
        humanReadable,
        isValidFn,
        errorMessage: humanReadableErrorMessage,
    } = displayInfo;

    const [localValue, setLocalValue] = useState<number | string>(
        value ? formatRatioForDisplay(value, attributeKey) : ''
    );
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    // hold unto the width of the unit span so that the input has enough padding for it
    const unitRef = useRef<HTMLDivElement>(null);
    const [width, setWidth] = useState(0);
    useLayoutEffect(() => {
        if (unitRef.current?.offsetWidth)
            setWidth(unitRef.current?.offsetWidth);
    });

    useEffect(() => {
        if (value !== undefined) {
            const formatted = formatRatioForDisplay(value, attributeKey);
            setLocalValue(formatted);
        }
    }, [value]);

    const updateValue = () => {
        if (localValue === "" || localValue === undefined) {
            if (required) {
                setIsError(true);
                setErrorMessage(humanReadableErrorMessage || null);
            }
            setValue(undefined);
            return;
        }
        let valueIsValid = true;
        if (isValidFn) {
            if (type === "number") {
                valueIsValid = isValidFn(Number(localValue));
                const formattedForApi = formatRatioForAPI(
                    Number(localValue),
                    attributeKey as keyof BuildingProperties
                );
                setValue(formattedForApi);
            } else {
                valueIsValid = isValidFn(localValue);
                setValue(localValue);
            }
        } else setValue(localValue);

        if (!valueIsValid) {
            setErrorMessage(humanReadableErrorMessage || null);
            setIsError(true);
        }
    };

    useOutsideClick(inputRef, () => {
        inputRef.current?.blur();

        if (!inputRef.current?.blur()) {
            if (onBlur) onBlur();
        }
    });

    return (
        <div className="form-input-wrapper">
            {showTitle && (
                <Header
                    size="small"
                    style={
                        errorMessage
                            ? { color: "var(--audette-content-negative)" }
                            : undefined
                    }
                >
                    {humanReadable}
                    {required && (
                        <span
                            style={{ color: "var(--audette-content-negative)" }}
                        >
                            *
                        </span>
                    )}
                </Header>
            )}

            <div>
                <input
                    id={`selenium-${attributeKey}-input`}
                    data-testid={`test-${attributeKey}-input`}
                    style={{
                        width: "100%",
                        paddingRight: `${width + 10}px`,
                        ...style,
                    }}
                    ref={inputRef}
                    placeholder={placeholder}
                    value={localValue}
                    type={type}
                    className={classNames(
                        "form-input",
                        errorMessage && "error"
                    )}
                    onChange={(e) => {
                        setErrorMessage(null);
                        setIsError(false);

                        if (e.target.value !== "") {
                            if (type === "number") {
                                setLocalValue(Number(e.target.value));
                            } else setLocalValue(e.target.value);
                        } else setLocalValue("");
                    }}
                    onBlur={() => {
                        updateValue();
                        if (onBlur) onBlur();
                    }}
                    onWheel={() => inputRef.current?.blur()}
                    onKeyDown={(e) => {
                        if (!allowNegativeNumbers && e.code === "Minus") {
                            e.preventDefault();
                        }
                    }}
                    onKeyUp={(e) => {
                        if (e.key === "Enter") {
                            inputRef.current?.blur();
                        }
                    }}
                />
                <div
                    ref={unitRef}
                    style={{
                        display: "inline-block",
                        marginLeft: `-${width + 10}px`,
                    }}
                >
                    <Paragraph
                        className="form-input--unit"
                        style={{
                            color: "var(--audette-gray-600)",
                        }}
                        size="small"
                    >
                        {unit}
                    </Paragraph>
                </div>
            </div>

            {errorMessage && (
                <Paragraph
                    size="small"
                    style={{ color: "var(--audette-content-negative)" }}
                >
                    {errorMessage}
                </Paragraph>
            )}
        </div>
    );
}

function useOutsideClick(
    ref: React.MutableRefObject<null | HTMLDivElement>,
    onOutsideClick: () => void
) {
    useEffect(() => {
        function handleOutsideClick(event: any) {
            if (ref.current && !ref.current.contains(event.target))
                onOutsideClick();
        }
        document.addEventListener("mousedown", handleOutsideClick);
        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, [ref]);
}

export default FormInput;
