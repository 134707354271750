// hooks
import { NavigateFunction } from "react-router-dom";

// helpers
import { getDisplayInfo } from "utils/formatting";

// components
import { OpenInNew } from "components/atoms/Icon";
import { Header, Paragraph } from "components/atoms/Typography";

interface MapTooltipProps {
    buildingModel: BarChartAndMapData;
    navigate: NavigateFunction;
}

function MapTooltip({ buildingModel, navigate }: MapTooltipProps) {
    const {
        annualCarbonEmissionIntensityCurrent: intensity,
        buildingModelUid,
        label,
    } = buildingModel;

    const { formatFunction, unit } = getDisplayInfo(
        "annualCarbonEmissionIntensityCurrent"
    );

    const goToBuildingPage = () => {
        const e = document.getElementById("map-popup");
        e?.remove();
        navigate(`/building/${buildingModelUid}`);
    };
    return (
        <div id="map-popup" className="map-popup">
            {buildingModelUid ? (
                <button
                    className="map-popup--title"
                    type="button"
                    onClick={goToBuildingPage}
                >
                    <Header size="small">{label}</Header>
                    <OpenInNew fontSize="1.5em" />
                </button>
            ) : (
                <div className="map-popup--title">
                    <Header size="small">{label}</Header>
                </div>
            )}
            <div className="map-popup--body">
                <Paragraph size="small">Emission intensity: </Paragraph>
                <Paragraph
                    size="small"
                    style={{ color: "var(--audette-gray-500)" }}
                >
                    {formatFunction(intensity)} {unit}
                </Paragraph>
            </div>
        </div>
    );
}

export default MapTooltip;