import ViewTab from "components/atoms/ViewTab";
import { useFeatureFlagEnabled } from "posthog-js/react";
import { Tab } from "utils/enums";

interface ViewTabsProps {
    customPlans: any[];
    setCurrentActiveTab: (tab: string) => void;
    currentActiveTab: string;
    setCurrentCustomPlanDetails: (plan: any) => void;
    setIsCustomPlanOpen: (isOpen: boolean) => void;
}

function ViewTabs({
    customPlans,
    setCurrentActiveTab,
    currentActiveTab,
    setCurrentCustomPlanDetails,
    setIsCustomPlanOpen,
}: ViewTabsProps) {
    const customPlansEnabled = useFeatureFlagEnabled("building-custom-plans");

    const defaultTabClick = (tab: string) => {
        setCurrentActiveTab(tab);
        setIsCustomPlanOpen(false);
    };

    const customPlansTabClick = (plan: any) => {
        setCurrentCustomPlanDetails({
            id: plan.id,
            plan: plan.plan,
            name: plan.name,
        });
        setIsCustomPlanOpen(true);
        const customPlanUrl = `${Tab.CustomPlan}=${plan.id}`;

        setCurrentActiveTab(customPlanUrl);
    };
    return (
        <div className="building-page--view-tabs">
            <ViewTab
                id="building-baseline-tab"
                name="Baseline"
                selected={currentActiveTab === Tab.Baseline}
                onClick={() => defaultTabClick(Tab.Baseline)}
            />
            <ViewTab
                id="building-recommendations-tab"
                name="Recommendations"
                selected={currentActiveTab === Tab.Recommendations}
                onClick={() => defaultTabClick(Tab.Recommendations)}
            />
            {customPlansEnabled && (
                <ViewTab
                    id="building-recommendations-tab"
                    name="Decarbonization"
                    selected={currentActiveTab === Tab.Decarbonization}
                    onClick={() => defaultTabClick(Tab.Decarbonization)}
                />
            )}
            <ViewTab
                id="building-projects-tab"
                name="Projects"
                selected={currentActiveTab === Tab.Projects}
                onClick={() => defaultTabClick(Tab.Projects)}
            />
            {customPlansEnabled &&
                customPlans
                    .filter(
                        (plan): plan is NonNullable<typeof plan> => plan != null
                    )
                    .map((customPlan) => (
                        <ViewTab
                            key={customPlan.id}
                            name={customPlan.name}
                            selected={currentActiveTab === `${Tab.CustomPlan}=${customPlan.id}`}
                            onClick={() => customPlansTabClick(customPlan)}
                        />
                    ))}
        </div>
    );
}

export default ViewTabs;
