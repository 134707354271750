import { withAuthenticationRequired } from "@auth0/auth0-react";
import { ReactNode, Suspense } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import { AccessTokenProvider, AlertProvider } from "context";
import { usePendo } from "hooks/usePendo";
import { usePosthog } from "hooks/usePosthog";
import useQueryClient from "hooks/useQueryClient";
import { useUserIdentity } from "hooks/useUserIdentity";

import { ErrorBoundary, ErrorPage } from "pages/ErrorState";
import { QueryClientProvider } from "react-query";
import { RecoilRoot } from "recoil";

import LoadingSpinner from "components/atoms/LoadingSpinner/LoadingSpinner";
import DownloadBanner from "components/molecules/DownloadBanner";
import GlobalAlert from "components/molecules/GlobalAlert";
import ProtectedRoute from "hooks/useProtectedRoute";
import BuildingEnergyPage from "pages/BuildingPage/BuildingEnergyPage";
import SettingsPage from "pages/SettingsPage/SettingsPage";
import SandboxPage from "pages/SandboxPage/SandboxPage";
import SandboxLayout from "pages/SandboxPage/SandboxLayout";
import SandboxBuildingPage from "pages/SandboxPage/SandboxBuildingPage";
import { UserPermissions } from "utils/enums";
import Header from "./components/organisms/Header";
import BuildingPage from "./pages/BuildingPage";
import PortfolioPage from "./pages/PortfolioPage";
import ReportsPage from "./pages/ReportsPage";

function App() {
    const { accessToken, error, isAuthenticated } = useUserIdentity();
    const queryClient = useQueryClient();

    if (error) return <ErrorPage />;
    if (!isAuthenticated || !accessToken) return null;

    return (
        <QueryClientProvider client={queryClient}>
            <ErrorBoundary>
                <AccessTokenProvider accessToken={accessToken}>
                    <AlertProvider>
                        <PosthogWrapper />
                        <PendoWrapper>
                            <RecoilRoot>
                                <BrowserRouter>
                                    <Header />
                                    <GlobalAlert />
                                    <div className="viewport">
                                        <Suspense fallback={<LoadingSpinner />}>
                                            <Routes>
                                                {/* Sandbox route */}
                                                    <>
                                                        <Route
                                                            path="/sandbox"
                                                            element={
                                                                <ProtectedRoute
                                                                    permissionRequired={
                                                                        UserPermissions
                                                                            .UseInternalTools
                                                                    }
                                                                >
                                                                    <SandboxPage />
                                                                </ProtectedRoute>
                                                            }
                                                        />
                                                        <Route
                                                            path="/sandbox/building/:buildingModelUid"
                                                            element={
                                                                <ProtectedRoute
                                                                    permissionRequired={
                                                                        UserPermissions
                                                                            .UseInternalTools
                                                                    }
                                                                >
                                                                    <SandboxLayout title="🧪 Building Sandbox">
                                                                        <SandboxBuildingPage />
                                                                    </SandboxLayout>
                                                                </ProtectedRoute>
                                                            }
                                                        />
                                                    </>
                                                {/* End Sandbox route */}

                                                <Route
                                                    path="*"
                                                    element={<>404 Not Found</>}
                                                />
                                                <Route
                                                    path="/"
                                                    element={
                                                        <Navigate to="/portfolio" />
                                                    }
                                                />
                                                <Route
                                                    path="/portfolio"
                                                    element={<PortfolioPage />}
                                                />
                                                <Route
                                                    path="/reports"
                                                    element={<ReportsPage />}
                                                />
                                                <Route
                                                    path="/settings"
                                                    element={
                                                        <ProtectedRoute
                                                         permissionRequired={
                                                            UserPermissions.ReadUser
                                                        }
                                                        >
                                                            <SettingsPage />
                                                        </ProtectedRoute>
                                                    }
                                                />
                                                <Route
                                                    path="/building/:buildingModelUid"
                                                    element={<BuildingPage />}
                                                />
                                                <Route
                                                    path="/building/:buildingModelUid/:tabName/:buildingModelRunUid"
                                                    element={<BuildingPage />}
                                                />
                                                <Route
                                                    path="/building/:buildingModelUid/energy"
                                                    element={
                                                        <BuildingEnergyPage />
                                                    }
                                                />
                                            </Routes>
                                        </Suspense>
                                    </div>
                                    <DownloadBanner />
                                </BrowserRouter>
                            </RecoilRoot>
                        </PendoWrapper>
                    </AlertProvider>
                </AccessTokenProvider>
            </ErrorBoundary>
        </QueryClientProvider>
    );
}

interface PendoWrapperProps {
    children: ReactNode;
}

function PendoWrapper({ children }: PendoWrapperProps) {
    usePendo();
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
}

function PosthogWrapper() {
    usePosthog();
    return null;
}

export default withAuthenticationRequired(App);
