import mutationDeleteCustomCarbonReductionPlan from "ApiLayer/CustomPlans/queryDeleteCustomCarbonReductionPlan";
import { buildingModelQueryKey } from "hooks/Building/useBuildingModel";
import { useAccessToken } from "hooks/useAccessToken";
import useAlert from "hooks/useAlert";
import { useMutation, useQueryClient } from "react-query";
import { showMutationAlert } from "utils/helpers";

export interface DeleteCustomPlanData {
    carbonReductionPlanUid: string;
    buildingModelUid: string;
}

export const useDeleteCustomCarbonReductionPlan = () => {
    const accessToken = useAccessToken();
    const alert = useAlert();
    const queryClient = useQueryClient();

    return useMutation({
        onError: (error) => {
            showMutationAlert(error, alert);
        },
        onSuccess: (_, variables) => {
            alert.success("Custom plan deleted.");

            queryClient.invalidateQueries([...buildingModelQueryKey, variables.buildingModelUid]);
        },
        mutationFn: async (data: DeleteCustomPlanData) => 
            mutationDeleteCustomCarbonReductionPlan(accessToken, {
              carbonReductionPlanUid: data.carbonReductionPlanUid,
        })
    });
}