import { request } from "graphql-request";
import { graphql } from "gql/gql";

import { BACKEND_DOMAIN } from "utils/constants";
import {
    buildRequestHeaders,
    buildBuildingModelRequestVariables,
} from "utils/helpers";

const queryFn = (accessToken: string, buildingModelRunUid: string) =>
    request({
        url: `${BACKEND_DOMAIN}/graphql`,
        document: query,
        variables: {buildingModelRunUid},
        requestHeaders: buildRequestHeaders(accessToken),
    }).then((data) => {
        if (data.carbonReductionPlanReports.error)
            throw new Error(data.carbonReductionPlanReports.error);
        return data.carbonReductionPlanReports
            ?.cumulativeNetPresentValueCard as CumulativeNetPresentValueCardData;
    });

const query = graphql(`
    query buildingModelRecommendationsCumulativeNetPresentValueCard(
        $buildingModelRunUid: Uuid!
    ) {
        carbonReductionPlanReports(buildingModelRunUid: $buildingModelRunUid) {
            error
            cumulativeNetPresentValueCard {
                averageMarginalAbatementCost
                discountRate
                netPresentValue
                returnOnInvestment
                totalEnergyCostSavings
                totalIncrementalCost
                totalLikeForLikeCost
                totalMeasureCost
                years
            }
        }
    }
`);

export default queryFn;
