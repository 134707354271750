import { request } from "graphql-request";
import { graphql } from "gql/gql";

import { BACKEND_DOMAIN } from "utils/constants";
import {
    buildRequestHeaders,
} from "utils/helpers";

const queryAnnualCarbonEmissionsChart  = (
    accessToken: string, 
    data: {
        reportFiltersetUid: string | null;
    }
) =>
    request({
        url: `${BACKEND_DOMAIN}/graphql`,
        document: query,
        variables: {
            reportFiltersetUid: data.reportFiltersetUid,
        },     
        requestHeaders: buildRequestHeaders(accessToken),
    }).then((data) => {
        if (data.prefilteredReports?.prefilteredBaselineReport?.annualCarbonEmissionsChart.error)
            throw new Error(
                data.prefilteredReports.prefilteredBaselineReport.annualCarbonEmissionsChart.error
            );
        return (
            data.prefilteredReports?.prefilteredBaselineReport?.annualCarbonEmissionsChart.data ||
            null
        );
    });

const query = graphql(`
    query annualCarbonEmissionsChart($reportFiltersetUid: Uuid) {
        prefilteredReports(reportFiltersetUid: $reportFiltersetUid) {
            prefilteredBaselineReport {
                annualCarbonEmissionsChart {
                    error
                    data {
                        totalCarbonEmissions
                        year
                    }
                }
            }
        }
    }
`);

export default queryAnnualCarbonEmissionsChart;
