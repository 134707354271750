// hooks
import { useNavigate } from "react-router-dom";

// store
import useFilters, { emptyFilters } from "recoilStore/useFilters";

// components
import { Button } from "components/atoms/Button";
import { Header, Paragraph } from "components/atoms/Typography";

// helpers
import { getDisplayInfo } from "utils/formatting";

// css
import "../ByBuildingBarChart.css";
import { useTooltipDataContext } from "./hooks/useTooltipDataContext";

interface ByBuildingChartTooltipProps {
    currentDataIndex?: number;
}

function ByBuildingChartTooltip({
    currentDataIndex,
}: ByBuildingChartTooltipProps) {
    if (currentDataIndex === undefined) return null;

    const { buckets } = useTooltipDataContext();
    const { buildings, min, max, average } = buckets[currentDataIndex];
    const { min: dataMin } = buckets[0];
    const { selectedKey } = buildings[0];

    const { formatFunction, unit } = getDisplayInfo(selectedKey, dataMin || 0);

    const { setFilters } = useFilters("buildings");
    const navigate = useNavigate();

    const onClickViewAll = () => {
        setFilters({
            ...emptyFilters,
            numericFilters: [
                {
                    key: selectedKey,
                    condition: "between",
                    field: "annual_carbon_emission_intensity", // field doesn't have to match
                    value: [min || 0, max || 0],
                },
            ],
        });
        navigate("/portfolio");
    };

    return (
        <div className="bar-tooltip">
            {buildings.length > 1 && (
                <div className="bar-tooltip--title">
                    <Header
                        style={{ color: "var(--audette-gray-500)" }}
                        size="small"
                    >
                        Average:
                    </Header>
                    <Paragraph>
                        {formatFunction
                            ? formatFunction(average, false)
                            : average}{" "}
                        {unit}
                    </Paragraph>
                </div>
            )}
            {buildings.slice(0, 5).map((b) => (
                <div className="bar-tooltip--row" key={b.key}>
                    <Header size="x-small">{b.address}</Header>
                    <Paragraph size="small">
                        {`${
                            formatFunction
                                ? formatFunction(b.value, false)
                                : b.value
                        } ${unit}`}
                    </Paragraph>
                </div>
            ))}
            {buildings.length > 5 && (
                <Button type="link" onClick={onClickViewAll}>
                    View all
                </Button>
            )}
        </div>
    );
}

export default ByBuildingChartTooltip;