import queryFn from "ApiLayer/Reports/Recommendations/queryAnnualCarbonEmissionsNaturalGasChart";
import { useAccessToken } from "hooks/useAccessToken";
import { useQuery } from "react-query";
import useFiltersetState from "recoilStore/Filters/useFilterset";
import { QUERY_STALE_TIME } from "utils/constants";

export const useAnnualCarbonEmissionsNaturalGasChart = () => {
    const accessToken = useAccessToken();
    const { state: filtersetState } = useFiltersetState();
    const filters = {
        reportFiltersetUid: filtersetState.filterset
    }
    
    return useQuery({
        queryKey: [
            "recommendationsAnnualCarbonEmissionsNaturalGasChart",
            filters,
        ],
        queryFn: async () => queryFn(accessToken, filters),
        staleTime: QUERY_STALE_TIME,
    });
};
