/* eslint-disable no-restricted-syntax */
import mapboxgl, {
    GeoJSONSource,
    Map as MapboxglMap
} from "mapbox-gl";
import { useEffect, useMemo, useRef, useState } from "react";

// css
import "mapbox-gl/dist/mapbox-gl.css";
import "./EmissionIntensityMap.css";

// constants
import { MAPBOX_TOKEN } from "utils/constants";

// hooks
import { useNavigate } from "react-router-dom";
import useReportsGroupByState from "recoilStore/useReportsGroupByState";

// helpers

// components
import { Header } from "components/atoms/Typography";
import { ChartErrorState } from "components/molecules/ErrorStates";

import { MapLegend, MapLoading } from "./EmissionIntensityMap/MapLegend";
import useBuildingBarChartAndMapByGroup from "./hooks/useBuildingBarChartAndMapByGroup";
import { createGeoJsonData, useMapLayers } from "./EmissionIntensityMap/EmissionIntensityMap.helpers";

mapboxgl.accessToken = MAPBOX_TOKEN;

interface EmissionIntensityMapProps {
    includeHeader?: boolean;
}

function EmissionIntensityMap({
    includeHeader = true,
}: EmissionIntensityMapProps) {
    const { state: groupByMetric } = useReportsGroupByState();

    const {
        data: buildingModels,
        isLoading,
        error,
    } = useBuildingBarChartAndMapByGroup(groupByMetric);

    if (isLoading || !buildingModels) return <MapLoading />;
    if (error) return <ChartErrorState />;

    return (
        <div className="emission-intensity-map">
            {includeHeader && (
                <div className="title">
                    <Header size="small">Emission intensity</Header>
                </div>
            )}
            <Map buildingModels={buildingModels} />
        </div>
    );
}

interface MapProps {
    buildingModels: BarChartAndMapData[];
}

export function Map({ buildingModels }: MapProps) {
    const mapContainer = useRef<HTMLDivElement | null>(null);
    const map = useRef<MapboxglMap | null>(null);
    const [mapHasLoaded, setMapHasLoaded] = useState(false);
    const navigate = useNavigate();

    const { minEmissionIntensity, maxEmissionIntensity } = useMemo(() => {
        const intensities = buildingModels.map(
            ({ annualCarbonEmissionIntensityCurrent }) =>
                annualCarbonEmissionIntensityCurrent
        );
        const minEmissionIntensity = Math.min(...intensities);
        const maxEmissionIntensity = Math.max(...intensities);
        return { minEmissionIntensity, maxEmissionIntensity };
    }, [buildingModels]);

    useEffect(() => {
        if (!map.current || !buildingModels) return;
        (map.current.getSource("buildings") as GeoJSONSource)?.setData(
            createGeoJsonData(buildingModels)
        );
    }, [buildingModels]);

    useMapLayers(
        buildingModels,
        map,
        mapContainer,
        setMapHasLoaded,
        minEmissionIntensity,
        maxEmissionIntensity,
        navigate
    );

    return (
        <div ref={mapContainer} className="map-container">
            {!mapHasLoaded && <MapLoading />}
            {mapHasLoaded && (
                <MapLegend
                    scaleMin={minEmissionIntensity}
                    scaleMax={maxEmissionIntensity}
                />
            )}
        </div>
    );
}

export default EmissionIntensityMap;
