import { Modal } from "antd";
import { Button } from "components/atoms/Button";
import Dropdown from "components/atoms/Dropdown";
import { useEffect, useState } from "react";
import { HOLDING_PERIOD_ITEMS } from "utils/constants";
import styles from "../CreateCustomPlanModal/createCustomPlanModal.module.scss";

interface HoldingPediodModalProps {
    modalIsOpen: boolean;
    onCancel: () => void;
    setModalIsOpen: (isOpen: boolean) => void;
    setHoldingPeriod: (holdingPeriod: number) => void;
    holdingPeriod: number;
}

function HoldingPediodModal({
    modalIsOpen,
    onCancel,
    setModalIsOpen,
    setHoldingPeriod,
    holdingPeriod,
}: HoldingPediodModalProps) {
    const [tempHoldingPeriod, setTempHoldingPeriod] = useState(holdingPeriod);

    useEffect(() => {
        if (modalIsOpen) {
            setTempHoldingPeriod(holdingPeriod);
        }
    }, [modalIsOpen, holdingPeriod]);


    const handleSave = () => {
        setHoldingPeriod(tempHoldingPeriod);
        setModalIsOpen(false);
    };

    return (
        <Modal
            open={modalIsOpen}
            onCancel={onCancel}
            title="Change holding period?"
            className={styles.modalContainer}
            footer={
                <HoldingPediodModalFooter
                    onSave={handleSave}
                    onCancel={onCancel}
                />
            }
        >
            <div>
                <div className={styles.firstHeader}>
                    <p>
                        This will temporarily modify the horizon considered for
                        the data used as context for the Portfolio Chatbot.
                        Closing the chatbot will reset the holding period to 10
                        years.
                    </p>
                </div>
                <div className="form-input-wrapper">
                    <div>
                        <Dropdown
                            items={HOLDING_PERIOD_ITEMS}
                            onSelectItem={({ id }) => setTempHoldingPeriod(Number(id))}
                            defaultItem={HOLDING_PERIOD_ITEMS[tempHoldingPeriod]}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    );
}

interface HoldingPediodModalFooterProps {
    onSave: () => void;
    onCancel: () => void;
}

function HoldingPediodModalFooter({
    onSave,
    onCancel,
}: HoldingPediodModalFooterProps) {
    return (
        <div className="bulk-edit-tags__footer" style={{ marginTop: "30px" }}>
            <Button type="primary" onClick={onSave}>
                Change holding period
            </Button>
            <Button
                type="link"
                onClick={onCancel}
                style={{ color: "var(--audette-black)" }}
            >
                Cancel
            </Button>
        </div>
    );
}

export default HoldingPediodModal;
