import { ReactNode } from "react";
import classNames from "classnames";
import { Header } from "components/atoms/Typography";
import { CloseButton, Collapse, Expand } from "components/atoms/Icon";
import "./index.css";

interface SidebarProps {
    onCancel: () => void;
    sidebarOpen: boolean;
    title: string;
    children: ReactNode;
    isExpanded?: boolean;
    canBeExpanded?: boolean;
    setIsExpanded?: (isExpanded: boolean) => void;
}

// To do: Watch out when implementing isExpanded. Currently prevents using text input properly in chatbot
function Sidebar({
    onCancel,
    sidebarOpen,
    title,
    children,
    isExpanded,
    canBeExpanded,
    setIsExpanded,
}: SidebarProps) {
    return (
        <div
            className={classNames(
                "sidebar",
                sidebarOpen ? "show" : "hidden",
                isExpanded && "is-expanded"
            )}
        >
            <div className="sidebar--header">
                <Header size="small" weight="bold">
                    {title}
                </Header>
                {canBeExpanded && (
                    <button 
                        onClick={() => setIsExpanded && setIsExpanded(!isExpanded)}
                        type="button"  
                        className="sidebar-expand"  
                    >
                        {!isExpanded ? <Expand /> : <Collapse />}
                    </button>
                )}
                <CloseButton onClick={onCancel} />
            </div>
            {children}
        </div>
    );
}

export default Sidebar;
