import { request } from "graphql-request";
import { graphql } from "gql/gql";

import { BACKEND_DOMAIN } from "utils/constants";
import { buildRequestHeaders } from "utils/helpers";

const queryFn = (
    accessToken: string,
    data: {
        reportFiltersetUid: string | null;
    }
) =>
    request({
        url: `${BACKEND_DOMAIN}/graphql`,
        document: query,
        variables: {
            reportFiltersetUid: data.reportFiltersetUid,
        },
        requestHeaders: buildRequestHeaders(accessToken),
    }).then((data) => {
        if (
            data.prefilteredReports?.prefilteredBaselineReport
                ?.annualCarbonEmissionsCard.error
        )
            throw new Error(
                data.prefilteredReports.prefilteredBaselineReport.annualCarbonEmissionsCard.error
            );
        return (
            data.prefilteredReports?.prefilteredBaselineReport
                ?.annualCarbonEmissionsCard.data || null
        );
    });

const query = graphql(`
    query annualCarbonEmissionsCard($reportFiltersetUid: Uuid) {
        prefilteredReports(reportFiltersetUid: $reportFiltersetUid) {
            prefilteredBaselineReport {
                annualCarbonEmissionsCard {
                    error
                    data {
                        totalCarbonEmissions
                        totalCarbonEmissionsElectricity
                        totalCarbonEmissionsNaturalGas
                        year
                    }
                }
            }
        }
    }
`);

export default queryFn;
