import errorFallbackUI from "components/molecules/ErrorFallbackUI.tsx/ErrorFallbackUI";
import { ComponentErrorBoundary } from "components/molecules/ErrorStates";
import { Table } from "components/molecules/Table";
import { useEffect, useMemo, useState } from "react";
import { getDisplayInfo } from "utils/formatting";
import useQueryBuildings from "ApiLayer/Building/queryBuildings";
import useFiltersetState from "recoilStore/Filters/useFilterset";
import useQueryProjects from "ApiLayer/Project/queryProjects";
import { falseProjects } from "utils/constants";
import { formatProjectsData } from "utils/helpers";
import MeasuresReportModal from "./Modal/MeasuresReportModal";

interface MeasuresMeasuresReportProps {
    setExportData: (data: any) => void;
}

function MeasuresMeasuresReport({
    setExportData,
}: MeasuresMeasuresReportProps) {
    const { state: filtersetState } = useFiltersetState();
    const filtersetId = filtersetState.filterset;
    const { data: buildingsResponse } = useQueryBuildings(filtersetId);
    const { data: projectsResponse } = useQueryProjects(filtersetId);

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [selectedRow] = useState<MeasuresMeasuresReportTableRow | null>(null);

    const handleCancel = () => {
        setModalIsOpen(false);
    }

    const formattedData = formatProjectsData(buildingsResponse, projectsResponse);

    const excludeKeys = [
        "yearApplied",
    ];
    const filteredData = formattedData?.flatMap(
        (datum) => {
            if (datum === undefined) return [];

            const newDatum: any = { ...datum };
            excludeKeys.forEach(
                (key) => delete newDatum[key]
            );
            return newDatum;
        }
    );

    // SumKeys and averageKeys are used to determine if value should be summed or averaged. Move keys as needed
    const sumKeys = new Set([
        "annualEnergyConsumptionSavingsElectricity",
        "annualEnergyConsumptionSavingsNaturalGas",
        "annualEnergyConsumptionSavingsSteam",
        "annualEnergyConsumptionSavingsTotal",
        "annualCarbonEmissionSavingsElectricity",
        "annualCarbonEmissionSavingsNaturalGas",
        "annualCarbonEmissionSavingsSteam",
        "annualCarbonEmissionSavingsTotal",
        "likeForLikeCost",
        "incrementalCost",
        "annualCarbonTaxSavings",
        "annualUtilityCostSavingsElectricity",
        "annualUtilityCostSavingsNaturalGas",
        "annualUtilityCostSavingsSteam",
        "netPresentValue",
        "marginalAbatementCost",
        "cashFlowAnnualHidden",
    ]);

    const averageKeys = new Set([
        "returnOnInvestment",
        "simplePayback",
        "netPresentValueTotal",
        "netPresentValueIncremental",
    ]);

    const accumulateAndAverageData = (data: any[]) => {
        const groupedData: { [key: string]: any[] } = {};

        data.forEach((item) => {
            const type = item.carbonReductionMeasureType;
            if (!groupedData[type]) {
                groupedData[type] = [];
            }
            groupedData[type].push(item);
        });

        const averagedData = Object.keys(groupedData).map((type) => {
            const items = groupedData[type];
            const averagedItem = items.reduce(
                (acc, item) => {
                    Object.keys(item).forEach((key) => {
                        if (key === "cashFlowAnnualHidden") {
                            if (!acc[key]) acc[key] = [];
                            acc[key] = [
                                ...(acc[key] || []),
                                ...(item[key] || []),
                            ];
                        } else if (typeof item[key] === "number") {
                            acc[key] = (acc[key] || 0) + item[key];
                        }
                    });
                    return acc;
                },
                {
                    carbonReductionMeasureType: type,
                    carbonReductionMeasureCategory:
                        items[0].carbonReductionMeasureCategory,
                    numberOfProjects: items.length,
                }
            );

            Object.keys(averagedItem).forEach((key) => {
                if (typeof averagedItem[key] === "number") {
                    if (sumKeys.has(key)) {
                        return;
                    }
                    if (averageKeys.has(key)) {
                        averagedItem[key] /= items.length;
                    }
                }
            });

            return averagedItem;
        });

        return averagedData;
    };

    const averagedData = useMemo(
        () => {
            if (!filteredData || filteredData.length === 0) return null;
            return accumulateAndAverageData(filteredData);
        },
        [filteredData]
    );

    const tableColumns = useMemo(() => {
        if (!averagedData || averagedData.length === 0) return null;

        const firstValidRow = averagedData.find(
            row => !falseProjects.includes(row.carbonReductionMeasureType)
        );
        
        if (!firstValidRow) return null;
        
        const averagedDataKeys = Object.keys(firstValidRow);
        
        return averagedDataKeys.map((key) => {
            const typedKey = key as keyof MeasuresMeasuresReportTableRow;

            if (typeof averagedData[0][typedKey] === "string") {
                const { formatFunction, humanReadable, tooltip } =
                    getDisplayInfo(key);

                return {
                    render: formatFunction,
                    key,
                    title: humanReadable,
                    tooltip,
                };
            }

            const columnValues = averagedData.map(
                (row) => row[typedKey]
            ) as number[];

            const { formatFunction, humanReadable, unit, tooltip } =
                getDisplayInfo(key, columnValues);

            return {
                render: formatFunction,
                key,
                title: `${humanReadable} ${unit && `(${unit})`}`,
                tooltip,
            };
        });
    }, [averagedData]);

    const dataSource = useMemo(() => {
        if (!averagedData) return null;

        return averagedData
            .filter((row) => !falseProjects.includes(row.carbonReductionMeasureType))
            .map((row, index) => ({
                ...row,
                key: `${row.carbonReductionMeasureType
                        ? row.carbonReductionMeasureType
                        : "defaultKey"
                    }-${index}`,
            }));
    }, [averagedData]);

    useEffect(() => {
        // Using preventive state update to avoid a rerender that bugs pagination
        if (dataSource) {
            setExportData((prev: any) => {
                if (JSON.stringify(prev) !== JSON.stringify(dataSource)) {
                    return dataSource;
                }
                return prev;
            });
        }
    }, [dataSource]);

    if (!dataSource || !tableColumns) return null;

    const handleMeasureRowClick = (row: MeasuresMeasuresReportTableRow) => {
        // Uncomment when reimplimenting the Cash Flow modal
        // setSelectedRow(row);
        // setModalIsOpen(true);
    };

    return (
        <div className="measures-measures-report">
            <ComponentErrorBoundary
                fallback={errorFallbackUI("tableError")}
                originComponent="MeasuresMeasuresReport"
            >
                <Table
                    dataSource={dataSource}
                    columns={tableColumns}
                    className="measures-report-table"
                    pagination={true}
                    onRowClick={(row) => handleMeasureRowClick(row)}
                />

                <MeasuresReportModal
                    modalIsOpen={modalIsOpen}
                    onCancel={handleCancel}
                    rowData={selectedRow}
                />
            </ComponentErrorBoundary>
        </div>
    );
}

export default MeasuresMeasuresReport;
